import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import {
  FormGroup,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  InputAdornment
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import Comments from './Comments';
import IconPopper from '../../IconPopper';
import { getMaxDate, roundXDigits } from '../../../Helpers/index';


const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 700,
    margin: '0 auto',
    position: 'relative'
  },
  group: {
    width: '100%',
    justifyContent: 'space-between'
  },
  groupedField: {
    width: '49%',
  },
  statDyn: {
    width: '49%',
    gap: 10
  },
  gap: {
    gap: 30
  },
  datetime: {
    width: '100%',
    justifyContent: 'center',
    gap: 20
  },
  selects: {
    marginTop: 16,
    marginBottom: 8
  },
  formLabel: {
    color: 'rgb(25,50,181) !important'
  },
  btn: {
    backgroundColor: 'rgb(25,118,210) !important',
    color: 'white !important',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '200px !important',
    marginTop: '20px !important',
    '&:hover': {
      backgroundColor: 'rgb(05,98,190) !important',
      color: 'rgb(230,230,230) !important',
    }
  },
  commentGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '25px',
    gap: 10,
    position: 'relative'
  },
  iconBtn: {
    width: 40,
    height: 40,
    marginTop: '6px !important',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(210,118,25,0.04) !important'
    }
  },
  iconBtnCont: {
    position: 'absolute',
    left: -43,
    top: -6
  },
  icon: {
    color: 'rgb(220,40,40)'
  },
  wrapper: {
    marginTop: 5,
    marginBottom: 5
  },
  ol: {
    width: 'fit-content',
    margin: '0 auto',
    '& li': {
      margin: '6px 0px'
    }
  },
  caveats: {
    justifyContent: 'space-between',
    width: '300px',
    margin: '0 auto',
    alignItems: 'center'
  },
  labelLine: {
    display: 'flex',
    alignItems: 'center'
  }
}));



export default function WeekReading({ weekNum, handleChange, data, submitAttempted, mondaySelected }) {
  const [maxDate, setMaxDate] = useState('');

  const classes = useStyles();
  
  useEffect(() => {
    setMaxDate(mondaySelected === '' ? '' : getMaxDate(mondaySelected));
  }, [mondaySelected]);

  const handleUpdate = (val, key) => {
    if (Object.keys(data.wellStatus).includes(key)) {
      if (key === 'staticOffTime') {
        val = roundXDigits(val, 0, true);
      }

      let newObj = { ...data.wellStatus, [key]: val };
      
      if (key === 'dynamicPumping' && val === 'No') {
        newObj.dynamicRiseFall = '';
      }

      handleChange(newObj, weekNum, 'wellStatus');
    } else {
      handleChange({ ...data.wpRate, [key]: val }, weekNum, 'wpRate');
    }
  };


  return (
    <div className={classes.container}>
      <FormGroup>
        <Typography variant='sectionTitle' sx={{marginTop: '8px', marginBottom: '8px'}}>Measurement Problems</Typography>

        <FormGroup row={true} className={classes.caveats}>
          <Checkbox sx={{color: 'red', '&.Mui-checked': { color: 'red' }}} checked={data.unableToMeasure} onChange={(e) => handleChange(e.target.checked, weekNum, 'unableToMeasure')} />
          <FormGroup row={true} className={classes.labelLine}>
            <FormLabel sx={{color: 'black'}}>Unable to Take Measurement</FormLabel>
            
            <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='8px'>
              <div>
                <Typography variant='popupSubheading'>This option will clear the form and require a comment to explain why no measurement was taken, such as:</Typography>
            
                <ol className={classes.ol} type='i'>
                  <li>Pump House locked</li>
                  <li>Tape hung up</li>
                  <li>Can&apos;t get tape in casing</li>
                  <li>Unable to locate well				</li>
                  <li>Well has been destroyed</li>
                  <li>Leaky casing</li>
                  <li>Temporarily inaccessible</li>
                </ol>
              </div>
            </IconPopper>
          </FormGroup>
        </FormGroup>

        <FormGroup row={true} className={classes.caveats}>
          <Checkbox sx={{color: 'red', '&.Mui-checked': { color: 'red' }}} checked={data.inaccurateMeasure} onChange={(e) => handleChange(e.target.checked, weekNum, 'inaccurateMeasure')} />
          
          <FormGroup row={true} className={classes.labelLine}>
            <FormLabel sx={{color: 'black'}}>Inaccurate Measurement</FormLabel>
            <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='8px'>
              <div>
                <Typography variant='popupSubheading'>This option will require a comment to explain why the measurement was inaccurate, such as:</Typography>
                <ol className={classes.ol} type='a'>
                  <li>Oil in casing</li>
                  <li>Nearby pump operating</li>
                  <li>Caved or deepened</li>
                  <li>Pumping on going or pumped a short while ago</li>
                  <li>Other significant observations</li>
                </ol>
              </div>
            </IconPopper>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      
      {!data.unableToMeasure &&
        <>
          <div>
            <FormGroup>
              <Typography variant='sectionTitle' sx={{marginTop: '8px'}}>Observation Date</Typography>
              <FormGroup row={true} className={classes.datetime}>
                <TextField
                  sx={{width: 175}}
                  size='small'
                  variant='outlined'
                  label='Measurement Date'
                  value={data['obsDate']}
                  onChange={(e) => handleChange(e.target.value, weekNum, 'obsDate')}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={submitAttempted && data['obsDate'] === ''}
                  disabled={mondaySelected === ''}
                  inputProps={{min: mondaySelected, max: maxDate}}
                />
                <TextField
                  sx={{width: 175}}
                  size='small'
                  variant='outlined'
                  label='Measurement Time'
                  value={data['time']}
                  onChange={(e) => handleChange(e.target.value, weekNum, 'time')}
                  type='time'
                  InputLabelProps={{ shrink: true }}
                  error={submitAttempted && data['time'] === ''}
                />
              </FormGroup>
            </FormGroup>
          </div>

          <FormGroup>
            <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Well Status</Typography>
            <FormGroup row={true} className={classes.group}>
              <FormGroup className={classes.statDyn}>
                <Typography variant='subtitle2' align='center'>Static</Typography>
                <FormGroup className={classes.gap}>
                  <TextField
                    select
                    label='Yes/No'
                    className={classes.selects}
                    size='small'
                    value={data['wellStatus']['isStatic']}
                    error={submitAttempted && data['wellStatus']['isStatic'] === ''}
                    onChange={(e) => handleUpdate(e.target.value, 'isStatic')}
                  >
                    <MenuItem value='Yes'>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
                  </TextField>
                  <TextField
                    size='small'
                    variant='outlined'
                    label='Pump Off Time (hrs.)'
                    value={data['wellStatus']['staticOffTime']}
                    onChange={(e) => handleUpdate(e.target.value, 'staticOffTime')}
                    type='number'
                    inputProps={{ min: 0 }}
                  />
                </FormGroup>
              </FormGroup>

              <FormGroup className={classes.statDyn}>
                <Typography variant='subtitle2' align='center'>Dynamic</Typography>
                <FormGroup className={classes.gap}>
                  <TextField
                    select
                    className={classes.selects}
                    size='small'
                    label='Dynamic'
                    value={data['wellStatus']['dynamicPumping']}
                    error={submitAttempted && data['wellStatus']['dynamicPumping'] === ''}
                    onChange={(e) => handleUpdate(e.target.value, 'dynamicPumping')}
                  >
                    <MenuItem value='Yes'>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
                  </TextField>
                  <TextField
                    select
                    className={classes.selects}
                    size='small'
                    label='Rising/Falling'
                    value={data['wellStatus']['dynamicRiseFall']}
                    error={submitAttempted && data['wellStatus']['dynamicPumping'] === 'Yes' && data['wellStatus']['dynamicRiseFall'] === ''}
                    onChange={(e) => handleUpdate(e.target.value, 'dynamicRiseFall')}
                    disabled={data['wellStatus']['dynamicPumping'] === 'No'}
                  >
                    <MenuItem value=''></MenuItem>
                    <MenuItem value='Rising'>Rising</MenuItem>
                    <MenuItem value='Falling'>Falling</MenuItem>
                  </TextField>
                </FormGroup>
              </FormGroup>
            </FormGroup>
          </FormGroup>

          <div>
            <FormGroup className={classes.group}>
              <Typography variant='sectionTitle'>Depth to Water</Typography>
              <TextField
                size='small'
                variant='outlined'
                label='From Top of Casing (ft.)'
                value={data['dtw']}
                onChange={(e) => handleChange(roundXDigits(e.target.value, 1, true), weekNum, 'dtw')}
                type='number'
                inputProps={{ step: 0.1 }}
                sx={{width: '50%', margin: '0 auto'}}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Typography variant='sectionTitle'>Water Level Elevation</Typography>
            <FormGroup row={true} className={classes.group}>
              <TextField
                className={classes.groupedField}
                size='small'
                variant='outlined'
                label='Static (ft.)'
                value={data['wlElev']['static']}
                disabled
              />
    
              <TextField
                className={classes.groupedField}
                size='small'
                variant='outlined'
                label='Pumping (ft.)'
                value={data['wlElev']['pumping']}
                disabled
              />
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Typography variant='sectionTitle'>Water Pumping Rate</Typography>
            <FormGroup row={true} className={classes.group}>
              <TextField
                className={classes.groupedField}
                size='small'
                variant='outlined'
                label='Weekly Instantaneous (gpm)'
                value={data['wpRate']['inst']}
                onChange={(e) => handleUpdate(roundXDigits(e.target.value, 0, true), 'inst')}
                type='number'
                inputProps={{ min: 0 }}
                InputLabelProps={{shrink: true}}
              />
    
              <TextField
                className={classes.groupedField}
                size='small'
                variant='outlined'
                label='Monthly (gal.)'
                value={data['wpRate']['monthly']}
                onChange={(e) => handleUpdate(roundXDigits(e.target.value, 0, true), 'monthly')}
                type='number'
                inputProps={{ min: 0 }}
                InputLabelProps={{shrink: true}}
              />
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Typography variant='sectionTitle'>Change in Original Reference Elevation</Typography>
            <FormGroup row={true} className={classes.group}>
              <TextField
                size='small'
                className={classes.groupedField}
                variant='outlined'
                label='Static Water Level'
                value={data.swlElevDelta}
                InputLabelProps={{shrink: true}}
                InputProps={{endAdornment: <InputAdornment position='end'>%</InputAdornment>}}
                disabled
              />
              <TextField
                size='small'
                className={classes.groupedField}
                variant='outlined'
                label='Pumping Water Level'
                value={data.pwlElevDelta}
                InputLabelProps={{shrink: true}}
                disabled
                InputProps={{endAdornment: <InputAdornment position='end'>%</InputAdornment>}}
              />
            </FormGroup>
          </FormGroup>  
        </>
      }
      
      <div>
        <Comments
          classes={classes}
          data={data.comments}
          handleChange={handleChange}
          submitAttempted={submitAttempted}
          weekNum={weekNum}
          dateBounds={{min: mondaySelected, max: maxDate}}
          keepOne={data.unableToMeasure || data.inaccurateMeasure}
        />
      </div>
    </div>
  );
}

WeekReading.propTypes = {
  classes: PropTypes.object,
  weekNum: PropTypes.number,
  handleChange: PropTypes.func,
  data: PropTypes.object,
  submitAttempted: PropTypes.bool,
  mondaySelected: PropTypes.string
};