import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBtn = styled(Button)({
  color: 'white',
  backgroundColor: 'rgb(5,66,102)',
  height: 34,
  marginTop: 1,
  fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
  '&:hover': {
    backgroundColor: 'rgb(1,24,35)'
  }
});



export default function LogOutButton() {
  const { logout } = useAuth0();


  return (
    <StyledBtn
      onClick={() => logout({
        returnTo: window.location.origin
      })}
    >
      Log Out
    </StyledBtn>
  );
}