import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { formatISO } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';

import { roundXDigits, customFetch } from '../../../Helpers/index';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import PWSForm from './PWSForm';
import WellInformation from './WellInformation';
import WeekMeasAndRead from './WeekMeasAndRead';
import SubmitBtn from '../../SubmitBtn';
import { Loading, Section } from '../../index';
import IconPopper from '../../IconPopper';

import {
  Box,
  Typography,
  Link
} from '@mui/material';


const initialState = {
  submitDate: formatISO(new Date(), { representation: 'date' }),
  measurement: {
    method: '',
    refPointLevel: '',
    refPointType: ''
  },
  well: {
    tocElev: 0,
    swlElev: 0,
    pwlElev: 0
  },
  unableToMeasure: false,
  inaccurateMeasure: false,
  weekMonday: '',
  obsDate: '',
  time: '',
  wellStatus: {
    isStatic: '',
    staticOffTime: 0,
    dynamicPumping: '',
    dynamicRiseFall: ''
  },
  dtw: 0,
  wlElev: {
    static: 0,
    pumping: 0
  },
  wpRate: {
    inst: 0,
    monthly: 0
  },
  swlElevDelta: 0,
  pwlElevDelta: 0,
  comments: []
};


const useStyles = makeStyles(() => ({
  title: {
    marginTop: 30,
    textAlign: 'center'
  },
  date: {
    width: 115
  },
  sectionCont: {
    width: '100%',
    maxWidth: 740,
    margin: '0 auto'
  },
  group: {
    width: '95%',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  groupedField: {
    width: '48%',
  },
  btn: {
    backgroundColor: 'rgb(25,118,210) !important',
    color: 'white !important',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '200px !important',
    marginBottom: '40px !important',
    '&:hover': {
      backgroundColor: 'rgb(05,98,190) !important',
      color: 'rgb(230,230,230) !important',
    }
  },
  checkboxes: {
    width: '100%',
    maxWidth: 740,
    margin: '0 auto'
  },
  checkboxCol: {
    marginLeft: 7,
    marginRight: 7,
    marginTop: 10,
    '& > .MuiCheckbox-root': {
      margin: '0 auto',
      width: 'fit-content'
    }
  },
  labelCol: {
    marginTop: 21
  },
  iconBtnCont: {
    position: 'relative',
    top: -5,
    left: -6
  },
  iconBtn: {
    width: 40,
    height: 40,
    marginTop: '6px !important',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(210,118,25,0.04) !important'
    }
  },
  icon: {
    color: 'rgb(220,40,40)'
  },
  hr: {
    backgroundColor: 'rgb(242,208,59)',
    height: 4,
    width: '65%',
    margin: '30px auto'
  },
  weekHeading: {
    display: 'flex',
    justifyContent: 'center'
  },
  subHeadingLine: {
    display: 'flex',
    width: 'fit-content',
    margin: '0 auto'
  },
  inputsCont: {
    gap: 30,
    marginTop: 10
  }
}));



export default function GroundWaterForm({ domain, prefill, siteAlias, setShowResults, editData, setSiteMetaData, setSite }) {
  const [formData, setFormData] = useState([JSON.parse(JSON.stringify(initialState))]);
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const classes = useStyles();
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (editData && Object.keys(editData.data).length > 0) {
      setFormData([editData.data]);
    }
  }, [editData]);


  const getCalculatedValues = (isStatic, dtw, well) => {
    let diff = roundXDigits(well.tocElev - dtw, 1, true);
  
    let swlElevDelta, pwlElevDelta, staticWL, pumping;
    if (isStatic === 'Yes') {
      swlElevDelta = roundXDigits(diff / well.swlElev * 100, 2, true);
      pwlElevDelta = 100.00;
      staticWL = diff;
      pumping = 'N/A';
    } else {
      swlElevDelta = 100.00;
      pwlElevDelta = roundXDigits(diff / well.pwlElev, 2, true);
      staticWL = 'N/A';
      pumping = diff;
    }
  
    if (isNaN(swlElevDelta)) {
      swlElevDelta = 0;
    }

    if (isNaN(pwlElevDelta)) {
      pwlElevDelta = 0;
    }

    return { swlElevDelta, pwlElevDelta, wlElev: { static: staticWL, pumping } };
  };

  const handleChange = (value, i, key) => {
    if (key === 'week') {
      if (value === 'addWeek') {
        let newWeek = JSON.parse(JSON.stringify(initialState));

        newWeek.measurement = { ...formData[0].measurement };
        newWeek.well = { ...formData[0].well };

        setFormData(prev => [ ...prev, newWeek ]);
      } else {
        setFormData(prev => prev.filter((week,j)=> j !== i));
      }
    } else {
      let currWeekData = formData[i];
  
      let dtw, isStatic;
      if (key === 'wellStatus') {
        dtw = currWeekData.dtw;
        isStatic = value.isStatic;
      } else if (key === 'dtw') {
        dtw = value;
        isStatic = currWeekData.wellStatus.isStatic;
      } else if (key === 'unableToMeasure') {
        if (value) {
          let newObj = JSON.parse(JSON.stringify(initialState));
          newObj.weekMonday = currWeekData.weekMonday;
          newObj.comments = currWeekData.comments;
          newObj.well = { ...currWeekData.well };
          newObj.measurement = { ...currWeekData.measurement };
          
          if (newObj.comments.length === 0) {
            newObj.comments = [{ date: '', comment: ''}];
          }
          
          currWeekData = newObj;
        }
      } else if (key === 'inaccurateMeasure') {
        currWeekData.unableToMeasure = false;
        
        if (currWeekData.comments.length === 0) {
          currWeekData.comments = [{date: '', comment: ''}];
        }
      }
      
      const calcVals = (dtw !== undefined && isStatic !== undefined) ? getCalculatedValues(isStatic, dtw, currWeekData.well) : {};
  
      setFormData(prev => {
        let newPrev = [ ...prev ];
  
        newPrev[i] = {
          ...currWeekData,
          ...calcVals,
          [key]: value
        };
  
        return newPrev;
      });
    }
  };

  const handleChangeAll = (value, key) => {
    setFormData(prev => {
      return prev.map(weekData => {
        const calcVals = key === 'well' ? getCalculatedValues(weekData.wellStatus.isStatic, weekData.dtw, weekData.well) : {};

        return {
          ...weekData,
          ...calcVals,
          [key]: value
        };
      });
    });
  };

  const requestSubmit = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/saveData`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        data: formData,
        type: 'ground',
        siteAlias,
        user: {
          name: user.name,
          userAlias: user.sub,
        }
      })
    });
  };

  const handleSubmit = async () => {
    // Prevents double clicks from sending multiple requests
    if (!loading) {
      setLoading(true);

      // Activates error highlighting for inputs
      if (!submitAttempted) {
        setSubmitAttempted(true);
      }
      
      // Check for missing data
      let missingData = false;
      let mondaysMatch = false;
      let mondays = [];
      formData.forEach(weekData => {
        if (weekData.unableToMeasure) {
          weekData.comments.forEach(commentObj => {
            if (commentObj.date === '' || commentObj.comment === '') {
              missingData = true;
            }
          });
        } else {
          Object.keys(weekData).forEach(field => {
            let fieldValue = weekData[field];
            if (['measurement', 'wellStatus', 'wlElev', 'wpRate'].includes(field)) {
              Object.keys(fieldValue).forEach(key => {
                if (fieldValue[key] === '' && (key !== 'dynamicRiseFall' || (key === 'dynamicRiseFall' && fieldValue['dynamicPumping'] === 'Yes'))) {
                  missingData = true;
                }
              });
            } else if (field === 'comments') {
              fieldValue.forEach(commentObj => {
                if (commentObj.date === '' || commentObj.comment === '') {
                  missingData = true;
                }
              });
            } else if (field === 'weekMonday') {
              if (fieldValue === '') {
                missingData = true;
              } else {
                if (mondays.includes(fieldValue)) {
                  missingData = true;
                  mondaysMatch = true;  
                } else {
                  mondays.push(fieldValue);
                }
              }
            } else if (fieldValue === '') {
              missingData = true;
            }
          });
        }
      });

      if (!missingData) {
        let results = await requestSubmit();
        
        // If token expired during call, try again, this should auto refresh the token
        if (results.code === 419) {
          results = await requestSubmit();
        }
        
        if (results.code === 200) {
          if (!(editData && Object.keys(editData).length > 0)) {
            setFormData([JSON.parse(JSON.stringify(initialState))]);
            setSubmitAttempted(false);
            setSiteMetaData(prev => { return { ...prev, missingMondays: prev.missingMondays.filter(date => !mondays.includes(date)) }; });

            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            setSite(null);
          }
        }
  
        setShowResults({
          code: results.code,
          msg: results.res,
          type: 'data',
          action: 'saved'
        });
      } else {
        if (mondaysMatch) {
          setShowResults({
            type: '',
            action: '',
            code: 'Duplicate Weeks selected',
            msg: 'You have chosen the same Monday date for multiple weeks. Please change one and ensure that your submission data is accurate.'
          });
        } else {
          setShowResults({
            code: 'Not Submitted',
            msg: 'It seems there is some missing data!',
            type: 'data',
            action: 'saved'
          });
        }
      }
  
      setLoading(false);
    }
  };

  const renderSectionTitle = () => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant='h2' sx={{color: 'white'}}>Measurements and Readings</Typography>
        <IconPopper
          vertAdjust='11px'
          icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'white', fontSize: '14px !important'}} />}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant='popupSubheading'>Use TOC as a reference point (measurement point)</Typography>
            <Typography variant='popupBody'>If there is a measuring tube, use the top of measuring tube as the reference point and future measurements should be taken from the same spot.</Typography>
            <Typography variant='popupBody'>In this case, TOC will refer to Top of Measuring Tube.</Typography>
            <Typography variant='popupBody'>When filling the form, Height of Casing from Land Surface is negative if TOC or measurement point is below land surface.</Typography>

            <Typography variant='popupSubheading'>What can affect water level measurement?</Typography>
            <Typography variant='popupBody'>Surrounding water uses and seasonal patterns affect the static water level.</Typography>
            <Typography variant='popupBody'>Generally, higher water levels occur in the winter due to higher recharge rates and less pumping and lower in the summer due to lower recharge rates and high pumping.</Typography>

            <Typography variant='popupSubheading'>Where Can I find more information?</Typography>
            <Typography variant='popupBody'>Most Well Construction (Initial / Aquifer Test) Reports may have information such as the TOC, original reference static and pumping water levels, land surface elevation and / or depth to bottom of the well.</Typography>
            <Typography variant='popupBody'>Otherwise, use your first static and pumping water level elevation measurements done in or after February 2017 as the original reference level elevation. </Typography>
            <Typography variant='popupBody'>Click on the following to learn more on how to take water level measurements:</Typography>

            <Link href='http://www.twdb.texas.gov/groundwater/docs/UMs/UM-52.pdf' variant='popupLink'>Texas Water Development Board - Field Manual</Link>

            <Link href='https://www.bookstore.ksre.ksu.edu/pubs/MF2669.pdf' variant='popupLink'>Kansas State University - Measuring the Depth to Water in Wells</Link>

            <Link href='http://www.farmsreach.com/welcome/wp-content/uploads/2014/03/GroundwaterLevelMonitoring.pdf' variant='popupLink'>University of California - Groundwater Level Monitoring</Link>
          </div>
        </IconPopper>
      </div>
    );
  };


  return (
    <Box>
      <div className={classes.title}>
        <Typography variant='h1'>Groundwater Level Monitoring and Reporting Form</Typography>
        <div className={classes.subHeadingLine}>
          <Typography variant='subtitle1'>Pursuant to RCSA Section 19-13-B102(n)(4)</Typography>
          
          <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />}>
            <Typography variant='popupSubheading'>What are the required measurement and reporting frequencies?</Typography>
            <Typography variant='popupBody'>Groundwater weekly instantaneous pumping rates, monthly pumping rates and water level elevations (static and/or pumping) are required to be measured weekly and submitted on a monthly basis.</Typography>
            <Typography variant='popupBody'>Complete and submit this form for each well in your water system if your water system has multiple active wells.</Typography>
            <Typography variant='popupBody'>Completed reports are due by the 9th of the month following every monitoring month and must be submitted electronically via this portal.</Typography>
            <Typography variant='popupBody'>Printed and filled paper reporting forms that are mailed or scanned and emailed are not acceptable.</Typography>
          </IconPopper>
        </div>
      </div>

      <Section titleText='Public Water System'>
        <PWSForm
          classes={classes}
          data={prefill}
        />
      </Section>

      <Section titleText='Well Information'>
        <WellInformation
          classes={classes}
          well={formData[0].well}
          measurement={formData[0].measurement}
          handleChangeAll={handleChangeAll}
          submitAttempted={submitAttempted}
          wellID={prefill.site.wellID}
        />
      </Section>

      <Section titleText={renderSectionTitle()}>
        <WeekMeasAndRead
          classes={classes}
          weeksData={formData}
          handleChange={handleChange}
          submitAttempted={submitAttempted}
          missingMondays={prefill.site.missingMondays || [editData.weekMonday]}
          readOnly={editData && Object.keys(editData).length > 0}
        />
      </Section>

      <SubmitBtn btnText='Submit Ground Water Data' submitFunc={handleSubmit} />

      {loading && <Loading />}
    </Box>
  );
}

GroundWaterForm.propTypes = {
  domain: PropTypes.string,
  prefill: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  editData: PropTypes.object,
  siteAlias: PropTypes.string,
  setShowResults: PropTypes.func,
  setSiteMetaData: PropTypes.func,
  setSite: PropTypes.func
};