import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  TextField,
  FormGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
    width: '100%',
    maxWidth: 740
  },
  textfield: {
    width: '48%',
  },
  row: {
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '95%'
  }
}));



export default function BasicInfo({ data }) {
  const classes = useStyles();


  return (
    <FormGroup className={classes.box}>
      <FormGroup>
        <Typography variant='sectionTitle' sx={{marginTop: 0}}>Public Water System</Typography>
        <FormGroup row={true} className={classes.row}>
          <TextField
            className={classes.textfield}
            size='small'
            label='PWS Name'
            value={data.company.meta.name}
            disabled
          />
          <TextField
            className={classes.textfield}
            size='small'
            label='PWS ID'
            value={data.company.meta.pwsID}
            disabled
          />
        </FormGroup>
      </FormGroup>

      <FormGroup>
        <Typography variant='sectionTitle'>Treatment Water System Facility</Typography>
        <FormGroup row={true} className={classes.row}>
          <TextField
            className={classes.textfield}
            size='small'
            label='TWSF Name'
            value={data.site.twfName}
            disabled
          />
          <TextField
            className={classes.textfield}
            size='small'
            label='TWSF ID'
            value={data.site.twsfID}
            disabled
          />
        </FormGroup>
      </FormGroup>

      <FormGroup>
        <Typography variant='sectionTitle'>Location</Typography>
        <FormGroup row={true} sx={{ justifyContent: 'space-around' }}>
          <TextField
            className={classes.input}
            size='small'
            variant='outlined'
            label='Latitude'
            value={data.site.lngLat[1]}
            disabled
          />

          <TextField
            className={classes.input}
            size='small'
            variant='outlined'
            label='Longitude'
            value={data.site.lngLat[0]}
            disabled
          />
        </FormGroup>
      </FormGroup>
    </FormGroup>
  );
}

BasicInfo.propTypes = {
  data: PropTypes.object,
};