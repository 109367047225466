export default function constructObj(dataKeys, initValue) {
  let obj = {};
  
  dataKeys.forEach(key => {
    if (key === 'swfs') {
      obj[key] = [];
    } else {
      obj[key] = initValue;
    }
  });

  return obj;
}