import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    padding: '20px 8px',
    width: '100%',
    boxSizing: 'border-box',
    color: 'white',
    zIndex: 1
  },
  text: {
    marginTop: '20px !important'
  }
}));



export default function ResultPopup({ code, msg, type, action }) {
  const classes = useStyles();

  let result, message, text, color, bColor;
  if (code !== 200) {
    result = `Error - ${code}`;
    message = msg;
    text = 'If this problem occurs repeatedly please inform the website administrator.';
    bColor= 'rgb(199, 87, 93)';
    color = 'rgb(219,107,113)';
  } else {
    result = 'Success!';
    bColor= 'rgb(107,201,35)';
    color = 'rgb(77,171,5)';
    
    if (msg.msg) {
      if (msg.msg.includes('password')) {
        message = msg.msg;
      } else {
        message = `This ${type} has been ${msg.msg}.`;
      }
    } 

    if (action === 'created') {
      if (type === 'user') {
        text = 'A password reset email has been sent to the email address that you provided.';
      } else {
        text = `To see the new ${type} in any menus you may have to refresh the page.`;
      }
    } else {
      text = 'It can take up to 30 seconds for changes to take effect, and you may need to refresh the page to see the new or edited data.';
    }
  }



  return (
    <div className={classes.container} style={{ backgroundColor: color, borderTop: `2px solid ${bColor}` }}>
      <div>
        <Typography variant='h1'>{result}</Typography>
        <Typography variant='h4'>{message}</Typography>
        <Typography variant='body2' className={classes.text}>{text}</Typography>
      </div>
    </div>
  );
}

ResultPopup.propTypes = {
  code: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  msg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  action: PropTypes.string
};