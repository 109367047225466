import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export default function Chart({ data }) {
  const theme = useTheme();

  const options = {
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    chart: {
      type: 'column',
      height: 275,
      width: 200,
      style: {
        fontFamily: theme.typography.fontFamily,
      },
      spacing: [5, 30, 8, 0],
    },
    plotOptions: {
      column: {
        borderWidth: 1,
        borderColor: 'rgb(180,180,180)',
        grouping: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      line: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    title: {
      floating: true,
      text: '',
    },
    xAxis: {
      categories: [0, 1, 2],
      title: {
        enabled: false,
      },
      plotLines: [
        {
          value: -0.465,
          color: 'black',
        },
      ],
      min: 0,
      max: 2,
      width: 115,
      labels: {
        enabled: false,
      },
      lineColor: 'black',
    },
    yAxis: {
      title: {
        text: 'Percent Full',
      },
      labels: {
        x: -5,
        style: {
          color: 'black',
        },
      },
      gridLineWidth: 0,
      tickWidth: 1,
      tickLength: 3,
      tickColor: 'black',
      min: 0,
      max: 100,
      tickAmount: 5,
    },
    series: [
      {
        data: [{ x: 1, y: data.hist }],
        color: 'rgb(233,177,59)',
        pointWidth: 70,
      },
      {
        data: [{ x: 1, y: data.curr }],
        pointWidth: 50,
        color: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
          },
          stops: [
            [0, '#1025da'],
            [0.3, '#1025da'],
            [1, '#03061f'],
          ],
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <Box>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}

Chart.propTypes = {
  data: PropTypes.object,
};
