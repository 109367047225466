import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Typography }from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledBtn = styled(Button)({
  color: 'white',
  backgroundColor: 'rgb(5,66,102)',
  fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
  width: 300,
  height: 100,
  fontSize: 20,
  borderRadius: 15,
  '&:hover': {
    backgroundColor: 'rgb(1,24,35)'
  }
});



export default function EditOrSubmit() {
  const navigate = useNavigate();

  return (
    <div>
      <Typography variant='h1' sx={{textAlign: 'center', marginTop: 1, marginBottom: 8}}>Would you like to</Typography>

      <div style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        maxWidth: 1000,
        margin: '0 auto'
      }}>
        <StyledBtn onClick={() => navigate('/Data/New')}>Submit New Data</StyledBtn>
        <Typography variant='h2'>OR</Typography>
        <StyledBtn onClick={() => navigate('/Data/Edit')}>Edit Past Data Entries</StyledBtn>
      </div>
    </div>
  );
}