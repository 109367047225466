import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Autocomplete,
  TextField
} from '@mui/material';



// Component to list items from given options
export default function ListOptions({ selected, options, type, handleChangeSelected }) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      value={selected}
      onChange={(e,val) => handleChangeSelected({ [type]: val })}
      inputValue={inputValue}
      onInputChange={(e,val) => setInputValue(val)}
      options={options}
      sx={{ width: '65%', minWidth: 300, margin: '0 auto' }}
      renderInput={(params) => <TextField {...params} size='small' label={type === 'site' ? 'Site' : type[0].toUpperCase() + type.slice(1)} />}
      getOptionLabel={option => typeof option === 'string' ? option : option[0]}
      renderOption={(props, option) => {
        if (typeof option === 'string') {
          return <li {...props} key={option}>{option}</li>;
        } else {
          return <li {...props} key={option[1]} style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>{option[0]}</div>
            <div style={{fontStyle: 'italic', color: 'rgb(150,150,150)'}}>{option[2]}</div>
          </li>;
        }
      }}
      filterOptions={(opts, state) => {
        let newOptions = [];
        options.forEach((element) => {
          let lower = typeof element === 'string' ? element : element[0];
          lower = lower.toLowerCase();

          if (lower.includes(state.inputValue.toLowerCase())) {
            newOptions.push(element);
          }
        });
        return newOptions;
      }}
    />
  );
}

ListOptions.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  options: PropTypes.array,
  type: PropTypes.string,
  handleChangeSelected: PropTypes.func
};