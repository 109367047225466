import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
  FormHelperText
} from '@mui/material';



export default function MeasMethod({ classes, data, handleChangeAll, submitAttempted }) {
  const renderMeasMeths = useMemo(() => {
    const rows = ['Steel Tape', 'Sounder', 'Air Hose Line', 'Pressure Transducer'];

    return rows.map((name, i) => {
      return (
        <FormGroup key={i} row={true} sx={{justifyContent: 'space-between', alignItems: 'center', gap: '35px'}}>
          <FormLabel sx={{color: (submitAttempted && data.method === '')? 'red !important' : 'black !important'}}>{name}</FormLabel>
          <Checkbox checked={name === data.method} onChange={() => handleChangeAll({...data, method: name}, 'measurement')} sx={(submitAttempted && data.method === '') ? {color: 'red'} : {}}/>
        </FormGroup>
      );
    });
  }, [data, submitAttempted]);

  
  return (
    <FormGroup className={classes.checkboxes}>
      <FormControl error={submitAttempted && data.method === ''}>
        <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Measurement Method</Typography>
        <FormGroup sx={{width: 244, marginLeft: '20px'}}>
          {renderMeasMeths}
        </FormGroup>
      </FormControl>
      { submitAttempted && data.method === '' && <FormHelperText error sx={{marginLeft: 10}}>*Required</FormHelperText> }
    </FormGroup>
  );
}

MeasMethod.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  handleChangeAll: PropTypes.func,
  submitAttempted: PropTypes.bool
};