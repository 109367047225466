import React from 'react';
import PropTypes from 'prop-types';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Typography
} from '@mui/material';



export default function RadioOpts({ label, options, value, setValue, error }) {
  return (
    <FormControl>
      <Typography sx={{color: 'black', fontSize: '14px', fontWeight: 500}}>{label}</Typography>
      <RadioGroup
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {
          options.map((option, i) => <FormControlLabel key={i} value={option} control={<Radio />} label={option} />)
        }
      </RadioGroup>
      {error && <FormHelperText sx={{color: 'red'}}>*Required</FormHelperText>}
    </FormControl>
  );
}

RadioOpts.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  setValue: PropTypes.func,
  error: PropTypes.bool
};