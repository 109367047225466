import React, { useMemo } from 'react';
// import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@mui/styles';
import {
  TextField,
  MenuItem,
  Typography
}from '@mui/material';
import { useTheme } from '@emotion/react';

import exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
highchartsMore(Highcharts); 
exporting(Highcharts);
NoDataToDisplay(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

import { Section } from './index';

const useStyles = makeStyles(() => ({
  siteSelector: {
    width: 200,
    borderRadius: 4,
    height: 41
  },
  graphsCont: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgb(241,241,241)',
    paddingBottom: 20
  },
  graph: {
    width: '48%',
    // margin: '1%',
    // height: 565,
    boxSizing: 'border-box',
    paddingTop: 20,
    // paddingTop: 65
  },
  selectCont: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    marginBottom: 12
  },
  title: {
    backgroundColor: 'rgb(84,141,212)',
    // padding: 12,
    // width: '96%',
    // margin: '0 auto',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 10
  }
}));

const mediumBlue = 'rgb(124, 181, 236)';
const darkBlue = 'rgb(20, 84, 143)';
const precipGreen = 'rgb(18, 161, 56)';
const precipAxis = {
  text: 'Precipitation (inches)',
  opposite: true,
  color: precipGreen
};
const units = {
  'Weekly Precipitation': ' in',
  'Total Precipitation': ' in',
  'Top Of Casing (TOC) Elevation': ' ft',
  'Static Water Level Elevation': ' ft',
  'Pumping Water Level Elevation': ' ft',
  'Monthly Water Pumping Rate': ' gal',
  'Instantaneous Water Pumping Rate': ' GPM',
  'Percent Change in Orig. Ref. Static Elevation': '%',
  'Percent Change in Orig. Ref. Pumping Elevation': '%'
};

const constructYAxes = (arr) => {
  return arr.map(({ text, color, opposite }) => {
    return {
      title: {
        text,
        style: {
          color
        }
      },
      labels: {
        style: {
          color
        }
      },
      opposite
    };
  });
};



export default function Graphs({ data, site, setSite, siteList }) {
  // const [swf, setSwf] = useState('');
  // const [deltaGraph, setDeltaGraph] = useState('static');
  // const [supplyGraph, setSupplyGraph] = useState('avgDP');
  // const [swfGraph, setSWFGraph] = useState('storage');
  
  const classes = useStyles();
  const theme = useTheme();

  const options =  {
    exporting:{
      enabled: true
    },
    credits: {enabled: false},
    chart: {
      zoomType: 'x',
      height: 500,
      marginTop: 80,
      marginBottom: 120,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(210,210,210)',
      style: {
        fontFamily: theme.typography.fontFamily
      }
    },
    title: {
      floating: true,
    },
    xAxis: {
      categories: data.months,
      title: {
        text: 'Month',
        style: {
          color: 'black'
        }
      },
      labels: {
        rotation: -20,
        style: {
          color: 'black'
        }
      },
      tickWidth: 1,
      tickmarkPlacement: 'on',
      tickLength: 7
    }
  };

  // useEffect(() => {
  //   if (data && data.type === 'surface') {
  //     setSwf(Object.keys(data.graph5)[0]);
  //   } else {
  //     setSwf('');
  //   }
  // }, [data]);

  // const renderGroundGraphs = useMemo(() => {
  //   if (data && data.type === 'ground') {
  //     let title, series, yLabel, tooltip, type;

  //     return ['graph1', 'graph2', 'graph3', 'graph4'].map(key => {
  //       if (key === 'graph1') {
  //         type = 'line';
  //         title = 'Monthly Averages of TOC Elevation, Static Water Level, and Pumping Water Level';
  //         series = [
  //           { data: data.graph1[0], name: 'Top Of Casing (TOC) Elevation' },
  //           { data: data.graph1[1], name: 'Static Water Level Elevation' },
  //           { data: data.graph1[2], name: 'Pumping Water Level Elevation' }
  //         ];
  //         yLabel = 'Average Elevation (feet)';
  //         tooltip = {
  //           shared: true
  //         };
  //       } else if (key === 'graph2') {
  //         type = 'line';
  //         title = 'Monthly Averages of Distance To Water from Top Of Casing';
  //         series = [
  //           { data: data.graph2, name: 'Distance To Water (DTW)' }
  //         ];
  //         yLabel = 'Distance (feet)';
  //         tooltip = {};
  //       } else if (key === 'graph3') {
  //         type = 'line';
  //         title = 'Monthly Averages of Weekly Instantaneous Water Pumping Rate';
  //         series = [
  //           { data: data.graph3, name: 'Instantaneous Water Pumping Rate' }
  //         ];
  //         yLabel = 'Gallons Per Minute (GPM)';
  //         tooltip = {};
  //       } else if (key === 'graph4') {
  //         type = 'column';
  //         title = 'Monthly Amount of Water Pumped';
  //         series = [
  //           { data: data.graph4, name: 'Total Water Pumped' }
  //         ];
  //         yLabel = 'Gallons (gal.)';
  //         tooltip = {};
  //       }

  //       let opts =  {
  //         ...options,
  //         chart: {
  //           ...options.chart,
  //           type
  //         },
  //         title: {
  //           ...options.title,
  //           text: title
  //         },
  //         series,
  //         yAxis: {
  //           ...options.yAxis,
  //           title: {
  //             text: yLabel
  //           }
  //         },
  //         tooltip
  //       };

  //       return <div key={uuidv4()} className={classes.graph}><HighchartsReact highcharts={Highcharts} options={opts} /></div>;
  //     });
  //   } else {
  //     return '';
  //   }
  // }, [data]);

  const renderGroundGraphs = useMemo(() => {
    if (data && data.type === 'ground') {
      let title, series, yAxis, type;

      return ['graph1', 'graph2', 'graph3'].map(key => {
        if (key === 'graph1') {
          type = 'spline';
          title = 'Monthly Averages of Water Level Elevations and Monthly Averages of Weekly Precipitation';
          series = [
            {
              data: data.graph1.toc,
              name: 'Top Of Casing (TOC) Elevation',
              yAxis: 0,
              color: 'black',
              marker: {
                enabled: false
              }
            },
            {
              data: data.graph1.swl,
              name: 'Static Water Level Elevation',
              yAxis: 0,
              color: mediumBlue
            },
            {
              data: data.graph1.pwl,
              name: 'Pumping Water Level Elevation',
              yAxis: 0,
              color: darkBlue
            },
            {
              data: data.precipData[0],
              name: 'Weekly Precipitation',
              yAxis: 1,
              color: precipGreen
            }
          ];

          yAxis = constructYAxes([
            {
              text: 'Average Elevation (feet)',
              color: 'black',
              opposite: false
            },
            precipAxis
          ]);
        } else if (key === 'graph2') {
          type = 'spline';
          title = 'Monthly Averages of Water Pumping Rates and Monthly Total Precipitation';
          series = [
            {
              data: data.graph2.monthly,
              name: 'Monthly Water Pumping Rate',
              type: 'column',
              yAxis: 1,
              color: darkBlue
            },
            {
              data: data.graph2.inst,
              name: 'Instantaneous Water Pumping Rate',
              yAxis: 0,
              color: mediumBlue
            },
            {
              data: data.precipData[1],
              name: 'Total Precipitation',
              yAxis: 2,
              color: precipGreen
            }
          ];

          yAxis = constructYAxes([
            {
              text: 'Gallons Per Minute (GPM)',
              color: mediumBlue,
              opposite: false
            },{
              text: 'Gallons',
              color: darkBlue,
              opposite: false
            },
            precipAxis
          ]);
        } else if (key === 'graph3') {
          type = 'spline';
          title = 'Monthly Averages of Percent Change in Orig. Ref. Elevations and Weekly Averages of Weekly Precipitation';
          series = [
            {
              data: data.graph3.swlElevDelta,
              name: 'Percent Change in Orig. Ref. Static Elevation',
              yAxis: 0,
              color: mediumBlue
            },
            {
              data: data.graph3.pwlElevDelta,
              name: 'Percent Change in Orig. Ref. Pumping Elevation',
              yAxis: 0,
              color: darkBlue
            },
            {
              data: data.precipData[0],
              name: 'Total Precipitation',
              type: 'spline',
              yAxis: 1,
              color: precipGreen
            }
          ];

          yAxis = constructYAxes([
            {
              text: 'Percent',
              color: mediumBlue,
              opposite: false
            },
            precipAxis
          ]);
        }

        let opts =  {
          ...options,
          chart: {
            ...options.chart,
            type
          },
          plotOptions: {
            spline: {
              marker: {
                symbol: 'circle'
              }
            }
          },
          title: {
            ...options.title,
            text: title
          },
          series,
          yAxis,
          tooltip: {
            shared: true,
            useHTML: true,
            formatter: function() {
              return this.points.reduce((acc, point) => {
                let name = point.series.name;

                let precision = 2;
                if (name.includes('Elevation')) {
                  precision = 1;
                } else if (name.includes('Rate')) {
                  precision = 0;
                }

                acc += `<div style="display: flex; justify-content: space-between;"><div>${name}:&nbsp;&nbsp;</div><div style="color: ${point.color};"><b>${Highcharts.numberFormat(point.y, precision)}</b>${units[name]}</div></div>`;
                return acc;
              }, `<b>${this.x}</b>`);
            }
          }
        };

        return <div key={uuidv4()} className={classes.graph}><HighchartsReact highcharts={Highcharts} options={opts} /></div>;
      });
    } else {
      return '';
    }
  }, [data]);

  // const renderDeltaGraph = useMemo(() => {
  //   if (data && data.type === 'ground') {
  //     let title, series, yLabel, tooltip, type;
      
  //     if (deltaGraph === 'static') {
  //       type = 'line';
  //       title = 'Monthly Averages of Percent Change in Orig. Ref. Static Elevation';
  //       series = [
  //         { data: data.graph5.swlElevDelta, name: 'Percent Change in Orig. Ref. Static Elevation' },
  //       ];
  //       yLabel = 'Percent';
  //       tooltip = {};
  //     } else if (deltaGraph === 'pump') {
  //       type = 'line';
  //       title = 'Monthly Averages of Percent Change in Orig. Ref. Pumping Elevation';
  //       series = [
  //         { data: data.graph5.pwlElevDelta, name: 'Percent Change in Orig. Ref. Pumping Elevation' }
  //       ];
  //       yLabel = 'Percent';
  //       tooltip = {};
  //     }

  //     let opts =  {
  //       ...options,
  //       chart: {
  //         ...options.chart,
  //         type
  //       },
  //       title: {
  //         ...options.title,
  //         text: title
  //       },
  //       series,
  //       yAxis: {
  //         ...options.yAxis,
  //         title: {
  //           text: yLabel
  //         }
  //       },
  //       tooltip
  //     };

  //     return <div key={uuidv4()} className={classes.graph} style={{paddingTop: 13}}>
  //       <div className={classes.selectCont}>
  //         <TextField
  //           select
  //           size='small'
  //           label='Graph Data'
  //           value={deltaGraph}
  //           onChange={(e) => setDeltaGraph(e.target.value)}
  //           sx={{ width: 250 }}
  //         >
  //           <MenuItem value={'static'}>Orig. Ref. Static Elevation</MenuItem>
  //           <MenuItem value={'pump'}>Orig. Ref. Pumping Elevation</MenuItem>
  //         </TextField>
  //       </div>

  //       <HighchartsReact highcharts={Highcharts} options={opts} />
  //     </div>;
  //   } else {
  //     return '';
  //   }
  // }, [data, deltaGraph]);

  // const renderSurfaceGraphs = useMemo(() => {
  //   if (data && data.type === 'surface') {
  //     let title, series, yLabel, tooltip, type;

  //     return ['graph1', 'graph2'].map(key => {
  //       if (key === 'graph1') {
  //         type = 'line';
  //         title = 'Monthly Averages of Total Usable Storage and Instantaneous Usable Storage';
  //         series = [
  //           { data: data.graph1.totalIus, name: 'Instantaneous Usable Storage', type: 'area', fillColor: 'rgba(124, 181, 236, 0.3)', yAxis: 0 },
  //           { data: data.graph1.totalTus, name: 'Total Usable Storage', yAxis: 0 }
  //         ];
  //         yLabel = 'Millions of Gallons (MG)';
  //         tooltip = {
  //           shared: true,
  //         };
  //       } else if (key === 'graph2') {
  //         type = 'line';
  //         title = 'Monthly Averages of Average Percent Full';
  //         series = [
  //           { data: data.graph2, name: 'Average Percent Full', yAxis: 0 }
  //         ];
  //         yLabel = 'Percent Full';
  //         tooltip = {};
  //       }
        
  //       series.push({ data: data.precipData, name: 'Weekly Precipitation', yAxis: 1 });
        
  //       let opts =  {
  //         ...options,
  //         chart: {
  //           ...options.chart,
  //           type
  //         },
  //         title: {
  //           ...options.title,
  //           text: title
  //         },
  //         series,
  //         yAxis: [{
  //           ...options.yAxis,
  //           title: {
  //             text: yLabel
  //           }
  //         },{
  //           ...options.yAxis,
  //           title: {
  //             text: 'Precipitation (inches)'
  //           },
  //           opposite: true
  //         }],
  //         tooltip
  //       };

  //       return <div key={uuidv4()} className={classes.graph}><HighchartsReact highcharts={Highcharts} options={opts} /></div>;
  //     });
  //   } else {
  //     return '';
  //   }
  // }, [data]);

  const renderSurfaceGraphs = useMemo(() => {
    if (data && data.type === 'surface') {
      let title, series, yLabel;

      return ['graph1', 'graph2', 'graph3', 'graph4'].map(key => {
        if (key === 'graph1') {
          title = 'Monthly Averages of Total Usable Storage, Instantaneous Usable Storage, and Weekly Precipitation';
          series = [
            {
              type: 'spline',
              data: data.graph1.totalTus,
              name: 'Total Usable Storage',
              yAxis: 0,
              color: 'black',
              marker: {
                enabled: false,
                symbol: 'circle'
              }
            },
            {
              data: data.graph1.totalIus,
              name: 'Instantaneous Usable Storage',
              type: 'area',
              yAxis: 0,
              color: 'rgb(124, 181, 236)',
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                  [0, 'rgba(124,181,236, 0.3)'],
                  [1, 'rgba(255,255,255, 0.3)'],
                ]
              },
              marker: {
                symbol: 'circle'
              }
            }
          ];
          yLabel = 'Millions of Gallons (MG)';
        } else if (key === 'graph2') {
          title = 'Monthly Averages of Average Percent Full and Weekly Precipitation';
          series = [
            {
              data: data.graph2,
              name: 'Average Percent Full',
              type: 'area',
              fillColor: {
                linearGradient: [0,0,0,300],
                stops: [
                  [0, 'rgba(124,181,236, 0.3)'],
                  [0.75, 'rgba(255,255,255, 0.3)'],
                  [1, 'rgba(235, 64, 52,0.3)']
                ]
              },
              yAxis: 0
            }
          ];
          yLabel = 'Percent Full (%)';
        } else if (key === 'graph3') {
          title = 'Monthly Averages of Average Daily Production and Average Daily Precipitation';
          series = [
            {
              data: data.graph3,
              name: 'Average Daily Production',
              type: 'spline',
              yAxis: 0,
              color: 'rgb(124, 181, 236)',
              // fillColor: {
              //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1},
              //   stops: [
              //     [0, 'rgba(124,181,236, 0.3)'],
              //     [1, 'rgba(255,255,255, 0.3)'],
              //   ]
              // }
            },
            {
              data: data.precipData[2],
              name: 'Average Daily Precipitation',
              type: 'spline',
              yAxis: 1,
              marker: {
                symbol: 'circle'
              },
              color: 'rgb(18, 161, 56)'
            }
          ];
          yLabel = 'Millions of Gallons per Day (MGD)';
        } else if (key === 'graph4') {
          title = 'Monthly Averages of Total Withdrawn and Total Monthly Precipitation';
          series = [
            {
              data: data.graph4,
              name: 'Total Withdrawn',
              type: 'column',
              yAxis: 0,
              color: 'rgb(124, 181, 236)',
              // fillColor: {
              //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1},
              //   stops: [
              //     [0, 'rgba(124,181,236, 0.3)'],
              //     [1, 'rgba(255,255,255, 0.3)'],
              //   ]
              // }
            },
            {
              data: data.precipData[1],
              name: 'Total Precipitation',
              type: 'column',
              yAxis: 1,
              color: 'rgb(18, 161, 56)'
            }
          ];
          yLabel = 'Millions of Gallons (MG)';
        }
        
        let opts =  {
          ...options,
          title: {
            ...options.title,
            text: title
          },
          series,
          yAxis: [{
            gridLineWidth: 1,
            title: {
              text: yLabel,
              style: {
                color: 'rgb(94, 151, 206)'
              }
            },
            labels: {
              style: {
                color:  'rgb(124, 181, 236)'
              }
            }
          },{
            title: {
              text: 'Precipitation (inches)',
              style: {
                color: 'rgb(0, 121, 16)'
              }
            },
            labels: {
              style: {
                color: 'rgb(18, 161, 56)'
              }
            },
            opposite: true
          }]
        };
        

        if (key === 'graph1' || key === 'graph2') {
          opts.tooltip = {
            shared: true,
            useHTML: true,
            formatter: function() {
              return this.points.reduce((acc, point) => {
                let name = point.series.name;
                let units = name === 'Weekly Precipitation' ? ' in' : (key === 'graph1' ? ' MG' : ' %');
                acc += `<div style="display: flex; justify-content: space-between;"><div>${point.series.name}:&nbsp;&nbsp;</div><div style="color: ${point.color};"><b>${Highcharts.numberFormat(point.y, 2)}</b>${units}</div></div>`;
                return acc;
              }, `<b>${this.x}</b>`);
            }
          };
          
          opts.series.push({
            data: data.precipData[0],
            name: 'Weekly Precipitation',
            type: 'spline',
            yAxis: 1,
            marker: {
              symbol: 'circle'
            },
            color: 'rgb(18, 161, 56)'
          });
        } else {
          opts.yAxis[0].gridLineWidth = 0;
          opts.series[0].color = 'rgb(124,181,236)';
          
          // opts.series[0].dataLabels= [{
          //   enabled: true,
          //   shadow: true,
          //   shape: 'circle',
          //   backgroundColor: 'rgb(150,150,150)',
          //   color: 'white',
          //   padding: 7,
          //   crop: false,
          //   overflow: 'allow',
          //   verticalAlign: 'bottom',
          //   y: -10,
          //   format: key === 'graph3' ? '{y} MGD' : '{y} MG'
          // }];

          opts.tooltip = {
            shared: true,
            useHTML: true,
            formatter: function() {
              return this.points.reduce((acc, point) => {
                let name = point.series.name;
                let units = (name === 'Average Daily Precipitation' || name === 'Total Precipitation') ? ' inches' : (key === 'graph3' ? ' MGD' : ' MG');
                acc += `<div style="display: flex; justify-content: space-between;"><div>${point.series.name}:&nbsp;&nbsp;</div><div style="color: ${point.color};"><b>${Highcharts.numberFormat(point.y, 2)}</b>${units}</div></div>`;
                return acc;
              }, `<b>${this.x}</b>`);
            }
          };
        }

        return <div key={uuidv4()} className={classes.graph}><HighchartsReact highcharts={Highcharts} options={opts} /></div>;
      });
    } else {
      return '';
    }
  }, [data]);

  // const renderSupplyGraph = useMemo(() => {
  //   if (data && data.type === 'surface') {
  //     let title, series, yLabel, tooltip, type;
      
  //     if (supplyGraph === 'avgDP') {
  //       type = 'line';
  //       title = 'Monthly Averages of Average Daily Production';
  //       series = [
  //         { data: data.graph4.avgDP, name: 'Average Daily Production' },
  //       ];
  //       yLabel = 'Millions of Gallons per Day (MGD)';
  //       tooltip = {};
  //     } else if (supplyGraph === 'totalWithdrawn') {
  //       type = 'line';
  //       title = 'Monthly Averages of Total Withdrawn from All Reservoirs';
  //       series = [
  //         { data: data.graph4.totalWithdrawn, name: 'Total Withdrawn' }
  //       ];
  //       yLabel = 'Millions of Gallons';
  //       tooltip = {};
  //     }

  //     let opts =  {
  //       ...options,
  //       chart: {
  //         ...options.chart,
  //         type
  //       },
  //       title: {
  //         ...options.title,
  //         text: title
  //       },
  //       series,
  //       yAxis: {
  //         ...options.yAxis,
  //         title: {
  //           text: yLabel
  //         }
  //       },
  //       tooltip
  //     };

  //     return <div key={uuidv4()} className={classes.graph} style={{paddingTop: 13}}>
  //       <div className={classes.selectCont}>
  //         <TextField
  //           select
  //           size='small'
  //           label='Supply Graph Data'
  //           value={supplyGraph}
  //           onChange={(e) => setSupplyGraph(e.target.value)}
  //           sx={{ width: 250 }}
  //         >
  //           <MenuItem value={'avgDP'}>Average Daily Production</MenuItem>
  //           <MenuItem value={'totalWithdrawn'}>Total Withdrawn</MenuItem>
  //         </TextField>
  //       </div>

  //       <HighchartsReact highcharts={Highcharts} options={opts} />
  //     </div>;
  //   } else {
  //     return '';
  //   }
  // }, [data, supplyGraph]);

  // const renderSWFGraph = useMemo(() => {
  //   if (data && data.type === 'surface' && Object.keys(data.graph5).includes(swf)) {
  //     let title, series, yLabel, tooltip, type;
      
  //     if (swfGraph === 'storage') {
  //       type = 'line';
  //       title = `Monthly Averages of Total Usable Storage and Instantaneous Usable Storage for ${swf}`;
  //       series = [
  //         { data: data.graph5[swf].ius, name: 'Instantaneous Usable Storage', type: 'area', fillColor: 'rgba(124, 181, 236, 0.3)' },
  //         { data: data.graph5[swf].tus, name: 'Total Usable Storage' },
  //       ];
  //       yLabel = 'Millions of Gallons (MG)';
  //       tooltip = {
  //         shared: true
  //       };
  //     } else if (swfGraph === 'waterElev') {
  //       type = 'line';
  //       title = 'Monthly Averages of Water Elevation';
  //       series = [
  //         { data: data.graph5[swf].waterElev, name: 'Water Elevation' }
  //       ];
  //       yLabel = 'Elevation (feet)';
  //       tooltip = {};
  //     } else if (swfGraph === 'percentFull') {
  //       type = 'line';
  //       title = 'Monthly Averages of Percent Full';
  //       series = [
  //         { data: data.graph5[swf].percentFull, name: 'Percent Full' }
  //       ];
  //       yLabel = 'Percent';
  //       tooltip = {};
  //     }

  //     let opts =  {
  //       ...options,
  //       chart: {
  //         ...options.chart,
  //         type
  //       },
  //       title: {
  //         ...options.title,
  //         text: title
  //       },
  //       series,
  //       yAxis: {
  //         ...options.yAxis,
  //         title: {
  //           text: yLabel
  //         }
  //       },
  //       tooltip
  //     };

  //     return <div key={uuidv4()} className={classes.graph} style={{paddingTop: 13}}>
  //       <div className={classes.selectCont}>
  //         <TextField
  //           sx={{width: 245}}
  //           select
  //           size='small'
  //           label='Source Water Facility'
  //           value={swf}
  //           onChange={(e) => setSwf(e.target.value)}
  //         >
  //           { Object.keys(data.graph5).map(id => <MenuItem key={uuidv4()} value={id}>{id}</MenuItem>) }
  //         </TextField>
  //         <TextField
  //           sx={{width: 245}}
  //           select
  //           size='small'
  //           label='SWF Graph'
  //           value={swfGraph}
  //           onChange={(e) => setSWFGraph(e.target.value)}
  //         >
  //           <MenuItem value={'storage'}>Usable Storage</MenuItem>
  //           <MenuItem value={'waterElev'}>Water Elevation</MenuItem>
  //           <MenuItem value={'percentFull'}>Percent Full</MenuItem>
  //         </TextField>
  //       </div>

  //       <HighchartsReact highcharts={Highcharts} options={opts} />
  //     </div>;
  //   } else {
  //     return '';
  //   }
  // }, [data, swfGraph, swf]);


  return (
    <Section
      width='96%'
      padding='0px'
      marginTop='0px'
      titleText={
        <div className={classes.title}>
          <Typography variant='h2' style={{color: 'white', paddingTop: '6px'}}>6 Month Summaries for</Typography>
          {/* <Typography variant='h1' style={{color: 'white'}}>12 Month Summaries for</Typography> */}
          <TextField
            className={classes.siteSelector}
            select
            size='small'
            value={site}
            onChange={(e) => setSite(e.target.value)}
          >
            { siteList.map(s => <MenuItem key={uuidv4()} value={s}>{s[0]}</MenuItem>) }
          </TextField>
        </div>
      }>
      <div className={classes.graphsCont}>
        {data && data.type === 'ground' ?
          <>
            {renderGroundGraphs}
            {/* {renderDeltaGraph} */}
          </>
          :
          <>
            {renderSurfaceGraphs}
            {/* {renderSupplyGraph} */}
            {/* {renderSWFGraph} */}
          </>
        }
      </div>
    </Section>
  );
}

Graphs.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  site: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  setSite: PropTypes.func,
  siteList: PropTypes.array
};