import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import Chart from './Chart';

const bStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  textAlign: 'center',
};

export default function LabelledChart({ data }) {
  return (
    <Box
      key={data[0]}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: 'fit-content',
      }}
    >
      <Typography variant='chartTitle' align='center'>
        {data[0]}
      </Typography>

      <Box>
        <Chart data={data[1]} />

        <Box
          sx={{
            width: '80%',
            margin: '10px auto 0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={bStyle}>
            <Typography color='rgb(0,0,180)'>{data[1].curr}%</Typography>
            <Typography sx={{ fontSize: '12px' }}>Current</Typography>
          </Box>
          <Box
            sx={{ backgroundColor: 'black', height: '40px', width: '1px' }}
          ></Box>
          <Box sx={bStyle}>
            <Typography color='rgb(180,0,0)'>{data[1].hist}%</Typography>
            <Typography sx={{ fontSize: '12px' }}>Historic Average</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

LabelledChart.propTypes = {
  data: PropTypes.array,
};
