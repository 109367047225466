import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormGroup,
  Typography,
  TextField,
  Autocomplete
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  generatePassword,
  customFetch,
  updateObject
} from '../../../Helpers/index';

import SiteSelector from './SiteSelector';
import SubmitBtn from '../../SubmitBtn';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  names: {
    justifyContent: 'space-between',
    width: '95%',
  },
  name: {
    width: '49%'
  },
  email: {
    width: '95%',
  },
  siteSelectorGrid: {
    padding: 18,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    gap: 16,
    justifyContent: 'space-evenly'
  },
  selectCompany: {
    textAlign: 'center',
    fontStyle: 'italic'
  },
  gap: {
    gap: 30,
    alignItems: 'center'
  }
}));



// Form allowing admin accounts to create new users
export default function NewUser({ domain, setShowResults, companies, relations, setData }) {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [readSite, setReadSite] = useState({});
  const [writeSite, setWriteSite] = useState({});
  const [first, setFirst] = useState({ error: false, errorMessage: null});
  const [last, setLast] = useState({ error: false, errorMessage: null});
  const [email, setEmail] = useState({ error: false, errorMessage: null});
  const [userInfo, setUserInfo] = useState({
    email: '',
    user_metadata: {
      first: '',
      last: ''
    }
  });
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();


  // Updates permissions when companies are added or removed from user
  useEffect(() => {
    let newRead = {};
    let newWrite = {};
    selectedCompanies.forEach(c => {
      if (Object.keys(readSite).includes(c[0])) {
        newRead[c[0]] = [...readSite[c[0]]];
        newWrite[c[0]] = [...writeSite[c[0]]];
      } else {
        newRead[c[0]] = relations[c[1]].map(site => site[1]);
        newWrite[c[0]] = [];
      }
    });

    setReadSite(newRead);
    setWriteSite(newWrite);
  }, [selectedCompanies]);


  // API request function
  const requestCreateUser = async (userInfo, rwc, password) => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/create`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user: {
          ...userInfo,
          password
        },
        rwc,
        type: 'user'
      })
    });
  };
  

  // Checks that form is complete, submits request, and shows results in popup
  const handleSubmit = async () => {
    setLoading(true);
    
    const nameRegex = /^[a-zA-Z]+$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    let errors = [true, true, true];

    // Validate first name
    if (userInfo.user_metadata.first === '') {
      setFirst({ error: true, errorMessage: 'First name is required.'});
    } else if (userInfo.user_metadata.first.length <= 2) {
      setFirst({ error: true, errorMessage: 'First name must be at least 3 letters.'});
    } else if (!nameRegex.test(userInfo.user_metadata.first)) {
      setFirst({ error: true, errorMessage: 'First name can only contain letters.'});
    } else {
      setFirst({ error: false, errorMessage: null});
      errors[0] = false;
    }

    // Validate last name
    if (userInfo.user_metadata.last === '') {
      setLast({ error: true, errorMessage: 'Last name is required.'});
    } else if (userInfo.user_metadata.last.length <= 1) {
      setLast({ error: true, errorMessage: 'Last name must be at least 2 letters.'});
    } else if (!nameRegex.test(userInfo.user_metadata.last)) {
      setLast({ error: true, errorMessage: 'Last name can only contain letters.'});
    } else {
      setLast({ error: false, errorMessage: null});
      errors[1] = false;
    }

    // Validate email
    if (userInfo.email === '') {
      setEmail({ error: true, errorMessage: 'Email is required.'});
    } else if (!emailRegex.test(userInfo.email)) {
      setEmail({ error: true, errorMessage: 'Email is invalid.'});
    } else {
      setEmail({ error: false, errorMessage: null});
      errors[2] = false;
    }


    // If all form fields are valid create the user
    if (!errors.includes(true)) {
      // Transform state data to KV format data
      const rwc = Object.keys(readSite).reduce((acc,compName) => {
        readSite[compName].forEach(site => {
          if (!acc.read.includes(site)) {
            acc.read.push(site);
          }
        });

        writeSite[compName].forEach(site => {
          if (!acc.write.includes(site)) {
            acc.write.push(site);
          }
        });

        acc.companies.push(companies.find(c => c[0] === compName)[1]);

        return acc;
      }, {read: [], write: [], companies: []});
  
      let results = await requestCreateUser(userInfo, rwc, generatePassword());

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestCreateUser(userInfo, rwc, generatePassword());
      }

      // On success, clear form
      if (results.code === 200) {
        setData(prev => {
          return {
            ...prev,
            userList: [...prev.userList, userInfo.email].sort()
          };
        });
        setSelectedCompanies([]);
        setReadSite({});
        setWriteSite({});
        setFirst({ error: false, errorMessage: null});
        setLast({ error: false, errorMessage: null});
        setEmail({ error: false, errorMessage: null});
        setUserInfo({
          email: '',
          user_metadata: {
            first: '',
            last: ''
          }
        });
      }

      setShowResults(results);
    }

    setLoading(false);
  };


  const handleChange = (val, path) => {
    let newInfoObj = { ...userInfo };
    updateObject(newInfoObj, path, val);
    setUserInfo(newInfoObj);
  };


  return (
    <>
      <FormGroup className={classes.container}>
        <Typography variant='sectionTitle' sx={{marginTop: 0}}>User Information</Typography>
        
        <FormGroup className={classes.gap}>
          <FormGroup row={true} className={classes.names}>
            <TextField
              className={classes.name}
              size='small'
              variant='outlined'
              label='First Name'
              value={userInfo.user_metadata.first}
              onChange={(e) => handleChange(e.target.value, 'user_metadata.first')}
              error={first.error}
              helperText={first.errorMessage && first.errorMessage}
            />
            <TextField
              className={classes.name}
              size='small'
              variant='outlined'
              label='Last Name'
              value={userInfo.user_metadata.last}
              onChange={(e) => handleChange(e.target.value, 'user_metadata.last')}
              error={last.error}
              helperText={last.errorMessage && last.errorMessage}
            />
          </FormGroup>
          
          <TextField
            className={classes.email}
            size='small'
            variant='outlined'
            label='Email Address'
            value={userInfo.email}
            onChange={(e) => handleChange(e.target.value, 'email')}
            error={email.error}
            helperText={email.errorMessage && email.errorMessage}
          />
        </FormGroup>
      </FormGroup>
        
      <FormGroup>
        <Typography variant='sectionTitle'>Accessible Water Systems</Typography>
        <Autocomplete
          multiple
          fullWidth
          size='small'
          options={companies}
          value={selectedCompanies}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Water Systems'
            />
          )}
          sx={{width: '95%', margin: '0 auto'}}
          onChange={(event, newValue) => setSelectedCompanies(newValue)}
          getOptionLabel={option => option[0]}
        />
      </FormGroup>

      <FormGroup>
        <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Accessible Wells and Treatment Plants</Typography>
        {(!selectedCompanies || selectedCompanies.length === 0) && <div className={classes.selectCompany}>No water systems selected.</div>}
        <div className={classes.siteSelectorGrid}>
          {
            selectedCompanies.map(company =>
              <SiteSelector
                key={company[1]}
                companyName={company[0]}
                relations={relations[company[1]]}
                readSite={readSite}
                setReadSite={setReadSite}
                writeSite={writeSite}
                setWriteSite={setWriteSite}
              />
            )
          }
        </div>
      </FormGroup>

      <SubmitBtn btnText={loading ? <CircularProgress size={20} /> : 'Create User'} submitFunc={handleSubmit} />
    </>
  );
}

NewUser.propTypes = {
  domain: PropTypes.string,
  setShowResults: PropTypes.func,
  companies: PropTypes.array,
  relations: PropTypes.object,
  setData: PropTypes.func
};