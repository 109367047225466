import { formatISO, parseISO, addDays, subDays, isFuture } from 'date-fns';

export default function getMaxDate(beginDate) {
  let max = addDays(parseISO(beginDate), 6);

  while (isFuture(max)) {
    max = subDays(max, 1);
  }

  return formatISO(max, {representation: 'date'});
}