import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';



export default function SubmitBtn({ btnText, submitFunc, sx }) {
  return (
    <div style={{textAlign: 'center', ...sx}}>
      <Button
        onClick={submitFunc}
        sx={{
          color: 'white',
          fontWeight: 'bold',
          backgroundColor: 'rgb(0,200,0)',
          '&:hover': {
            backgroundColor: 'rgb(0,170,0)'
          }
        }}
      >
        {btnText}
      </Button>
    </div>
  );
}

SubmitBtn.propTypes = {
  btnText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  submitFunc: PropTypes.func,
  sx: PropTypes.object
};