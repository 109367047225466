import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormGroup,
  Typography,
  TextField,
  Autocomplete,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { customFetch } from '../../../Helpers/index';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginBottom: 30,
  },
  input: {
    margin: '0 auto !important',
    width: '95%'
  },
  btnGroup: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  submit: {
    width: 200,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(5, 66, 102) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(1,24,35) !important',
    }
  },
  delete: {
    width: 200,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(219, 107, 113) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(189, 77, 83) !important',
    }
  },
}));



// Form allowing admin accounts to edit existing companies
export default function EditCompany({ domain, setShowResults, allRelations, siteList, companyData, setCompanyData, companyName, handleChangeSelected, setData }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  

  // API request function, handles updates
  const requestEdit = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        type: 'company',
        method: 'update',
        alias: companyName[1],
        data: companyData
      })
    });
  };


  // API request function, handles deleting
  const requestDelete = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        type: 'company',
        method: 'delete',
        alias: companyName[1]
      })
    });
  };


  // Checks that form is complete, submits request, and shows results in popup
  const handleSubmit = async () => {
    setLoading('update');

    let newErrors = companyData.pwsID === '' ? 'PWS ID is required' : false;

    if (!newErrors) {
      let results = await requestEdit();
      
      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestEdit();
      } 
      
      if (results.code === 200) {
        setData(prev => { return { ...prev, relations: { ...prev.relations, [companyName[1]]: siteList.filter(s => companyData.sites.includes(s[1])) } }; });
      }
      
      setShowResults(results);
    } else {
      setErrors(newErrors);
    }

    setLoading(false);
  };


  // Verify that admin wants to delete, then does it
  const handleDelete = async () => {
    setLoading('delete');
    
    if (confirm(`Are you sure that you want to delete ${companyName[0]}`)) {
      let results = await requestDelete();

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestDelete();
      }
      
      if (results.code === 200) {
        setCompanyData(false);
        handleChangeSelected({ 'company': '' });

        let rels = { ...allRelations };
        delete rels[companyName[1]];
        setData(prev => { return { ...prev, relations: rels, companies: prev.companies.filter(c => c[0] !== companyName[0]) }; });
      }

      setShowResults(results);
    }
    
    setLoading(false);
  };


  return (
    <>
      <FormGroup className={classes.container}>
        <Typography variant='sectionTitle'>Water System Basic Information</Typography>
        <TextField
          className={classes.input}
          size='small'
          variant='outlined'
          label='PWS ID'
          value={companyData.meta.pwsID}
          onChange={(e) => setCompanyData(prev => { return { ...prev, meta: { ...prev.meta, pwsID: e.target.value } }; })}
          error={errors.pwsID}
          helperText={errors.pwsID}
          InputLabelProps={{shrink: true}}
        />

        <Typography variant='sectionTitle'>Accessible Wells and Treatment Plants</Typography>
        <Autocomplete
          className={classes.input}
          multiple
          size='small'
          options={siteList}
          value={siteList.filter(s => companyData.sites.includes(s[1]))}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Wells and Treatment Plants'
            />
          )}
          onChange={(event, newValue) => setCompanyData(prev => { 
            return { ...prev, sites: newValue.map(val => val[1]) };
          })}
          getOptionLabel={opt => opt[0]}
          isOptionEqualToValue={(option, value) => (option[0] === value[0] && option[1] === value[1])}
          renderOption={(props, option) => {
            return <li {...props} key={option[1]} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>{option[0]}</div>
              <div style={{fontStyle: 'italic', color: 'rgb(150,150,150)'}}>{option[2]}</div>
            </li>;
          }}
        />
      </FormGroup>

      <div className={classes.btnGroup}>
        <Button className={classes.submit} onClick={handleSubmit} >{loading === 'update' ? <CircularProgress size={20} color='update' /> : 'Update Company'}</Button>
        <Button className={classes.delete} onClick={handleDelete} >{loading === 'delete' ? <CircularProgress size={20} color='delete' /> : 'Delete Company'}</Button>
      </div>
    </>
  );
}

EditCompany.propTypes = {
  domain: PropTypes.string,
  setShowResults: PropTypes.func,
  allRelations: PropTypes.object,
  companyData: PropTypes.object,
  siteList: PropTypes.array,
  setCompanyData: PropTypes.func,
  companyName: PropTypes.array,
  handleChangeSelected: PropTypes.func,
  setData: PropTypes.func,
};