import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import App from './App';
import { Auth0ProviderWithHistory } from './Components/index';
import './index.css';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 410,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  'palette': {
    'primary': {
      'main': '#1976d2',
      'light': '#42a5f5',
      'dark': '#1565c0',
      'contrastText': '#fff'
    },
    'secondary': {
      'main': '#9c27b0',
      'light': '#ba68c8',
      'dark': '#7b1fa2',
      'contrastText': '#fff'
    },
    'reset': 'rgb(0,0,0)',
    'update': 'rgb(0,0,100)',
    'delete': 'rgb(0,0,0)'
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    h1: {
      fontSize: '2rem',
      fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    },
    h3: {
      fontSize: '2rem',
      fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    },
    h4: {
      fontSize: '1.7rem',
      fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    },
    subtitle1: {
      fontSize: '1.4rem',
      fontStyle: 'italic',
      fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    },
    subtitle2: {
      fontSize: '0.8rem',
    },
    popupSubheading: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '18px',
      marginBottom: '12px'
    },
    popupBody: {
      fontSize: '15px',
      display: 'block',
      marginTop: '6px',
      marginBottom: '6px',
      maxWidth: '350px',
      paddingLeft: '14px',
      textIndent: '15px'
    },
    popupLink: {
      fontSize: '15px',
      display: 'block',
      marginTop: '8px',
      marginBottom: '8px',
      color: '#42a5f5',
      textAlign: 'center'
    },
    sectionTitle: {
      color: 'rgb(25,50,181) !important',
      marginTop: '20px',
      marginBottom: '20px'
    },
    p: {
      textIndent: '15px',
      lineHeight: '23px'
    },
    legend: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    chartTitle: {
      fontWeight: 'bold',
      fontSize: '14px'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          fieldset: {
            borderWidth: '4px',
            top: '-4px',
          },
          '&:hover fieldset': {
            borderColor: 'rgb(79,88,93) !important'
          },
          '&.Mui-focused fieldset': {
            borderWidth: '4px !important',
            borderColor: 'rgb(79,88,93) !important'
          },
          '&:hover.Mui-disabled fieldset': {
            borderColor: 'rgba(0,0,0,0.26) !important'
          },
          '&.Mui-disabled fieldset': {
            borderColor: 'rgba(0,0,0,0.26) !important'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          backgroundColor: 'white',
          paddingLeft: 7,
          paddingRight: 7,
          borderRadius: 4,
          left: -2,
          top: -5,
          height: 24,
          border: '1px solid rgb(242,208,59)',
          color: 'rgb(110,110,110)',
          '&.Mui-focused': {
            color: 'rgb(110,110,110)'
          },
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    }
  }
});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);