import React from 'react';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  footer: {
    width: '100%',
    height: 40,
    boxSizing: 'border-box',
    backgroundColor: 'rgb(200,200,200)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    textAlign: 'center',
    width: 'fit-content',
    height: 'fit-content',
  }
}));



export default function Footer() {
  const classes = useStyles();
  
  return (
    <footer className={classes.footer}>
      <div className={classes.text}>Footer Content Coming Soon...</div>
    </footer>
  );
}