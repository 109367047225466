import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import { makeStyles } from '@mui/styles';
import {
  Tab, 
  Tabs
} from '@mui/material';

import { customFetch } from '../../../Helpers';

import ResultPopup from '../../ResultPopup';
import TabPanel from '../../TabPanel';
import ListOptions from './ListOptions';
import EditUser from './EditUser';
import EditCompany from './EditCompany';
import EditSite from './EditSite';


const useStyles = makeStyles(() => ({
  formCont: {
    minWidth: 360,
    width: '94%',
    maxWidth: 600,
    margin: '0 auto',
  },
  tabsCont: {
    backgroundColor: 'rgb(84,141,212)',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  tabs: {
    backgroundColor: 'rgb(84,141,212)',
    minHeight: '40px !important'
  },
  tab: {
    color: 'white !important',
    padding: '4px 8px !important',
    minHeight: '40px !important',
    '&:hover': {
      backgroundColor: 'rgb(54,111,182)'
    }
  },
  divider: {
    position: 'absolute',
    height: 40,
    width: 1,
    backgroundColor: 'rgba(210,210,210, 0.5)',
  }
}));



// Container component for admin accounts to edit existing users, companies, and sites
export default function Edit({ domain, companies, relations, userList, setData, siteList }) {
  const [tab, setTab] = useState(0);
  const [showResults, setShowResults] = useState({ code: null, msg: null });
  const [showMsg, setShowMsg] = useState(false);
  const [selected, setSelected] = useState({ user: null, company: null, site: null });
  const [selUserData, setSelUserData] = useState(false);
  const [selCompanyData, setSelCompanyData] = useState(false);
  const [selSiteData, setSelSiteData] = useState(false);

  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();


  // Handles showing and hiding the popup
  useEffect(() => {
    if (showResults.code !== null && showResults.msg !== null) {
      setShowMsg(true);

      const timeId = setTimeout(() => {
        setShowMsg(false);
      }, 4000);
  
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showResults]);


  // Handles clearing data in case of deleting a user
  useEffect(() => {
    setSelected({ user: null, company: null, site: null });
    setSelUserData(false);
  }, [userList]);


  const handleTabChange = (e, val) => {
    setTab(val);
  };


  // Handles getting existing data for whatever is selected
  const handleChangeSelected = async (obj) => {
    if (Object.keys(obj).includes('user')) {
      setSelUserData(false);

      if (obj.user !== null) {
        (async() => {
          let accessToken = await getAccessTokenSilently();
          let data = await customFetch(`//${domain}/getData`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ type: 'user', name: obj.user })
          });
  
          if (data.code === 419) {
            accessToken = await getAccessTokenSilently();
            data = await customFetch(`//${domain}/getData`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ type: 'user', name: obj.user })
            });
          }
    
          setSelUserData(data.res);
        })();
      }
    } else if (Object.keys(obj).includes('company')) {
      if (obj.company) {
        (async () => {
          const accessToken = await getAccessTokenSilently();
        
          let results = await customFetch(`//${domain}/getData`,{
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ type: 'company', id: obj.company[1] })
          });
  
          setSelCompanyData(results.res);
        })();
      } else {
        setSelCompanyData(false);
      }
    } else if (Object.keys(obj).includes('site')) {
      if (obj.site) {
        (async () => {
          const accessToken = await getAccessTokenSilently();
        
          let results = await customFetch(`//${domain}/getData`,{
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ type: 'meta', id: 'c.' + obj.site[1] })
          });
  
          setSelSiteData(results.res);
        })();
      }
    }

    setSelected({ ...selected, ...obj });
  };


  return (
    <div>
      <div className={classes.formCont}>
        <div className={classes.tabsCont}>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: {
              backgroundColor: 'rgb(242,208,59)'
            } }}
          >
            <Tab className={classes.tab} label="User" />
            <Tab className={classes.tab} label="Water System" />
            <Tab className={classes.tab} label="Site" />
          </Tabs>
          <div className={classes.divider} style={{left: '90px'}}></div>
          <div className={classes.divider} style={{left: '207px'}}></div>
        </div>
        <TabPanel value={tab} index={0}>
          <ListOptions
            selected={selected.user}
            options={userList}
            type='user'
            handleChangeSelected={handleChangeSelected}
          />

          {selected.user !== null && selUserData && 
            <EditUser
              userName={selected.user}
              userInfo={selUserData}
              domain={domain}
              setShowResults={setShowResults}
              allRelations={relations}
              setData={setData}
              allComps={companies}
            />
          }
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ListOptions
            selected={selected.company}
            options={companies}
            type='company'
            handleChangeSelected={handleChangeSelected}
          />

          {selected.company !== null && selCompanyData &&
            <EditCompany
              domain={domain}
              setShowResults={setShowResults}
              allRelations={relations}
              siteList={siteList}
              companyData={selCompanyData}
              setCompanyData={setSelCompanyData}
              companyName={selected.company}
              handleChangeSelected={handleChangeSelected}
              setData={setData}
            />
          }
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ListOptions
            selected={selected.site}
            options={siteList}
            type='site'
            handleChangeSelected={handleChangeSelected}
          />

          {selected.site !== null && selSiteData &&
            <EditSite
              domain={domain}
              setShowResults={setShowResults}
              allRelations={relations}
              setData={setData}
              siteData={selSiteData}
              setSiteData={setSelSiteData}
              siteName={selected.site}
            />
          }
        </TabPanel>
      </div>

      {
        showMsg && <ResultPopup
          code={showResults.code}
          msg={showResults.res}
          type={tab === 0 ? 'user' : tab === 1 ? 'company' : 'site'}
          action='edited'
        />
      }
    </div>
  );
}

Edit.propTypes = {
  domain: PropTypes.string,
  companies: PropTypes.array,
  siteList: PropTypes.array,
  userList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ]),
  relations: PropTypes.object,
  setData: PropTypes.func
};