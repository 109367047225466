/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  ListItemIcon,
  CircularProgress,
  Link
} from '@mui/material';
import { styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import XLSX from 'xlsx';

import Section from './Section';
import { customFetch } from '../Helpers';
import IconPopper from './IconPopper';

const SpecialLi = styled(ListItem)({
  fontSize: '12px',
  color: 'rgb(150,150,150)',
  fontStyle: 'italic',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0 auto'
});

const requestSubmit = async (domain, accessToken, data) => {
  return await customFetch(`//${domain}/saveData`,{
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data)
  });
};

const downloadAsCsv = (dataArr, fileName) => {
  var dataContent = '';
  dataArr.forEach(function(infoArray, index) {
    let dataString = infoArray.join(',');
    dataContent += index < dataArr.length ? dataString + '\n' : dataString;
  });
  
  var a = document.createElement('a');
  const mimeType = 'text/csv;encoding:utf-8;';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([dataContent], {
      type: mimeType
    }), fileName);
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(new Blob([dataContent], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(dataContent);
  }
};



export default function UploadData({ domain }) {
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusArray, setStatusArray] = useState([]);

  const { getAccessTokenSilently, user } = useAuth0();

  const handleUpload = async () => {
    setLoading(true);
    setStatusArray(prev => prev.map(() => 'loading'));
    
    const accessToken = await getAccessTokenSilently();
    
    await Promise.all(files.map(async (file, i) => {
      let data = {
        type: 'csv',
        user: {
          name: user.name,
          userAlias: user.sub,
        },
        fileName: file.name
      };

      const reader = new FileReader();
      reader.onload = async (e) => {
        const workbook = XLSX.read(e.target.result);
        const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);

        
        let results = await requestSubmit(domain, accessToken, { ...data, data: csvData});
        
        // If token expired during call, try again, this should auto refresh the token
        if (results.code === 419) {
          results = await requestSubmit(domain, accessToken, { ...data, data: csvData});
        }
        
        setStatusArray(prev => {
          let newPrev = [ ...prev ];

          if (!results.res.saveResults) {
            newPrev[i] = 'failed';
          } else if (results.res.saveResults.every(res => res[2] === false) && results.res.dataErrors.length === 0) {
            newPrev[i] = true;
          } else {
            newPrev[i] = {
              saveResults: results.res.saveResults,
              dataErrors: results.res.dataErrors
            };
          }

          return newPrev;
        });
      };

      reader.readAsArrayBuffer(file);
    }));

    setLoading(false);
  };

  const removeFile = (index) => {
    if (!loading) {
      let tempFile = [ ...files ];
      tempFile.splice(index, 1);
      setFiles(tempFile);

      let tempStat = [ ...statusArray ];
      tempStat.splice(index, 1);
      setStatusArray(tempStat);
    }
  };

  const humanReadableSize = (size) => {
    let sOutput = size + ' bytes';
    const aMultiples = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    for (let nMultiple = 0, nApprox = size / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
      sOutput = nApprox.toFixed(3) + ' ' + aMultiples[nMultiple];
    }

    return sOutput;
  };

  const transformProblems = (data) => {
    let csv = [['Errors in data', '', '', '', 'Rejected Date', 'Reason']];
    
    if (data.dataErrors.length > data.saveResults.length) {
      data.dataErrors.forEach((err, i) => {
        let sr = data.saveResults[i] || false;
        
        err = `"${err}"`;
        if (sr) {
          csv.push([err, '', '', '', sr[1], sr[2] === false ? 'Saved successfully' : sr[2]]);
        } else {
          csv.push([err, '', '', '', '', '']);
        }
      });
    } else {
      data.saveResults.forEach((arr, i) => {
        let de = `"${data.dataErrors[i] || ''}"`;

        let reason = arr[2] === false ? 'Saved successfully' : arr[2];
        csv.push([de, '', '', '', arr[1], reason]);
      });
    }

    return csv;
  };

  const handleClick = (e, index, filename) => {
    stubEvent(e);
    let data = statusArray[index];
    if (typeof data === 'object') {
      downloadAsCsv(transformProblems(data), `${filename.slice(0, filename.length - 4)}-problems.csv`);
    }
  };

  const renderFileNames = (fileList) => {
    let sizeSum = 0;
    let res = fileList.map((file, i) => {
      sizeSum += file.size;

      let fileName = file.name;
      if (fileName.length > 24) {
        fileName = fileName.slice(0,21) + '...';
      }

      let icon = '';
      let popContent = '';
      let status = statusArray[i];
      
      if (typeof status === 'object') {
        icon = <div style={{height: 24, width: 24, borderRadius: '50%', backgroundColor: 'rgb(255,234,75)'}}>
          <PriorityHighIcon sx={{ color: 'black' }} />
        </div>;
        popContent = 'Not all of this file was able to be uploaded. Click on the icon to download a report about the results of this upload attempt.';
      } else if (status === 'failed') {
        icon = <CloseIcon sx={{color: 'rgb(255,75,75)' }} />;
        popContent = 'No valid data was found in this file or you do not have permissions to add data for any of the provided sites.';
      } else if (status === 'loading') {
        icon = <CircularProgress size={25} sx={{color: 'rgb(150,150,150)'}} />;
        popContent = 'Upload in progress...';
      } else if (status) {
        icon = <CheckIcon sx={{color: 'rgb(0,175,0)' }} />;
        popContent = 'File was successfully uploaded.';
      }

      return (
        <Fragment key={i}>
          {i !== 0 && <Divider />}

          <ListItem sx={{paddingLeft: 0, paddingRight: 0}} >
            <ListItemButton onClick={() => removeFile(i)}>
              <ListItemText>{fileName}</ListItemText>
              <div style={{display: 'flex', gap: 20}}>
                <ListItemText sx={{width: 100, textAlign: 'right'}}>{humanReadableSize(file.size)}</ListItemText>
                <ListItemIcon
                  sx={{
                    minWidth: 25,
                    minHeight: 25,
                    width: 25,
                    height: 25,
                    marginLeft: '5px',
                    marginRight: '5px',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    top: status === 'loading' ? 0 : 4
                  }}
                  onClick={(e) => handleClick(e, i, file.name)}
                >
                  {popContent === '' ? '' :
                    <IconPopper icon={icon} size='25px' padding='0px'>
                      <Typography>{popContent}</Typography>
                    </IconPopper>
                  }
                </ListItemIcon>
              </div>
            </ListItemButton>
          </ListItem>
        </Fragment>
      );
    });

    sizeSum = humanReadableSize(sizeSum);
    if (sizeSum !== totalSize) {
      setTotalSize(sizeSum);
    }

    return res;
  };

  const stubEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleAddFiles = (e) => {
    if (!loading) {
      const extRegex = /.csv$|.xls$|.xlsx$/;
      
      let fileList;
      if (e instanceof FileList) {
        fileList = e;
      } else {
        e.stopPropagation();
        e.preventDefault();
    
        fileList = e.dataTransfer.files;
      }

      let newFiles = [ ...files ];
      for (let i = 0; i < fileList.length; i++) {
        if (extRegex.test(fileList[i].name) && !(newFiles.some(f => f.name === fileList[i].name))) {
          newFiles.push(fileList[i]);
        }
      }
  
      document.getElementById('file-selector').value = '';
      setFiles(newFiles);
      setStatusArray([ ...statusArray, false]);
    }
  };


  return (
    <div style={{width: 600, margin: '0 auto'}}>
      <Section
        width='100%'
        marginTop='0px'
        titleText={
          <div style={{display: 'flex'}}>
            <Typography variant='h2' sx={{color: 'white'}}>Upload Data</Typography>
            <IconPopper icon={<QuestionMarkIcon sx={{color: 'white', fontSize: '14px !important'}} />} vertAdjust='8px'>
              <div>
                <Typography variant='popupSubheading'>Helpful Resources for Uploading files:</Typography>
    
                <ul style={{marginTop: '20px'}}>
                  <li><Link variant='popupLink' href={`${process.env.PUBLIC_URL}/Assets/csv-upload-documentation.docx`}>Documentation</Link></li>
                  <li><Link variant='popupLink' href={`${process.env.PUBLIC_URL}/Assets/surface-water-sample-upload.csv`}>Sample Surface Water Upload File</Link></li>
                  <li><Link variant='popupLink' href={`${process.env.PUBLIC_URL}/Assets/well-water-sample-upload.csv`}>Sample Ground Water Upload File</Link></li>
                </ul>
              </div>
            </IconPopper>
          </div>
        }
      >
        <>
          <List
            onDragOver={stubEvent}
            onDragEnter={stubEvent}
            onDrop={handleAddFiles}
            sx={{
              border: '2px solid rgb(200,200,200)',
              backgroundColor: 'white',
              width: 400,
              maxHeight: 400,
              overflowY: 'auto',
              margin: '0 auto',
              padding: '6px 20px'
            }}
    
          >
            <SpecialLi>
              <div>File Name</div>
              <div style={{display: 'flex', gap: 20}}>
                <div>File Size</div>
                <div style={{width: 35}}>Status</div>
              </div>
              {/* <div style={{position: 'relative', left: 35}}>File Size</div> */}
            </SpecialLi>
            <Divider />
    
            {
              files.length === 0 ?
                <ListItem sx={{fontStyle: 'italic', fontWeight: 'bold', justifyContent: 'center'}}>No files selected yet</ListItem>
                :
                renderFileNames(files)
            }
            <Divider />
    
            {/* <SpecialLi sx={{width: 400}}> */}
            <SpecialLi>
              <div>Number of Files: {files.length}</div>
              <div style={{display: 'flex', gap: 20}}>
                <div>Total Size: {totalSize}</div>
                <div style={{width: 35}}></div>
              </div>
            </SpecialLi>
          </List>
          {files.length > 0 && <Typography align='center' sx={{color: 'rgb(80,80,80)'}}>*Click on a file to remove it</Typography>}
          {files.length > 0 && <Typography align='center' sx={{color: 'rgb(80,80,80)'}}>**Hover over Status icons for more information</Typography>}
          <div style={{display: 'flex', justifyContent: 'space-between', width: 300, margin: '20px auto 0px auto'}}>
            <Button
              variant="contained"
              component="label"
              disabled={loading}
            >
              Select Files
              <input
                id='file-selector'
                type="file"
                multiple
                hidden
                accept='.csv, .xls, .xlsx'
                onChange={(e) => handleAddFiles(e.target.files)}
              />
            </Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              disabled={files.length === 0 || loading}
            >
              Upload Data
            </Button>
          </div>
        </>
      </Section>
    </div>
  );
}

UploadData.propTypes = {
  domain: PropTypes.string,
};