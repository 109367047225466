import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const TitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 7,
  backgroundColor: 'rgb(241,241,241)',
  padding: 20,
  width: 'fit-content',
  borderRadius: 15,
  border: '3px solid rgb(242,208,59)'
});

const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 30,
  backgroundColor: 'rgb(241,241,241)',
  padding: '20px 40px',
  maxWidth: 600,
  width: '100%',
  borderRadius: 15,
  border: '3px solid rgb(242,208,59)',
  boxSizing: 'border-box'
});

const ToDataBtn = styled(Button)({
  backgroundColor: 'rgb(84,141,212)',
  color: 'white',
  padding: '6px 50px',
  '&:hover': {
    backgroundColor: 'rgb(64,121,192)'
  }
});



export default function Welcome() {
  return (
    <Box sx={{
      display: 'flex',
      borderTop: '4px solid rgb(242,208,59)',
      borderBottom: '4px solid rgb(242,208,59)',
    }}>
      <Box sx={{
        flexGrow: 1,
        height: 1100,
        backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/welcomeWater.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '70%'
      }}></Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 7,
        width: 500,
        paddingRight: '20px',
        background: 'rgb(30,30,30)'
      }}>
        <TitleBox>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1
          }}>
            <Typography variant='h1' sx={{fontSize: 50}}>Welcome</Typography>
            <Typography variant='h5' sx={{fontSize: 20}}>to the</Typography>
            <Typography variant='h5' sx={{fontSize: 30}}>Connecticut Water Portal</Typography>
          </Box>
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
              border: '2px solid rgb(220,220,220)',
              borderRadius: '50%'
            }}
            alt="Webpage logo"
            src="/logo192.png"
          />
        </TitleBox>
        <ContentBox>
          <Typography variant='p'>The purpose of this portal is to allow for easy transmission of critical water resource metrics between the companies responsible for resource maintainence and Connecticut State Department of Public Health.</Typography>
        </ContentBox>
      
        <ContentBox>
          <Typography variant='p'>In order to use this portal to submit data data for a water resource please contact XXXXXXXXXXXXXXXXXX and an account can be created for you. If you already have an account please click the login button at the top right of this page.</Typography>
        </ContentBox>
      
        <ContentBox>
          <Typography variant='p'>Otherwise, if you are curious about the data that is being collected you are welcome to explore the data through an interactive graph found by clicking on the See the Data button below.</Typography>
          <ToDataBtn component={Link} to='/Data/View'>See the Data</ToDataBtn>
        </ContentBox>
      </Box>
    </Box>
  );
}