import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { 
  Checkbox
} from '@mui/material';

const checkColWidth = 40;
const nameColWidth = 150;


const useStyles = makeStyles((theme) => ({
  container: {
    width: 241,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    height: 'fit-content',
    boxSizing: 'border-box',
    padding: 4,
    backgroundColor: 'white'
  },
  companyName: {
    fontWeight: 700,
    marginTop: 4,
    marginBottom: 16,
    textAlign: 'center'
  },
  li: {
    display: 'flex',
    textAlign: 'center'
  },
  bb: {
    borderBottom: '2px solid black',
    paddingBottom: 4,
    marginBottom: 6
  },
  checkCol: {
    boxSizing: 'border-box',
    width: checkColWidth,
    height: checkColWidth
  },
  siteNameCol: {
    boxSizing: 'border-box',
    width: nameColWidth,
    height: checkColWidth,
    paddingTop: 13
  },
  none: {
    borderTop: '2px solid black',
    textAlign: 'center',
    padding: 12,
  },
  rw: {
    width: checkColWidth,
    textAlign: 'center',
  },
  s: {
    width: nameColWidth,
    textAlign: 'center'
  }
}));



// Component showing a single companies' sites with checkboxes for adding/removing read/write permissions
export default function SiteSelector({ companyName, relations, readSite, setReadSite, writeSite, setWriteSite }) {
  const classes = useStyles();


  // Handles changing the permissions selected
  const handleChange = (checked, readWrite, sName) => {
    let newObj;
    if (readWrite === 'read') {
      newObj = { ...readSite };
    } else {
      newObj = { ...writeSite };
    }
    
    if (checked) {
      if (sName === '*') {
        newObj[companyName] = relations.map(site => site[1]);
      } else {
        newObj[companyName] = [ ...newObj[companyName], sName ];
      }
    } else {
      if (sName === '*') {
        newObj[companyName] = [];
      } else {
        newObj[companyName] = newObj[companyName].filter(site => site !== sName);
      }
    }

    if (readWrite === 'read') {
      setReadSite(newObj);
    } else {
      setWriteSite(newObj);
    }
  };


  // Conditional return
  if (readSite[companyName]) {
    if (relations.length > 0) {
      return (
        <div className={classes.container}>
          <div className={classes.companyName}>{companyName}</div>
    
          <ul>
            <li className={`${classes.li} ${classes.bb}`}>
              <div className={classes.rw}>Read</div>
              <div className={classes.rw}>Write</div>
              <div className={classes.s}>Site</div>
            </li>
    
            {
              relations.length > 1 &&
              <li className={`${classes.li} ${classes.bb}`}>
                <div className={classes.checkCol}><Checkbox checked={relations.length === readSite[companyName].length} onChange={(e) => handleChange(e.target.checked, 'read', '*')} /></div>
                <div className={classes.checkCol}><Checkbox checked={relations.length === writeSite[companyName].length} onChange={(e) => handleChange(e.target.checked, 'write', '*')} /></div>
                <div className={classes.siteNameCol}>Select All</div>
              </li>
            }
    
            {
              relations.sort().map(site => 
                <li key={site[1]} className={classes.li}>
                  <div className={classes.checkCol}><Checkbox checked={readSite[companyName].includes(site[1])} onChange={(e) => handleChange(e.target.checked, 'read', site[1])} /></div>
                  <div className={classes.checkCol}><Checkbox checked={writeSite[companyName].includes(site[1])} onChange={(e) => handleChange(e.target.checked, 'write', site[1])} /></div>
                  <div className={classes.siteNameCol}>{site[0]}</div>
                </li>  
              )
            }
          </ul>
        </div>
      );
    } else {
      return (
        <div className={classes.container}>
          <div className={classes.companyName}>{companyName}</div>
    
          <div className={classes.none}>There are no water supplies available for this company.</div>
        </div>
      );
    }
  } else {
    return '';
  }
}

SiteSelector.propTypes = {
  companyName: PropTypes.string,
  relations: PropTypes.array,
  readSite: PropTypes.object,
  setReadSite: PropTypes.func,
  writeSite: PropTypes.object,
  setWriteSite: PropTypes.func
};