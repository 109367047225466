import React from 'react';
import PropTypes from 'prop-types';

export default function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{
        backgroundColor: 'rgb(241,241,241)',
        padding: 20
      }}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};