/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}
const picOrder = shuffle(Array.from({length: 27}, (v,i) => i + 1));



export default function PictureColumn({ width, maxWidth, height, rows, bar }) {
  let picNums = [ ...picOrder ];
  const imgContStyle = {
    border: '1px solid rgb(79,88,93)',
    width: 'calc(50% - 2.5px)',
    backgroundColor: 'rgb(79,88,93)'
  };

  let imgStyle = {
    height: bar ? height : '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  };

  
  return (
    <div style={{
      width,
      maxWidth: maxWidth || 'calc(100% - 10px)',
      height,
      padding: '5px',
      backgroundColor: 'rgb(79,88,93)',
      margin: '0 auto'
    }}>
      <div style={{
        display: 'flex',
        flexWrap: bar ? 'no-wrap' : 'wrap',
        gap: '5px',
        height: '100%',
        width: '100%'
      }}>
        {Array.from(Array(rows)).map((v,i) => {
          if (i % 2 === 0 || bar) {
            return (
              <div key={i} style={{ ...imgContStyle, width: '100%' }}>
                <div style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/water' + picNums.pop() + '.jpg'})`,
                  ...imgStyle
                }}></div>
              </div>
            );
          } else {
            return (
              <div key={i} style={{ display: 'flex', width: '100%', gap: '5px' }}>
                <div style={ imgContStyle }>
                  <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/water' + picNums.pop() + '.jpg'})`,
                    ...imgStyle
                  }}></div>
                </div>

                <div style={ imgContStyle }>
                  <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/water' + picNums.pop() + '.jpg'})`,
                    ...imgStyle
                  }}></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}





PictureColumn.propTypes = {
  width: PropTypes.string,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  rows: PropTypes.number,
  bar: PropTypes.bool
};