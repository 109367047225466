export default function roundXDigits( number, digits, asFloat=false ) {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  
  let res = (Math.round( Math.round( number * Math.pow(10, digits + 1) ) / 10 ) / Math.pow(10, digits)).toFixed(digits);
  
  if (asFloat) {
    res = parseFloat(res);
  }

  return res;
}