import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';

import { makeStyles } from '@mui/styles';
import { MenuItem, Select, Typography }from '@mui/material';

import { AuthBtnGroup } from './index';


const useStyles = makeStyles(() => ({
  header: {
    width: '100%'
  },
  left: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  right: {
    width: 'fit-content',
    display: 'flex',
    gap: 10
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8
  },
  link: {
    height: 30,
    width: 'fit-content',
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    },
    '&.active': {
      '& > div': {
        paddingBottom: 2,
        borderBottom: '1px solid rgb(242,208,59)'
      }
    }
  },
  linkText: {
    height: 'fit-content',
    width: 'fit-content',
    padding: 3,
    color: 'white',
    fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"',
    fontSize: 14,
    fontWeight: 500
  },
  logo: {
    width: 50,
    height: 50,
    backgroundImage: `url(${process.env.PUBLIC_URL + '/logo192.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  top: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '5px 20px 5px 10px',
    backgroundColor: 'rgb(58,149,210)',
    color: 'white'
  },
  bottom: {
    height: 40,
    backgroundColor: 'rgb(79,88,93)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  divider: {
    height: 30,
    backgroundColor: 'rgb(150,150,150)',
    width: 1
  },
  username: {
    padding: 7
  },
  auth: {
    position: 'relative',
    top: -3
  },
  companyDropdown: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    margin: '0 16px 0 8px',
    paddingBottom: 2,
    '& > .cc': {
      paddingTop: 6,
      marginRight: 8,
      fontSize: 13
    }
  },
  cd: {
    width: 150,
    color: 'white !important',
    textAlign: 'center',
    marginBottom: 2,
    '&:before': {
      borderBottomColor: 'rgb(242,208,59) !important'
    },
    '&:after': {
      borderBottomColor: 'rgb(242,208,59) !important'
    },
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '& > .MuiSelect-select': {
      paddingBottom: 0
    }
  },
  bannerImage: {
    borderBottom: '4px solid rgb(242,208,59)',
    overflow: 'hidden',
    height: 100,
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/banner.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: 'cover'
  }
}));



export default function Header({ user, links, companies, currentCompany, setCurrentCompany }) {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const fullHeader = isAuthenticated || location.pathname === '/Data/View';


  return (
    <header className={classes.header} style={{ height: fullHeader ? 200 : 96 }}>
      <div className={classes.top}>
        <div className={classes.left}>
          <div className={classes.logo}></div>
          <Typography variant='h5'>Connecticut Water Portal</Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.username}>{(user?.email && user.email) || ''}</div>
          <div className={classes.auth}>
            <AuthBtnGroup />
          </div>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.links}>
          {
            links.map((link, i) =>
              <Fragment key={link[0]}>
                <NavLink className={({isActive}) => isActive ? `${classes.link} active` : classes.link} to={link[0]}>
                  <div className={classes.linkText}>
                    {link[1]}
                  </div>
                </NavLink>

                {i !== links.length - 1 && <div className={classes.divider}></div>}
              </Fragment>
            )
          }
        </div>

        <div className={classes.companyDropdown}>
          {companies.length > 0 &&
            <Select
              className={classes.cd}
              size='small'
              variant='standard'
              value={currentCompany}
              renderValue={(selected) => {
                let cArr = companies.find(c => c[1] === selected);
                if (cArr && cArr.length > 0) {
                  return cArr[0];
                } else {
                  return '';
                }
              }}
              onChange={(e) => setCurrentCompany(e.target.value)}
            >
              {
                companies.map(c => <MenuItem key={uuidv4()} value={c[1]} >{c[0]}</MenuItem>)
              }
            </Select>
          }
        </div>
      </div>

      {fullHeader &&
        <div className={classes.bannerImage}></div>
      }
    </header>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  links: PropTypes.array,
  companies: PropTypes.array,
  currentCompany: PropTypes.string,
  setCurrentCompany: PropTypes.func
};