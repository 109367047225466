import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  TextField
}from '@mui/material';

import Pictures from './Pictures';
import Section from './Section';
import { customFetch } from '../Helpers/index';
import { GroundWaterForm, SurfaceWaterForm } from '.';

const useStyles = makeStyles((theme) => ({
  formSeparator: {
    borderBottom: `3px dotted ${theme.palette.primary.main}`,
    width: '94%',
    height: 0,
    margin: '0 auto'
  }
}));



export default function SubmitData({ domain, sites, company, setShowResults, companyMeta }) {
  const [site, setSite] = useState(null);
  const [siteMetaData, setSiteMetaData] = useState({});
  
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();


  useEffect(() => {
    setSite(null);
  }, [company]);


  const getSiteMetaData = async (domain, dataID) => {
    const accessToken = await getAccessTokenSilently();
  
    return await customFetch(`//${domain}/getData`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ type: 'meta', id: dataID })
    });
  };

  const handleChangeSite = async (newSite) => {
    if (newSite) {
      const results = await getSiteMetaData(domain, `${company}.${newSite[1]}`);
      setSiteMetaData(results.res);
    } else {
      setSiteMetaData({});
    }

    setSite(newSite);
  };

  


  return (
    <div style={{display: 'flex', justifyContent: 'space-around'}}>
      <Pictures
        width='20%'
        maxWidth={400}
        height={site ? 2800 : 700}
        rows={site ? 12 : 3}
      />

      <div style={{ width: '75%' }}>
        <Section width='450px' marginTop='0px' titleText='Select Site'>
          <Autocomplete
            options={sites}
            getOptionLabel={option => option.length > 0 ? option[0] : null}
            value={site}
            onChange={(e, val) => handleChangeSite(val)}
            sx={{
              width: 400,
              margin: '10px auto'
            }}
            renderInput={(params) => <TextField
              {...params}
              label='Well or Reservoir'
              variant='outlined'
              size='small'
              helperText='Select from the list a well for groundwater reporting or treatment plant for surface water reporting'
            />}
          />
        </Section>
        { site !== null && <div className={classes.formSeparator}></div> }
        <div>
          {siteMetaData.form === 'ground' &&
              <GroundWaterForm
                domain={domain}
                prefill={{ company: companyMeta, site: siteMetaData}}
                siteAlias={site && site[1]}
                setShowResults={setShowResults}
                setSiteMetaData={setSiteMetaData}
              />
          }
          {siteMetaData.form === 'surface' &&
              <SurfaceWaterForm
                domain={domain}
                prefill={{ company: companyMeta, site: { ...siteMetaData, twfName: site ? site[0] : '' }}}
                siteAlias={site && site[1]}
                setShowResults={setShowResults}
                setSiteMetaData={setSiteMetaData}
              />
          }
        </div>
      </div>
    </div>
  );
}

SubmitData.propTypes = {
  domain: PropTypes.string,
  sites: PropTypes.array,
  company: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setShowResults: PropTypes.func,
  companyMeta: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
};




