import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { Route, Routes } from 'react-router-dom';

import {
  Header,
  Footer,
  Create,
  Edit,
  Loading,
  EditOrSubmit,
  EditData,
  SubmitData,
  ResultPopup,
  Graphs,
  Download,
  Upload,
  ProtectedComponent,
  Map,
  Welcome,
  Pictures,
  DroughtStatus,
} from './Components/index';

import { customFetch } from './Helpers';

const useStyles = makeStyles((theme) => ({
  app: {
    minWidth: 700,
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    boxSizing: 'border-box',
    height: 'fit-content',
    minHeight: 300,
  },
  mainSizer: {
    minHeight: 'calc(100vh - 240px)',
    paddingTop: 30,
    paddingBottom: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: 20,
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    borderRadius: 5,
    padding: 6,
    paddingRight: 12,
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  hr: {
    backgroundColor: 'rgb(242,208,59)',
    height: 4,
    width: '85%',
    margin: '50px auto',
  },
}));

// Domain for fetchs
let DOMAIN;
if (location.hostname === 'localhost') {
  DOMAIN = '127.0.0.1:8787/api';
} else {
  DOMAIN = 'ct-water.eck.workers.dev/api';
}

// [Endpoint, Text for NavLink]
const publicLinks = [
  ['/', 'Home'],
  ['/Data/View', 'View Data'],
];

const userLinks = [
  ...publicLinks,
  ['/Data/New', 'Submit Data'],
  ['/Data/Edit', 'Edit Data'],
  ['/Data/Upload', 'Upload Data'],
];

const adminLinks = [
  ...userLinks,
  ['/Create', 'Create'],
  ['/Edit', 'Edit'],
  ['/Data/Download', 'Download Data'],
];

function App() {
  const [data, setData] = useState({
    companies: [],
    readSite: [],
    writeSite: [],
    userList: [],
    relations: {},
  });
  const [currentCompany, setCurrentCompany] = useState('');
  const [currentCompanyMeta, setCurrentCompanyMeta] = useState(false);
  const [readSiteList, setReadSiteList] = useState([]);
  const [writeSiteList, setWriteSiteList] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [showResults, setShowResults] = useState({
    code: null,
    msg: null,
    type: null,
    action: null,
  });
  const [graphSite, setGraphSite] = useState('');
  const [graphData, setGraphData] = useState(false);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();

  useEffect(() => {
    if (showResults.code !== null && showResults.msg !== null) {
      setShowMsg(true);

      const timeId = setTimeout(() => {
        setShowMsg(false);
      }, 4000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showResults]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        const data = await customFetch(`//${DOMAIN}/getData`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ type: 'user' }),
        });

        setData(data.res);

        setCurrentCompany(
          data.res.companies.length > 0 ? data.res.companies[0][1] : ''
        );
      })();
    }
  }, [user]);

  useEffect(() => {
    if (currentCompany) {
      (async () => {
        const accessToken = await getAccessTokenSilently();

        let results = await customFetch(`//${DOMAIN}/getData`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ type: 'company', id: currentCompany }),
        });

        setCurrentCompanyMeta(results.res);
      })();
    }

    if (currentCompany === '') {
      setReadSiteList([]);
      setWriteSiteList([]);
      setGraphSite('');
    } else {
      const compSites = data.relations[currentCompany].map((s) => s[1]);

      let newRead = data.readSite
        .filter((s) => compSites.includes(s[1]))
        .sort();

      setGraphSite(newRead[0]);
      setReadSiteList(newRead);
      setWriteSiteList(
        data.writeSite.filter((s) => compSites.includes(s[1])).sort()
      );
    }
  }, [currentCompany, data]);

  useEffect(() => {
    if (currentCompany && graphSite && graphSite.length === 3) {
      (async () => {
        const accessToken = await getAccessTokenSilently();

        let results = await customFetch(`//${DOMAIN}/getData`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ type: 'graph', siteAlias: graphSite[1] }),
        });

        setGraphData(results.res);
        setLoading(false);
      })();
    }
  }, [currentCompany, graphSite]);

  const chooseComponent = () => {
    if (isAuthenticated) {
      return (
        <>
          <EditOrSubmit
            writeSites={writeSiteList}
            company={currentCompany}
            companyMeta={currentCompanyMeta}
            domain={DOMAIN}
          />

          <div className={classes.hr}></div>

          <DroughtStatus />

          <div className={classes.hr}></div>

          <Graphs
            data={graphData}
            site={graphSite}
            setSite={setGraphSite}
            siteList={readSiteList}
          />
        </>
      );
    } else {
      return <Welcome key='welcome' />;
    }
  };

  if (loading) {
    if (!isAuthenticated && !isLoading) {
      setLoading(false);
    }
    return <Loading />;
  }

  return (
    <div className={classes.app}>
      <Header
        user={user}
        links={
          isAuthenticated
            ? data.userList && data.userList.length > 0
              ? adminLinks
              : userLinks
            : publicLinks
        }
        companies={data.companies.sort()}
        currentCompany={currentCompany}
        setCurrentCompany={setCurrentCompany}
      />

      <main className={classes.main}>
        <div className={classes.mainSizer}>
          <Routes>
            <Route path='/' element={chooseComponent()} />

            <Route
              path='/Data/View'
              element={
                <Map domain={DOMAIN.split('/')[0]} setLoading={setLoading} />
              }
            />

            <Route
              path='/Data/Edit'
              element={
                <ProtectedComponent>
                  <EditData
                    sites={writeSiteList}
                    company={currentCompany}
                    companyMeta={currentCompanyMeta}
                    domain={DOMAIN}
                    setShowResults={setShowResults}
                  />
                </ProtectedComponent>
              }
            />

            <Route
              path='/Data/New'
              element={
                <ProtectedComponent>
                  <SubmitData
                    sites={writeSiteList}
                    company={currentCompany}
                    companyMeta={currentCompanyMeta}
                    domain={DOMAIN}
                    setShowResults={setShowResults}
                  />
                </ProtectedComponent>
              }
            />

            <Route
              path='/Data/Upload'
              element={
                <ProtectedComponent>
                  <Upload domain={DOMAIN} />
                </ProtectedComponent>
              }
            />

            <Route
              path='/Create'
              element={
                <ProtectedComponent adminOnly userList={data.userList}>
                  <Create
                    domain={DOMAIN}
                    companies={data.companies.sort()}
                    relations={data.relations}
                    siteList={data.readSite.sort()}
                    setData={setData}
                  />
                </ProtectedComponent>
              }
            />

            <Route
              path='/Edit'
              element={
                <ProtectedComponent adminOnly userList={data.userList}>
                  <Edit
                    domain={DOMAIN}
                    companies={data.companies.sort()}
                    relations={data.relations}
                    siteList={data.readSite.sort()}
                    userList={data.userList && data.userList.sort()}
                    setData={setData}
                  />
                </ProtectedComponent>
              }
            />

            <Route
              path='/Data/Download'
              element={
                <ProtectedComponent adminOnly userList={data.userList}>
                  <Download
                    domain={DOMAIN}
                    companies={data.companies.sort()}
                    siteList={data.readSite.sort()}
                    setShowResults={setShowResults}
                  />
                </ProtectedComponent>
              }
            />
          </Routes>

          {location.pathname !== '/Data/New' &&
            location.pathname !== '/Data/Edit' &&
            isAuthenticated && (
              <div style={{ position: 'relative', top: '20px' }}>
                <Pictures width='100%' height={150} rows={6} bar />
              </div>
            )}
        </div>
      </main>

      {showMsg && (
        <ResultPopup
          code={showResults.code}
          msg={showResults.msg}
          type={showResults.type}
          action={showResults.action}
        />
      )}

      <Footer />
    </div>
  );
}

export default App;
