import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography
} from '@mui/material';



export default function Section({ children, titleText, width, padding, marginTop, marginBottom }) {
  return (
    <div
      style={{
        width: width || '80%',
        margin: '60px auto',
        marginTop: marginTop || '60px',
        marginBottom: marginBottom || '60px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgb(84,141,212)',
          padding: 12,
          width: '100%',
          boxSizing: 'border-box',
          minHeight: 53
        }}
      >
        {
          typeof titleText === 'string' ? <Typography variant='h2' style={{color: 'white'}}>{titleText}</Typography> : titleText
        }
      </div>
      
      <div
        style={{
          backgroundColor: 'rgb(241,241,241)',
          width: '100%',
          padding: padding || 20,
          boxSizing: 'border-box'
        }}
      >
        {children}
      </div>
    </div>
  );
}

Section.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.element,
  titleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
};