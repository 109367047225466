// For updating map precip colors
const cityLngLats = {
  'Franklin': [-72.1425,41.6197],
  'Mansfield': [-72.2289,41.7883],
  'Andover': [-72.3704,41.7373],
  'Ansonia': [-73.0787,41.343],
  'Ashford': [-72.1215,41.8732],
  'Avon': [-72.8307,41.8098],
  'Barkhamsted': [-72.914,41.9293],
  'Beacon Falls': [-73.0626,41.4429],
  'Bethlehem': [-73.2081,41.6394],
  'Bethel': [-73.414,41.3712],
  'Berlin': [-72.7457,41.6215],
  'Bethany': [-72.997,41.4218],
  'Bloomfield': [-72.7301,41.8265],
  'Bolton': [-72.4334,41.769],
  'Bozrah': [-72.1682,41.5506],
  'Bridgewater': [-73.3662,41.5351],
  'Branford': [-72.8151,41.2795],
  'Bristol': [-72.9464,41.6736],
  'Bridgeport': [-73.2048,41.167],
  'Brookfield': [-73.4096,41.4826],
  'Chaplin': [-72.1273,41.7948],
  'Canaan': [-73.3293,42.0273],
  'Burlington': [-72.9645,41.7693],
  'Brooklyn': [-71.9498,41.7882],
  'Canterbury': [-71.9709,41.6984],
  'Canton': [-72.911654,41.866088],
  'Cheshire': [-72.9007,41.499],
  'Chester': [-72.4509,41.4032],
  'Colchester': [-72.332,41.5757],
  'Clinton': [-72.5276,41.2787],
  'Colebrook': [-73.0957,41.9895],
  'Cornwall': [-73.332943,41.844528],
  'Columbia': [-72.3012,41.702],
  'Danbury': [-73.454,41.3948],
  'Coventry': [-72.3051,41.7701],
  'Cromwell': [-72.6454,41.5951],
  'Darien': [-73.4693,41.0787],
  'Deep River': [-72.4356,41.3857],
  'Derby': [-73.089,41.3224],
  'Durham': [-72.6812,41.4818],
  'East Haddam': [-72.4612,41.4532],
  'East Granby': [-72.7273,41.9412],
  'East Hampton': [-72.5026,41.5759],
  'East Hartford': [-72.612,41.7823],
  'East Haven': [-72.8684,41.2762],
  'East Lyme': [-72.21,41.37],
  'Eastford': [-72.0799,41.9021],
  'East Windsor': [-72.6131,41.9156],
  'Easton': [-73.2973,41.2529],
  'Ellington': [-72.4698,41.904],
  'Enfield': [-72.5918,41.9762],
  'Essex': [-72.3906,41.3534],
  'Farmington': [-72.832,41.7198],
  'Fairfield': [-73.2637,41.1412],
  'Glastonbury': [-72.6081,41.7123],
  'Granby': [-72.7887,41.954],
  'Goshen': [-73.2251,41.8318],
  'Greenwich': [-73.6285,41.0265],
  'Griswold': [-71.9622,41.6035],
  'Groton': [-72.0774,41.3507],
  'Hampton': [-72.0548,41.784],
  'Guilford': [-72.6818,41.289],
  'Haddam': [-72.512,41.4773],
  'Hamden': [-72.8969,41.3959],
  'Hartford': [-72.6831,41.7635],
  'Hartland': [-72.9054,41.999],
  'Harwinton': [-73.0598,41.7712],
  'Hebron': [-72.3659,41.6579],
  'Kent': [-73.4693,41.7376],
  'Killingly': [-71.8692,41.8387],
  'Killingworth': [-72.5637,41.3582],
  'Lebanon': [-72.2126,41.6362],
  'Ledyard': [-72.0175,41.4386],
  'Lisbon': [-72.0208,41.5867],
  'Madison': [-72.5983,41.2794],
  'Litchfield': [-73.1887,41.7473],
  'Lyme': [-72.34,41.38],
  'Manchester': [-72.5215,41.7759],
  'Meriden': [-72.807,41.5382],
  'Middlefield': [-72.712,41.5173],
  'Marlborough': [-72.4598,41.6315],
  'Middlebury': [-73.1276,41.5279],
  'Middletown': [-72.6506,41.5623],
  'New Britain': [-72.7795,41.6612],
  'Naugatuck': [-73.0509,41.486],
  'Monroe': [-73.2073,41.3326],
  'Morris': [-73.1962,41.6843],
  'Milford': [-73.0565,41.2223],
  'Montville': [-72.1556,41.464],
  'New Canaan': [-73.4948,41.1468],
  'New Milford': [-73.4106,41.5771],
  'New Haven': [-72.9251,41.3082],
  'New London': [-72.0995,41.3557],
  'New Fairfield': [-73.4857,41.4665],
  'New Hartford': [-72.977,41.8823],
  'Newington': [-72.7237,41.6979],
  'Norfolk': [-73.2021,41.994],
  'Newtown': [-73.3086,41.4135],
  'North Branford': [-72.7673,41.3276],
  'North Canaan': [-73.33,42.03],
  'North Haven': [-72.8595,41.3909],
  'North Stonington': [-71.8812,41.4409],
  'Norwich': [-72.0759,41.5243],
  'Norwalk': [-73.4079,41.1176],
  'Oxford': [-73.1168,41.434],
  'Old Lyme': [-72.329,41.3159],
  'Old Saybrook': [-72.3762,41.2918],
  'Orange': [-73.0257,41.2784],
  'Plainfield': [-71.9151,41.6765],
  'Plainville': [-72.8582,41.6745],
  'Portland': [-72.6406,41.5729],
  'Pomfret': [-71.967437,41.888893],
  'Plymouth': [-73.0529,41.672],
  'Preston': [-71.9821,41.5268],
  'Putnam': [-71.9093,41.9153],
  'Prospect': [-72.9787,41.5023],
  'Redding': [-73.3835,41.3026],
  'Ridgefield': [-73.4982,41.2815],
  'Roxbury': [-73.3089,41.5568],
  'Rocky Hill': [-72.6393,41.6648],
  'Salem': [-72.2754,41.4904],
  'Salisbury': [-73.4212,41.9834],
  'Scotland': [-72.12,41.71],
  'Seymour': [-73.0743,41.3943],
  'Sharon': [-73.4768,41.8793],
  'Shelton': [-73.0932,41.3165],
  'Somers': [-72.4462,41.9854],
  'Sherman': [-73.4957,41.5793],
  'Simsbury': [-72.8012,41.8759],
  'South Windsor': [-72.5718,41.849],
  'Southbury': [-73.2132,41.4815],
  'Southington': [-72.8783,41.6005],
  'Sprague': [-72.05,41.6],
  'Stafford': [-72.289439,41.984709],
  'Sterling': [-71.8287,41.7076],
  'Stonington': [-71.9059,41.3359],
  'Thomaston': [-73.0732,41.674],
  'Stratford': [-73.1332,41.1845],
  'Thompson': [-71.8626,41.9587],
  'Suffield': [-72.6506,41.9818],
  'Tolland': [-72.3677,41.87],
  'Torrington': [-73.1212,41.8007],
  'Trumbull': [-73.2007,41.2429],
  'Stamford': [-73.5387,41.0534],
  'Wallingford': [-72.8232,41.457],
  'Voluntown': [-71.8703,41.5707],
  'Union': [-72.1573,41.9909],
  'Vernon': [-72.4795,41.8187],
  'Warren': [-73.3487,41.7429],
  'Washington': [-73.3107,41.6315],
  'Waterbury': [-73.0515,41.5582],
  'Waterford': [-72.147,41.3498],
  'Watertown': [-73.1182,41.6062],
  'West Hartford': [-72.742,41.762],
  'Westbrook': [-72.4476,41.2854],
  'West Haven': [-72.947,41.2707],
  'Willington': [-72.2615,41.8854],
  'Weston': [-73.3805,41.2012],
  'Wethersfield': [-72.6526,41.7143],
  'Westport': [-73.3579,41.1415],
  'Winchester': [-73.1348,41.9001],
  'Wilton': [-73.4379,41.1954],
  'Windsor': [-72.6437,41.8526],
  'Windham': [-72.1576,41.6999],
  'Wolcott': [-72.9868,41.6023],
  'Windsor Locks': [-72.6273,41.9293],
  'Woodstock': [-71.974,41.9484],
  'Woodbridge': [-73.0084,41.3526],
  'Woodbury': [-73.209,41.5445]
};



// For displaying which PWSs are in each town on hover and for retrieving the PWS' data onClick
const pwsInCity = {
  'Bethel': ['Bethel Water Dept'],
  'Bridgeport': ['Aquarion Water Co of CT-Main System'],
  'Bristol': ['Bristol Water Department'],
  'Chester': ['CTWC - Shoreline Region-Chester System'],
  'Danbury': ['Danbury Water Department'],
  'East Windsor': ['CTWC - Northern Reg-Western System'],
  'Greenwich': ['Aquarion Water Co of CT-Greenwich System'],
  'Griswold': ['Jewett City Water Company'],
  'Groton': ['Groton Utilities'],
  'Guilford': ['CTWC - Shoreline Region-Guilford System'],
  'Hartford': ['Metropolitan District Commission'],
  'Manchester': ['Manchester Water Department'],
  'Meriden': ['Meriden Water Division'],
  'MiddleTown': ['Middletown Water Department', 'Connecticut Valley Hospital'],
  'Naugatuck': ['CTWC - Naugatuck Region-Central System'],
  'New Britain': ['New Britain Water Department'],
  'New Haven': ['Regional Water Authority'],
  'New London': ['New London Dept. of Public Utilities'],
  'Norfolk': ['Aquarion Water Co of CT-Norfolk System'],
  'Norwalk': ['Norwalk First Taxing District', 'South Norwalk Electric & Water'],
  'Norwich': ['Norwich Public Utilities'],
  'Salisbury': ['Aquarion Water Co of CT-Salisbury Sys'],
  'Sharon': ['Sharon Water & Sewer Commission'],
  'Southington': ['Southington Water Department'],
  'Stafford': ['CTWC - Northern Reg-Stafford System'],
  'Stamford': ['Aquarion Water Co of CT-Stamford'],
  'Stonington': ['Aquarion Water Co of CT-Mystic'],
  'Torrington': ['Torrington Water Company'],
  'Wallingford': ['Wallingford Water Department'],
  'Waterbury': ['Waterbury Water Department'],
  'Winchester': ['Winsted Water Works'],
  'Windham': ['Windham Water Works']
};

const hsls = [
  [31,85,25],
  [43,85,50],
  [73,75,50],
  [97,85,50],
  [130,65,25]
];


export { pwsInCity, cityLngLats, hsls };