import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  TextField,
  Button,
  Typography,
  FormGroup
} from '@mui/material';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';



export default function Comments({ classes, data, handleChange, submitAttempted, weekNum, dateBounds, keepOne }) {
  const addComment = () => {
    handleChange([...data, {date: '', comment: ''}], weekNum, 'comments');
  };

  const removeComment = (id) => {
    if (!keepOne || (keepOne && data.length > 1)) {
      handleChange(data.filter((s,i)=> i !== id), weekNum, 'comments');
    }
  };

  const updateComment = (val, id, type) => {
    const newData = data.map((obj,i) => {
      if (id !== i) {
        return obj;
      } else {
        return { ...obj, [type]: val};
      }
    });

    handleChange(newData, weekNum, 'comments');
  };


  return (
    <FormGroup>
      <Typography variant='sectionTitle'>Comments</Typography>

      { data.length === 0 ? <div style={{ textAlign: 'center', fontStyle: 'italic', marginTop: 20 }}>No Comments</div> :
        data.map((obj, i) => {
          return (
            <div className={classes.wrapper} key={i}>
              <div className={classes.commentGroup}>
                <div className={classes.iconBtnCont}>
                  <IconButton className={classes.iconBtn} onClick={() => removeComment(i)}><HighlightOffIcon className={classes.icon} /></IconButton>
                </div>

                <TextField
                  size='small'
                  variant='outlined'
                  label='Date'
                  value={obj.date}
                  onChange={(e) => updateComment(e.target.value, i, 'date')}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  inputProps={dateBounds}
                  error={submitAttempted && obj.date === ''}
                  disabled={dateBounds.min === '' || dateBounds.max === ''}
                />
                <TextField
                  sx={{width: '58%'}}
                  size='small'
                  variant='outlined'
                  label='Comment'
                  helperText={i === data.length - 1 && 'Description: Weather / Rainfall / Measurement point changes / Well dynamic state (rising, falling, flowing) / Missed or Inaccurate measurement / Other'}
                  value={obj.comment}
                  onChange={(e) => updateComment(e.target.value, i, 'comment')}
                  multiline
                  rows={4}
                  error={submitAttempted && obj.comment === ''}
                />
              </div>
            </div>
          );
        })}

      <Button
        className={classes.btn}
        onClick={addComment}
      >
        {data.length > 0 ? 'Add Another Comment' : 'Add A Comment'}
      </Button>
    </FormGroup>
  );
}

Comments.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  handleChange: PropTypes.func,
  submitAttempted: PropTypes.bool,
  weekNum: PropTypes.number,
  dateBounds: PropTypes.object,
  keepOne: PropTypes.bool
};