import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
  FormGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  customFetch,
} from '../../../Helpers/index';

import SiteSelector from '../Create/SiteSelector';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  names: {
    justifyContent: 'space-between'
  },
  name: {
    width: '49%'
  },
  siteSelectorGrid: {
    padding: 18,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    gap: 16,
    justifyContent: 'space-evenly'
  },
  selectCompany: {
    textAlign: 'center',
    fontStyle: 'italic'
  },
  btnGroup: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between'
  },
  submit: {
    width: 150,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(5, 66, 102) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(1,24,35) !important',
    }
  },
  reset: {
    width: 150,
    height: 40,
    color: 'black !important',
    backgroundColor: 'rgb(242, 208, 59) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgba(212, 178, 29) !important'
    }
  },
  delete: {
    width: 150,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(219, 107, 113) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(189, 77, 83) !important',
    }
  },
}));


const initSite = (siteArr, companies, relations) => {
  let obj = {};
  let site = siteArr.map(site => site[1]);

  companies.forEach(c => {
    obj[c[0]] = [];

    relations[c[1]].forEach(arr => {
      if (site.includes(arr[1])) {
        obj[c[0]].push(arr[1]);
      }
    });
  });

  return obj;
};



export default function EditUser({ userName, domain, setShowResults, userInfo, allComps, allRelations, setData }) {
  const [init, setInit] = useState(true);
  const [selectedCompanies, setSelectedCompanies] = useState(userInfo.companies || []);
  const [readSite, setReadSite] = useState({});
  const [writeSite, setWriteSite] = useState({});
  const [loading, setLoading] = useState(false);
  
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  // Updates data if a different user is selected or the users' company list changes
  useEffect(() => {
    let newRead = {};
    let newWrite = {};

    if (init) {
      newRead = initSite(userInfo.readSite, userInfo.companies, userInfo.relations);
      newWrite = initSite(userInfo.writeSite, userInfo.companies, userInfo.relations);
      setInit(false);
    } else {
      selectedCompanies.forEach(c => {
        if (Object.keys(readSite).includes(c[0])) {
          newRead[c[0]] = [...readSite[c[0]]];
          newWrite[c[0]] = [...writeSite[c[0]]];
        } else {
          newRead[c[0]] = allRelations[c[1]].map(site => site[1]);
          newWrite[c[0]] = [];
        }
      });
    }
    
    setReadSite(newRead);
    setWriteSite(newWrite);
  }, [selectedCompanies, userName]);


  // API request function, handles updates
  const requestEdit = async (rwc, user) => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user,
        rwc,
        method: 'update',
        type: 'user'
      })
    });
  };
  

  const handleSubmit = async () => {
    setLoading('update');

    // Transform state data to KV format data
    const rwc = Object.keys(readSite).reduce((acc,compName) => {
      readSite[compName].forEach(site => {
        if (!acc.read.includes(site)) {
          acc.read.push(site);
        }
      });

      writeSite[compName].forEach(site => {
        if (!acc.write.includes(site)) {
          acc.write.push(site);
        }
      });

      acc.companies.push(allComps.find(c => c[0] === compName)[1]);

      return acc;
    }, {read: [], write: [], companies: []});

    let results = await requestEdit(rwc, userName);

    // If token expired during call, try again, this should auto refresh the token
    if (results.code === 419) {
      results = await requestEdit(rwc, userName);
    }

    setShowResults(results);
    setLoading(false);
  };


  // API request function, handles password resets
  const requestResetPassword = async (email) => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/resetPassword`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: email
    });
  };

  
  // API request function, handles deletes
  const requestDelete = async (item) => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        method: 'delete',
        type: 'user',
        user: item
      })
    });
  };


  const handleResetPassword = async () => {
    setLoading('reset');
    
    let results = await requestResetPassword(userName);

    // If token expired during call, try again, this should auto refresh the token
    if (results.code === 419) {
      results = await requestResetPassword(userName);
    }

    setShowResults(results);
    setLoading(false);
  };

  
  const handleDelete = async () => {
    setLoading('delete');
    
    if (confirm(`Are you sure that you want to delete ${userName}`)) {
      let results = await requestDelete(userName);

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestDelete(userName);
      }

      setShowResults(results);

      setData(prev => { return { ...prev, userList: prev.userList.filter(name => name !== userName) }; });
    }
    
    setLoading(false);
  };


  return (
    <FormGroup>
      <Typography variant='sectionTitle'>Accessible Water Systems</Typography>

      <Autocomplete
        multiple
        fullWidth
        size='small'
        options={allComps}
        value={selectedCompanies}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Water Systems'
          />
        )}
        onChange={(event, newValue) => setSelectedCompanies(newValue)}
        getOptionLabel={option => option[0]}
        sx={{margin: '0 auto', width: '95%'}}
        isOptionEqualToValue={(opt, val) => opt[1] === val[1]}
      />


      <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Accessible Wells and Treatment Plants</Typography>
      {(!selectedCompanies || selectedCompanies.length === 0) && <div className={classes.selectCompany}>No water systems selected.</div>}

      <div className={classes.siteSelectorGrid}>
        {
          selectedCompanies.map(company =>
            <SiteSelector
              key={company[1]}
              companyName={company[0]}
              relations={allRelations[company[1]]}
              readSite={readSite}
              setReadSite={setReadSite}
              writeSite={writeSite}
              setWriteSite={setWriteSite}
            />
          )
        }
      </div>

      <div className={classes.btnGroup}>
        <Button className={classes.submit} onClick={handleSubmit} >{loading === 'update' ? <CircularProgress size={20} color='update' /> : 'Update User'}</Button>
        <Button className={classes.reset} onClick={handleResetPassword} >{loading === 'reset' ? <CircularProgress size={20} color='reset' /> : 'Reset Password'}</Button>
        <Button className={classes.delete} onClick={handleDelete} >{loading === 'delete' ? <CircularProgress size={20} color='delete' /> : 'Delete User'}</Button>
      </div>
    </FormGroup>
  );
}

EditUser.propTypes = {
  domain: PropTypes.string,
  userName: PropTypes.string,
  setShowResults: PropTypes.func,
  userInfo: PropTypes.object,
  allComps: PropTypes.array,
  allRelations: PropTypes.object,
  setData: PropTypes.func
};