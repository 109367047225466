import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Popper,
  Fade
}from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    borderRadius: '50%',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(27,136,199, 0.05)',
      cursor: 'pointer'
    }
  },
  popper: {
    borderRadius: 5,
    border: '1px solid rgb(80,80,80)',
    boxShadow: '5px 5px 6px 0px rgba(0,0,0,0.25)',
    backgroundColor: 'white',
    maxWidth: 400,
    maxHeight: 400,
    overflowX: 'auto',
    padding: 18,
  }
}));



export default function IconPopper({ children, vertAdjust, icon, size, padding }) {
  const [popperAnchor, setPopperAnchor] = useState(false);
  const [open, setOpen] = useState(false);
  
  const classes = useStyles();

  const handleHover = (event) => {
    setPopperAnchor(event.currentTarget);
    setOpen(prev => !prev);
  };

  
  return (
    <div
      className={classes.container}
      style={{
        bottom: vertAdjust || '0px',
        height: size || '14px',
        width: size || '14px',
        padding: padding || '6px'
      }}
      onMouseEnter={handleHover}
      onMouseLeave={() => setOpen(prev => !prev)}
    >

      {icon}

      <Popper
        style={{zIndex: 1}}
        open={open}
        anchorEl={popperAnchor}
        placement="top"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <div className={classes.popper}>
              {children}
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

IconPopper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  vertAdjust: PropTypes.string,
  icon: PropTypes.element,
  size: PropTypes.string,
  padding: PropTypes.string
};