import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { roundXDigits } from '../../../Helpers';
import IconPopper from '../../IconPopper';


const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    maxWidth: 740,
    margin: '0 auto'
  },
  textfield: {
    marginLeft: '3% !important',
    width: '47%',
  },
  group: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    padding: 15,
    border: '1px solid rgba(180,180,180,0.7)',
    borderRadius: 5,
    backgroundColor: 'rgb(157,224,255)',
    marginTop: 40,
    boxSizing: 'border-box'
  },
  value: {
    marginLeft: '10px !important',
    fontWeight: 'bold !important'
  },
  labelLine: {
    display: 'flex',
    alignItems: 'center'
  }
}));



export default function SupplyData({ handleChange, submitAttempted, data }) {
  const classes = useStyles();
  
  return (
    <>
      <div className={classes.box}>
        <FormGroup>
          <FormGroup row={true} className={classes.labelLine}>
            <Typography variant='sectionTitle' sx={{marginTop: 0}}>Treatment Plant</Typography>
          
            <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='17px'>
              <Typography variant='popupSubheading'>Average Daily Production from the Treatment Plant</Typography>
              <Typography variant='popupBody'>Average volume of water supplied by the Treatment Water System Facility in Million Gallons per day (MGD).</Typography>
            </IconPopper>
          </FormGroup>

          <TextField
            className={classes.textfield}
            size='small'
            label='Avg. Daily Production (MGD)'
            value={data.avgDP}
            onChange={(e) => handleChange(roundXDigits(e.target.value, 3, true), 'supplyData.avgDP')}
            type='number'
            inputProps={{
              step: '0.01',
              min: '0'
            }}
            error={submitAttempted && data.avgDP === ''}
          />
        </FormGroup>

        <FormGroup>
          <FormGroup row={true} className={classes.labelLine}>
            <Typography variant='sectionTitle'>All Reservoirs</Typography>
          
            <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='8px'>
              <Typography variant='popupSubheading'>Total Withdrawn from all Reservoirs</Typography>
              <Typography variant='popupBody'>Total withdrawn volume from all reservoirs in Million Gallons (MG).</Typography>
            </IconPopper>
          </FormGroup>

          <TextField
            className={classes.textfield}
            size='small'
            label='Total Withdrawn (MG)'
            value={data.totalWithdrawn}
            onChange={(e) => handleChange(roundXDigits(e.target.value, 3, true), 'supplyData.totalWithdrawn')}
            type='number'
            inputProps={{
              step: '0.01',
              min: '0'
            }}
            error={submitAttempted && data.totalWithdrawn === ''}
          />
        </FormGroup>
      </div>

      <div className={classes.group} style={{maxHeight: 53}}>
        <Typography>Days of Supply Remaining (days):</Typography>
        <Typography className={classes.value}>{data.daysRemaining === null ? 0 : data.daysRemaining}</Typography>

        <div style={{position: 'relative', top: '-12px'}}>
          <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />}>
            <Typography variant='popupSubheading'>Days of Supply Remaining</Typography>
            <Typography variant='popupBody'>Instantaneous Usable Storage / Average Daily Production from the Treatment Plant in days.</Typography>
          </IconPopper>
        </div>
      </div>
    </>
  );
}

SupplyData.propTypes = {
  handleChange: PropTypes.func,
  submitAttempted: PropTypes.bool,
  data: PropTypes.object,
};