import React, { useState, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormGroup,
  Typography,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';

import { customFetch } from '../../../Helpers/index';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginBottom: 30
  },
  input: {
    marginTop: 5
  },
  formLabel: {
    color: 'rgb(25,50,181) !important',
    marginTop: 20
  },
  btnGroup: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  submit: {
    width: 200,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(5, 66, 102) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(1,24,35) !important',
    }
  },
  delete: {
    width: 200,
    height: 40,
    color: 'white !important',
    backgroundColor: 'rgb(219, 107, 113) !important',
    boxSizing: 'border-box !important',
    '&:hover': {
      backgroundColor: 'rgb(189, 77, 83) !important',
    }
  },
  btn: {
    backgroundColor: 'rgb(25,118,210) !important',
    color: 'white !important',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    marginBottom: '10px !important',
    '&:hover': {
      backgroundColor: 'rgb(05,98,190) !important',
      color: 'rgb(230,230,230) !important',
    }
  },
  iconBtn: {
    width: 40,
    height: 40,
    marginTop: '6px !important',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(210,118,25,0.04) !important'
    }
  },
  iconBtnCont: {
    position: 'absolute',
    left: -18,
    top: -5
  },
  icon: {
    color: 'rgb(220,40,40)'
  },
  swf: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: 30
  },
  hr: {
    backgroundColor: 'rgb(242,208,59)',
    height: 4,
    width: '65%',
    margin: '30px auto'
  },
  gap: {
    gap: '30px',
    width: '95%',
    margin: '0 auto'
  }
}));



export default function EditSite({ domain, setShowResults, allRelations, setData, siteData, setSiteData, siteName }) {
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();


  // API request function, handles updates
  const requestEdit = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        type: 'site',
        method: 'update',
        siteData,
        alias: siteName[1]
      })
    });
  };


  // API request function, handles deleting
  const requestDelete = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/saveChanges`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        type: 'site',
        method: 'delete',
        siteAlias: siteName[1]
      })
    });
  };


  const handleSubmit = async () => {
    setLoading('update');
    
    let results = await requestEdit();

    // If token expired during call, try again, this should auto refresh the token
    if (results.code === 419) {
      results = await requestEdit();
    }

    setShowResults(results);
    setLoading(false);
  };


  const handleDelete = async () => {
    setLoading('delete');
    
    if (confirm(`Are you sure that you want to remove ${siteName[0]} from all water systems? (This will cause the facility data to be inaccessible from the website. It will not delete any data nor will it revoke user read/write permissions. If this site is later re-added to a company, then any users with permissions for both that the company and that site will be able to access it normally.)`)) {
      let results = await requestDelete();

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestDelete();
      }
      
      if (results.code === 200) {
        let newRels = {};
        for (const [cAlias, cRels] of Object.entries(allRelations)) {
          newRels[cAlias] = cRels.filter(siteArr => {
            return siteArr[1] !== siteName[1];
          });
        }
      
        setData(prev => { return { ...prev, relations: { ...prev.relations, ...newRels } }; });
      }

      setShowResults(results);
    }
    
    setLoading(false);
  };


  const addSWF = () => {
    setSiteData(prev => {
      return {
        ...prev,
        swfs: [ ...prev.swfs, { id: '', name: '', defaultTUS: '' }]
      };
    });
  };

  
  const removeSWF = (index) => {
    setSiteData(prev => {
      return {
        ...prev,
        swfs: prev.swfs.filter((x, i) => i !== index)
      };
    });
  };


  return (
    <>
      {siteData.form === 'ground' && 
          <FormGroup sx={{marginBottom: '30px'}}>
            <Typography variant='sectionTitle'>Ground Water Form Information</Typography>
            <FormGroup className={classes.gap}>
              <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Well ID'
                value={siteData.wellID}
                onChange={(e) => setSiteData(prev => { return { ...prev, wellID: e.target.value }; })}
                error={siteData.wellID === ''}
                helperText={siteData.wellID === '' && 'ID is required'}
              />

              <FormGroup row={true} style={{justifyContent: 'space-around'}}>
                <TextField
                  className={classes.input}
                  size='small'
                  variant='outlined'
                  label='Latitude'
                  type='number'
                  value={siteData.lngLat[1]}
                  onChange={(e) => setSiteData(prev => { return { ...prev, lngLat: [ prev.lngLat[0], e.target.value ] }; })}
                  error={siteData.lngLat[1] === ''}
                  helperText={siteData.lngLat[1] === '' && 'Required and must be between 40.9 and 42.1'}
                />

                <TextField
                  className={classes.input}
                  size='small'
                  variant='outlined'
                  label='Longitude'
                  type='number'
                  value={siteData.lngLat[0]}
                  onChange={(e) => setSiteData(prev => { return { ...prev, lngLat: [ e.target.value, prev.lngLat[1] ] }; })}
                  error={siteData.lngLat[0] === ''}
                  helperText={siteData.lngLat[0] === '' && 'Required and must be between -73.8 and -71.7'}
                />
              </FormGroup>

              {/* <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Population Served'
                type='number'
                inputProps={{min: 0}}
                value={siteData.populationServed}
                onChange={(e) => setSiteData(prev => { return { ...prev, populationServed: e.target.value }; })}
                error={siteData.populationServed === 0}
                helperText={siteData.populationServed === 0 && 'Population served is required'}
              /> */}
            </FormGroup>
          </FormGroup>
      }

      {siteData.form === 'surface' &&
          <FormGroup>
            <Typography variant='sectionTitle'>Surface Water Form Information</Typography>
            <FormGroup className={classes.gap}>
              <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Treatment WSFID'
                value={siteData.twsfID}
                onChange={(e) => setSiteData(prev => { return { ...prev, twsfID: e.target.value }; })}
                error={siteData.twsfID === ''}
                helperText={siteData.twsfID === '' && 'ID is required'}
              />
            </FormGroup>

            <Typography variant='sectionTitle'>Source Water Facilities</Typography>
            {siteData.swfs.map((swf, i) => {
              return (
                <Fragment key={i}>
                  <div className={classes.swf} key={i}>
                    <div className={classes.iconBtnCont}>
                      <IconButton className={classes.iconBtn} onClick={() => removeSWF(i)}><HighlightOffIcon className={classes.icon} /></IconButton>
                    </div>
                    
                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='SWF Name'
                      value={swf.name}
                      onChange={(e) => setSiteData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].name = e.target.value;
                        return newDataObj;
                      })}
                      error={siteData.swfs[i].name === ''}
                      helperText={siteData.swfs[i].name === '' && 'Name is required'}
                    />
    
                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='SWF ID'
                      value={swf.id}
                      onChange={(e) => setSiteData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].id = e.target.value;
                        return newDataObj;
                      })}
                      error={siteData.swfs[i].id === ''}
                      helperText={siteData.swfs[i].id === '' && 'ID is required'}
                    />

                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='Default Total Usable Storage'
                      type='number'
                      inputProps={{min: 0}}
                      value={swf.defaultTUS}
                      onChange={(e) => setSiteData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].defaultTUS = e.target.value;
                        return newDataObj;
                      })}
                      error={siteData.swfs[i].defaultTUS === 0}
                      helperText={siteData.swfs[i].defaultTUS === 0 && 'Default TUS is required'}
                    />
                  </div>

                  <div className={classes.hr}></div>
                </Fragment>
              );
            })
            }

            <Button
              className={classes.btn}
              onClick={addSWF}
            >
              {siteData.swfs.length > 0 ? 'Add Another Source Water Facility' : 'Add A Source Water Facility'}
            </Button>
          </FormGroup>
      }

      <div className={classes.btnGroup}>
        <Button className={classes.submit} onClick={handleSubmit} >{loading === 'update' ? <CircularProgress size={20} color='update' /> : 'Update Site'}</Button>
        <Button className={classes.delete} onClick={handleDelete} >{loading === 'delete' ? <CircularProgress size={20} color='delete' /> : 'Delete Site'}</Button>
      </div>
    </>
  );
}

EditSite.propTypes = {
  domain: PropTypes.string,
  setShowResults: PropTypes.func,
  allRelations: PropTypes.object,
  setData: PropTypes.func,
  siteData: PropTypes.object,
  setSiteData: PropTypes.func,
  siteName: PropTypes.array
};