/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormGroup,
  Typography,
  TextField,
  Autocomplete,
  MenuItem,
  Button,
  IconButton
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';

import { customFetch, constructObj } from '../../../Helpers/index';

import SubmitBtn from '../../SubmitBtn';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginBottom: 20
  },
  input: {
    minWidth: '200px !important'
  },
  lnglat: {
    width: '49%'
  },
  btn: {
    backgroundColor: 'rgb(25,118,210) !important',
    color: 'white !important',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgb(05,98,190) !important',
      color: 'rgb(230,230,230) !important',
    }
  },
  iconBtn: {
    width: 40,
    height: 40,
    marginTop: '6px !important',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(210,118,25,0.04) !important'
    }
  },
  iconBtnCont: {
    position: 'absolute',
    left: -18,
    top: -5
  },
  icon: {
    color: 'rgb(220,40,40)'
  },
  swf: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    gap: 30
  },
  hr: {
    backgroundColor: 'rgb(242,208,59)',
    height: 4,
    width: '65%',
    margin: '30px auto'
  },
  gap: {
    gap: '30px',
    width: '95%',
    margin: '0 auto'
  },
  spread: {
    justifyContent: 'space-around'
  }
}));


const groundList = ['wellID'];
// const groundList = ['wellID', 'populationServed'];
const surfaceList = ['twsfID', 'swfs'];



// Form allowing admin accounts to create new sites
export default function NewSite({ domain, setShowResults, companies, setData }) {
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [compError, setCompError] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [form, setForm] = useState('');
  const [formError, setFormError] = useState(false);
  const [lngLat, setLngLat] = useState(['','']);
  const [lngLatError, setLngLatError] = useState(false);
  const [groundData, setGroundData] = useState(constructObj(groundList, ''));
  const [groundErrors, setGroundErrors] = useState(constructObj(groundList, false));
  const [surfaceData, setSurfaceData] = useState(constructObj(surfaceList, ''));
  const [surfaceErrors, setSurfaceErrors] = useState(constructObj(surfaceList, false));

  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  

  // API request function
  const requestCreateSite = async () => {
    const accessToken = await getAccessTokenSilently();
    let meta = form === 'ground' ? groundData : surfaceData;

    return await customFetch(`//${domain}/admin/create`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
        addToComps: selectedCompany,
        type: 'site',
        meta: {
          ...meta,
          lngLat,
          form
        }
      })
    });
  };
  

  // Checks that form is complete, submits request, and shows results in popup
  const handleSubmit = async () => {
    setLoading(true);

    let errors = false;
    if (name === '') {
      errors = true;
      setNameError(true);
    }

    if (
      lngLat[0] === '' ||
      lngLat[0] > -71.7 ||
      lngLat[0] < -73.8 ||
      lngLat[1] === '' ||
      lngLat[1] > 42.1 ||
      lngLat[1] < 40.9
    ) {
      errors = true;
      setLngLatError(true);
    }

    if (selectedCompany === null) {
      errors = true;
      setCompError(true);
    }

    if (form === '') {
      errors = true;
      setFormError(true);
    } else if (form === 'ground') {
      let newGroundErrors = constructObj(groundList, false);
      
      if (groundData.wellID === '') {
        errors = true;
        newGroundErrors.wellID = 'Well ID is required';
      }

      // if (groundData.populationServed === 0) {
      //   errors = true;
      //   newGroundErrors.populationServed = 'Population Served cannot be 0';
      // }

      setGroundErrors(newGroundErrors);
    } else if (form === 'surface') {
      let newSurfaceErrors = constructObj(surfaceList, false);

      if (surfaceData.twsfID === '') {
        errors = true;
        newSurfaceErrors.twsfID = 'ID is required';
      }

      newSurfaceErrors.swfs = surfaceData.swfs.map(obj => {
        if (obj.id === '' || obj.name === '' || obj.defaultTUS === '') {
          errors = true;
        }

        return {
          id: obj.id === '' ? 'ID is required' : false,
          name: obj.name === '' ? 'Name is required' : false,
          defaultTUS: obj.defaultTUS === '' ? 'Default TUS is required' : false
        };
      });

      setSurfaceErrors(newSurfaceErrors);
    }

    if (!errors) {
      let results = await requestCreateSite();
  
      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestCreateSite();
      }
      
      if (results.code === 200) {
        setData(prev => {
          let newRels = { ...prev.relations };
          newRels[selectedCompany[1]] = [ ...newRels[selectedCompany[1]], results.res.siteID ];

          return {
            ...prev,
            readSite: [ ...prev.readSite, results.res.siteID ],
            writeSite: [ ...prev.writeSite, results.res.siteID ],
            relations: {
              ...prev.relations,
              ...newRels
            }
          };
        });
        setName('');
        setForm('');
        setLngLat(['', '']);
        setGroundData(constructObj(groundList, ''));
        setSurfaceData(constructObj(surfaceList, ''));
        setGroundErrors(constructObj(groundList, false));
        setSurfaceErrors(constructObj(surfaceList, false));
        setCompError(false);
        setNameError(false);
        setFormError(false);
        setLngLatError(false);
        setSelectedCompany(null);
      }
  
      setShowResults(results);
    }

    setLoading(false);
  };


  const addSWF = () => {
    setSurfaceData(prev => {
      return {
        ...prev,
        swfs: [ ...prev.swfs, { id: '', name: '', defaultTUS: '' }]
      };
    });

    setSurfaceErrors(prev => {
      return {
        ...prev,
        swfs: [ ...prev.swfs, { id: false, name: false, defaultTUS: false }]
      };
    });
  };

  
  const removeSWF = (index) => {
    setSurfaceData(prev => {
      return {
        ...prev,
        swfs: prev.swfs.filter((x, i) => i !== index)
      };
    });

    setSurfaceErrors(prev => {
      return {
        ...prev,
        swfs: prev.swfs.filter((x, i) => i !== index)
      };
    });
  };


  return (
    <>
      <FormGroup className={classes.container}>
        <FormGroup>
          <Typography variant='sectionTitle' sx={{marginTop: 0}}>Well or Treatment Plant Basic Information</Typography>
          <FormGroup className={classes.gap}>
            <TextField
              className={classes.input}
              size='small'
              variant='outlined'
              label='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
              helperText={nameError && 'Name is required'}
            />

            <FormGroup row={true} className={classes.spread}>
              <TextField
                className={classes.lnglat}
                type='number'
                size='small'
                variant='outlined'
                label='Latitude'
                InputProps={{
                  inputProps: {
                    min: 40.9,
                    max: 42.1,
                    step: 0.01
                  }
                }}
                value={lngLat[1]}
                onChange={(e) => setLngLat(prev => [ prev[0], parseFloat(e.target.value) ])}
                error={lngLatError}
                helperText={lngLatError && 'Required and must be between 40.9 and 42.1'}
              />

              <TextField
                className={classes.lnglat}
                type='number'
                size='small'
                variant='outlined'
                label='Longitude'
                value={lngLat[0]}
                InputProps={{
                  inputProps: {
                    min: -73.8,
                    max: -71.7,
                    step: 0.01
                  }
                }}
                onChange={(e) => setLngLat(prev => [ parseFloat(e.target.value), prev[1] ])}
                error={lngLatError}
                helperText={lngLatError && 'Required and must be between -73.8 and -71.7'}
              />
            </FormGroup>

            <TextField
              select
              className={classes.input}
              size='small'
              variant='outlined'
              label='Form Type'
              value={form}
              onChange={(e) => setForm(e.target.value)}
              error={formError}
              helperText={formError && 'Form type is required'}
            >
              <MenuItem value='ground'>Ground Water Form</MenuItem>
              <MenuItem value='surface'>Surface Water Form</MenuItem>
            </TextField>
          </FormGroup>
        </FormGroup>

        {form === 'ground' && 
          <FormGroup>
            <Typography variant='sectionTitle'>Ground Water Form Information</Typography>
            <FormGroup className={classes.gap}>
              <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Well ID'
                value={groundData.wellID}
                onChange={(e) => setGroundData(prev => { return { ...prev, wellID: e.target.value }; })}
                error={!!groundErrors.wellID}
                helperText={groundErrors.wellID}
              />
              {/* <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Population Served'
                type='number'
                inputProps={{min: 0}}
                value={groundData.populationServed}
                onChange={(e) => setGroundData(prev => { return { ...prev, populationServed: e.target.value }; })}
                error={!!groundErrors.populationServed}
                helperText={groundErrors.populationServed}
              /> */}
            </FormGroup>
          </FormGroup>
        }

        {form === 'surface' &&
          <FormGroup>
            <Typography variant='sectionTitle'>Surface Water Form Information</Typography>
            <FormGroup className={classes.gap}>
              <TextField
                className={classes.input}
                size='small'
                variant='outlined'
                label='Treatment WSFID'
                value={surfaceData.twsfID}
                onChange={(e) => setSurfaceData(prev => { return { ...prev, twsfID: e.target.value }; })}
                error={!!surfaceErrors.twsfID}
                helperText={surfaceErrors.twsfID}
              />
            </FormGroup>

            <Typography variant='sectionTitle'>Source Water Facilities</Typography>
            {surfaceData.swfs.map((swf, i) => {
              return (
                <Fragment key={i}>
                  <div className={classes.swf} key={i}>
                    <div className={classes.iconBtnCont}>
                      <IconButton className={classes.iconBtn} onClick={() => removeSWF(i)}><HighlightOffIcon className={classes.icon} /></IconButton>
                    </div>
                  
                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='SWF Name'
                      value={swf.name}
                      onChange={(e) => setSurfaceData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].name = e.target.value;
                        return newDataObj;
                      })}
                      error={!!surfaceErrors.swfs[i].name}
                      helperText={surfaceErrors.swfs[i].name}
                    />
  
                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='SWF ID'
                      value={swf.id}
                      onChange={(e) => setSurfaceData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].id = e.target.value;
                        return newDataObj;
                      })}
                      error={!!surfaceErrors.swfs[i].id}
                      helperText={surfaceErrors.swfs[i].id}
                    />

                    <TextField
                      className={classes.input}
                      size='small'
                      variant='outlined'
                      label='Default Total Usable Storage'
                      type='number'
                      inputProps={{min: 0}}
                      value={swf.defaultTUS}
                      onChange={(e) => setSurfaceData(prev => { 
                        let newDataObj = { ...prev };
                        newDataObj.swfs[i].defaultTUS = e.target.value;
                        return newDataObj;
                      })}
                      error={!!surfaceErrors.swfs[i].defaultTUS}
                      helperText={surfaceErrors.swfs[i].defaultTUS}
                    />

                  </div>
                  <div className={classes.hr}></div>
                </Fragment>
              );
            })
            }

            <Button
              className={classes.btn}
              onClick={addSWF}
            >
              {surfaceData.swfs.length > 0 ? 'Add Another Source Water Facility' : 'Add A Source Water Facility'}
            </Button>
          </FormGroup>
        }
        
        <Typography variant='sectionTitle'>Water Systems With Access to New Well or Treatment Plant</Typography>
        <Autocomplete
          fullWidth
          size='small'
          options={companies}
          value={selectedCompany}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Water System'
              error={compError}
              helperText={compError && 'Water system is required'}
            />
          )}
          onChange={(event, newValue) => setSelectedCompany(newValue)}
          getOptionLabel={option => option[0] || ''}
          sx={{width: '95%', margin: '0 auto'}}
        />
      </FormGroup>

      <SubmitBtn btnText={loading ? <CircularProgress size={20} /> : 'Create Site'} submitFunc={handleSubmit} />
    </>
  );
}

NewSite.propTypes = {
  domain: PropTypes.string,
  setShowResults: PropTypes.func,
  companies: PropTypes.array,
  setData: PropTypes.func
};