import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  IconButton,
  MenuItem
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import WeekReading from './WeekReading';



export default function WeekMeasAndRead({ classes, weeksData, handleChange, submitAttempted, missingMondays, readOnly }) {
  const formatDate = (dateStr) => {
    let pieces = dateStr.split('-');
    return `${pieces[1]}/${pieces[2]}/${pieces[0]}`;
  };

  
  return (
    <>
      {
        weeksData.map((weekObj, i) => {
          return (
            <div key={i}>
              <div className={classes.weekHeading}>
                {weeksData.length > 1 &&
                  <div className={classes.iconBtnCont}>
                    <IconButton className={classes.iconBtn} onClick={() => handleChange('removeWeek', i, 'week')}><HighlightOffIcon className={classes.icon} /></IconButton>
                  </div>
                }
                
                {readOnly ? 
                  <TextField
                    className={classes.weekSelect}
                    size='small'
                    variant='outlined'
                    label='Week Date'
                    helperText='Monday of the week of observation'
                    value={formatDate(weekObj.weekMonday)}
                    disabled
                  />
                  :
                  <TextField
                    className={classes.weekSelect}
                    size='small'
                    variant='outlined'
                    label='Week Date'
                    helperText='Monday of the week of observation'
                    select
                    value={weekObj.weekMonday}
                    onChange={(e) => handleChange(e.target.value, i, 'weekMonday')}
                    error={submitAttempted && weekObj.weekMonday === ''}
                  >
                    {
                      missingMondays.map((date, i) => <MenuItem key={i} value={date}>{formatDate(date)}</MenuItem>)
                    }
                  </TextField>
                }
              </div>

              <WeekReading
                submitAttempted={submitAttempted}
                weekNum={i}
                handleChange={handleChange}
                data={weekObj}
                mondaySelected={weekObj.weekMonday}
              />

              <div className={classes.hr}></div>
            </div>
          );
        })
      }

      {weeksData.length < missingMondays.length &&
        <Button className={classes.btn} onClick={() => handleChange('addWeek', 0, 'week')}>Add A Week</Button>
      }

      <div className={classes.sectionCont}>
        
      </div>

      
    </>
  );
}

WeekMeasAndRead.propTypes = {
  classes: PropTypes.object,
  swlElevDelta: PropTypes.string,
  pwlElevDelta: PropTypes.string,
  weeksData: PropTypes.array,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  submitAttempted: PropTypes.bool,
  missingMondays: PropTypes.array,
};