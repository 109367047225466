import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  parseISO,
  formatISO,
  previousMonday,
  nextMonday,
  isBefore
} from 'date-fns';

import { customFetch } from '../../../Helpers';
import { Section } from '../../index';
import RadioOpts from './RadioOpts';
import SubmitBtn from '../../SubmitBtn';

import {
  TextField,
  FormGroup,
  Typography,
  Autocomplete
} from '@mui/material';


// API request function
const requestCsvData = async (accessToken, domain, type, ids, dates) => {
  return await customFetch(`//${domain}/admin/downloadData`,{
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      type,
      ids,
      dates
    })
  });
};



export default function Download({ domain, companies, siteList, setShowResults }) {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [dateProblem, setDateProblem] = useState(false);
  const [formType, setFormType] = useState('');
  const [idType, setIdType] = useState('');
  const [selectable, setSelectable] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  
  const { getAccessTokenSilently } = useAuth0();


  // Checks for valid date range
  useEffect(() => {
    if (start !== '' && end !== '') {
      if (isBefore(parseISO(end), parseISO(start))) {
        setDateProblem(true);
      } else {
        setDateProblem(false);
      }
    }
  }, [start, end]);


  // Checks that form is complete, submits request, and shows results in popup or downloads file
  const handleDownload = async () => {
    let startDate = parseISO(start);
    let endDate = parseISO(end);
    if (start === '' || end === '' || formType === '' || idType === '' || dateProblem) {
      setShowErrors(true);
    } else {
      let type;
      if (formType === 'Ground Sites Only') {
        type = 'ground';
      } else if (formType === 'Surface Sites Only') {
        type = 'surface';
      } else {
        type = 'both';
      }

      // Get all mondays between start date and end date
      let dates = [];
      let currDate = nextMonday(previousMonday(startDate));
      while (!isBefore(endDate, currDate)) {
        dates.push(formatISO(currDate, {representation: 'date'}));
        currDate = nextMonday(currDate);
      }
      
      const accessToken = await getAccessTokenSilently();
      let results = await requestCsvData(accessToken, domain, type, selected.map(arr => arr[1]), dates);

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestCsvData(accessToken, domain, type, selected.map(arr => arr[1]), dates);
      }
      
      if (results.code !== 200) {
        setShowResults(results);
      } else {
        if (!results.res) {
          setShowResults({
            code: 'No Data',
            msg: 'No data could be found to match your selections.'
          });
        } else {
          let zip = new JSZip();
          results.res.forEach(arr => zip.file(...arr));
          zip.generateAsync({type:'blob'})
            .then(function (blob) {
              saveAs(blob, 'output.zip');
            });
        }
      }
    }
  };


  const getNewIdList = (letter) => {
    let idList;
    if (letter === 'a') {
      idList = siteList;
    } else if (letter === 'w') {
      idList = siteList.filter(id => id[1].split('')[0] === 'g');
    } else {
      idList = siteList.filter(id => id[1].split('')[0] === 's');
    }

    return idList;
  };


  // Handles change between ground, surface, or all
  const handleFormTypeChange = (newValue) => {
    if (idType !== '') {
      const letter = newValue.split('')[0].toLowerCase();
      const idList = getNewIdList(letter);

      if (idType === 'Facility') {
        setSelectable(idList);

        if (letter !== 'a') {
          setSelected(selected.filter(id => id[1].split('')[0] === letter));
        }
      } else if (idType === 'All') {
        setSelected(idList);
      }
    }

    setFormType(newValue);
  };


  // Handles change between water systems, facilities, or all
  const handleIdTypeChange = (newValue) => {
    if (formType !== '') {
      const letter = formType.split('')[0].toLowerCase();
      const idList = getNewIdList(letter);

      if (newValue === 'All') {
        setSelected(idList);
      } else {
        setSelected([]);
        
        if (newValue === 'Water System') {
          setSelectable(companies);
        } else {
          setSelectable(idList);
        }
      }
    }
    
    setIdType(newValue);
  };
  
  
  return (
    <div style={{width: 600, margin: '0 auto'}}>
      <Section titleText='Download Data' width='100%' marginTop='0px'>
        <>
          <FormGroup>
            <Typography variant='sectionTitle' sx={{marginTop: 0}}>Submission Date Range</Typography>
            <FormGroup row={true} sx={{width: '100%', justifyContent: 'space-evenly'}}>
              <TextField
                type='date'
                size='small'
                variant='outlined'
                label='Start Date'
                value={start}
                onChange={(e) => setStart(e.target.value)}
                error={(showErrors && start === '') || dateProblem}
                helperText={(showErrors && start === '' && '*Required') || (dateProblem && 'Must be before end date')}
                InputLabelProps={{shrink: true}}
                InputProps={{inputProps: { min: '2021-01-01', max: formatISO(new Date(), {representation: 'date'}) }}}
                sx={{width: 255}}
              />
              <TextField
                type='date'
                size='small'
                variant='outlined'
                label='End Date'
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                error={(showErrors && end === '') || dateProblem}
                helperText={(showErrors && end === '' && '*Required') || (dateProblem && 'Must be after start date')}
                InputLabelProps={{shrink: true}}
                InputProps={{inputProps: { min: '2021-01-01', max: formatISO(new Date(), {representation: 'date'}) }}}
                sx={{width: 255}}
              />
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Options</Typography>
            <FormGroup row={true} sx={{width: '100%', justifyContent: 'space-evenly'}}>
              <RadioOpts
                label='Type of Data'
                options={['Wells Only', 'Treatment Plants Only', 'All Facilities']}
                value={formType}
                setValue={handleFormTypeChange}
                error={showErrors && formType === ''}
              />

              <RadioOpts
                label='Select Data By'
                options={['Water System', 'Facility', 'All']}
                value={idType}
                setValue={handleIdTypeChange}
                error={showErrors && idType === ''}
              />
            </FormGroup>
          </FormGroup>

          {formType !== '' && idType !== '' && idType !== 'All' &&
            <FormGroup>
              <Typography variant='sectionTitle' sx={{marginTop: '10px'}}>Selection</Typography>
              <Autocomplete
                multiple
                size='small'
                options={selectable}
                value={selected}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={idType}
                  />
                )}
                onChange={(e, value) => setSelected(value)}
                getOptionLabel={opt => opt[0]}
                isOptionEqualToValue={(option, value) => (option[0] === value[0] && option[1] === value[1])}
                sx={{width: '95%', margin: '0 auto'}}
              />
            </FormGroup>
          }
          
          <SubmitBtn btnText='Download Data' submitFunc={handleDownload} sx={{marginTop: '20px'}} />
        </>
      </Section>
    </div>
  );
}

Download.propTypes = {
  domain: PropTypes.string,
  companies: PropTypes.array,
  siteList: PropTypes.array,
  setShowResults: PropTypes.func
};