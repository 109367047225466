import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';

import NewUser from './NewUser';
import NewCompany from './NewCompany';
import NewSite from './NewSite';
import ResultPopup from '../../ResultPopup';
import TabPanel from '../../TabPanel';


const useStyles = makeStyles(() => ({
  formCont: {
    minWidth: 360,
    width: '94%',
    maxWidth: 600,
    margin: '0 auto',
  },
  tabsCont: {
    backgroundColor: 'rgb(84,141,212)',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  tabs: {
    backgroundColor: 'rgb(84,141,212)',
    minHeight: '40px !important'
  },
  tab: {
    color: 'white !important',
    padding: '4px 8px !important',
    minHeight: '40px !important',
    '&:hover': {
      backgroundColor: 'rgb(54,111,182)'
    }
  },
  divider: {
    position: 'absolute',
    height: 40,
    width: 1,
    backgroundColor: 'rgba(210,210,210, 0.5)',
  }
}));



// Container component for admin accounts to create new users, companies, and sites
export default function Create({ domain, companies, relations, siteList, setData }) {
  const [tab, setTab] = useState(0);
  const [showResults, setShowResults] = useState({ code: null, msg: null });
  const [showMsg, setShowMsg] = useState(false);
  
  const classes = useStyles();

  // Handles showing and hiding the popup
  useEffect(() => {
    if (showResults.code !== null && showResults.msg !== null) {
      setShowMsg(true);

      const timeId = setTimeout(() => {
        setShowMsg(false);
      }, 6000);
  
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showResults]);


  const handleTabChange = (e, val) => {
    setTab(val);
  };


  return (
    <div>
      <div className={classes.formCont}>
        <div className={classes.tabsCont}>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: {
              backgroundColor: 'rgb(242,208,59)'
            } }}
          >
            <Tab className={classes.tab} label="User" />
            <Tab className={classes.tab} label="Water System" />
            <Tab className={classes.tab} label="Site" />
          </Tabs>
          <div className={classes.divider} style={{left: '90px'}}></div>
          <div className={classes.divider} style={{left: '207px'}}></div>
        </div>
        <TabPanel value={tab} index={0}>
          <NewUser
            domain={domain}
            companies={companies}
            relations={relations}
            setShowResults={setShowResults}
            setData={setData}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <NewCompany
            domain={domain}
            setShowResults={setShowResults}
            siteList={siteList}
            setData={setData}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <NewSite
            domain={domain}
            setShowResults={setShowResults}
            companies={companies}
            setData={setData}
          />
        </TabPanel>
      </div>

      {
        showMsg && <ResultPopup
          code={showResults.code}
          msg={showResults.res}
          type={tab === 0 ? 'user' : tab === 1 ? 'company' : 'site'}
          action='created'
        />
      }
    </div>
  );
}

Create.propTypes = {
  domain: PropTypes.string,
  companies: PropTypes.array,
  siteList: PropTypes.array,
  relations: PropTypes.object,
  setData: PropTypes.func
};