export default function updateObject(object, path, value){
  var stack = path.split('.');
  while(stack.length>1){
    let val = stack.shift();
    if (isNaN(parseInt(val))) {
      object = object[val];
    } else {
      object = object[parseInt(val)];
    }
  }

  let val = stack.shift();
  if (isNaN(parseInt(val))) {
    object[val] = value;
  } else {
    if (value === 'delete') {
      object.splice(val, 1);
    } else {
      object[parseInt(val)] = value;
    }
  }
}