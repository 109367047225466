import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';

import {
  MenuItem,
  ListSubheader,
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { customFetch } from '../../../Helpers/index';

import Pictures from '../../Pictures';
import Section from '../../Section';
import { GroundWaterForm, SurfaceWaterForm } from '../../index';

const months = [
  ['12', 'December'],
  ['11', 'November'],
  ['10', 'October'],
  ['09', 'September'],
  ['08', 'August'],
  ['07', 'July'],
  ['06', 'June'],
  ['05', 'May'],
  ['04', 'April'],
  ['03', 'March'],
  ['02', 'February'],
  ['01', 'January'],
];

function ordinal(n) {
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const useStyles = makeStyles((theme) => ({
  formSeparator: {
    borderBottom: `3px dotted ${theme.palette.primary.main}`,
    width: '94%',
    height: 0,
    margin: '0 auto',
  },
  submitter: {
    textAlign: 'center',
    marginTop: 20,
  },
  noData: {
    fontStyle: 'italic',
    textAlign: 'center',
    marginTop: 20,
  },
}));

const initState = {
  year: '',
  date: '',
  data: {},
  siteYears: [],
  siteData: {},
  siteMetaData: false,
};

// Form allowing users to edit existing data
export default function EditData({
  domain,
  sites,
  company,
  companyMeta,
  setShowResults,
}) {
  const [selected, setSelected] = useState(initState);
  const [site, setSite] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  useEffect(() => {
    setSite(null);
    handleChangeSite(null);
  }, [company]);

  // API request function
  const getSiteData = async (domain, id, type, year = false) => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/getData`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        type,
        id,
        year,
      }),
    });
  };

  // Handles updating data when site is changed
  const handleChangeSite = async (newSite) => {
    if (newSite) {
      const results = await getSiteData(
        domain,
        `${company}.${newSite[1]}`,
        'years'
      );

      setSelected({
        year: results.res.years.length > 0 ? results.res.years[0] : '',
        date: results.res.date,
        data: results.res.dayData,
        siteYears: results.res.years,
        siteData: results.res.yearData,
        siteMetaData: results.res.siteMeta,
      });
    } else {
      setSelected({ ...initState });
    }

    setSite(newSite);
  };

  // Handles updating data when year is changed
  const handleChangeYear = async (newYear) => {
    const results = await getSiteData(
      domain,
      `${company}.${site[1]}`,
      'site',
      newYear
    );

    setSelected((prev) => {
      return {
        year: newYear,
        date: results.res.date,
        data: results.res.dayData,
        siteYears: prev.siteYears,
        siteData: results.res.yearData,
        siteMetaData: prev.siteMetaData,
      };
    });
  };

  // Handles updating data when date is changed
  const handleChangeDate = (newDate) => {
    let md = newDate.split('.');

    setSelected((prev) => {
      return {
        ...prev,
        date: newDate,
        data: prev.siteData[md[0]][md[1]],
      };
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '75%' }}>
        <Section width='600px' marginTop='0px' titleText='Select Data to Edit'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Autocomplete
              options={sites}
              getOptionLabel={(option) =>
                option.length > 0 ? option[0] : null
              }
              value={site}
              onChange={(e, val) => handleChangeSite(val)}
              sx={{
                minWidth: 250,
                margin: '10px',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Site'
                  variant='outlined'
                  size='small'
                />
              )}
            />

            <TextField
              size='small'
              variant='outlined'
              value={selected.year}
              onChange={(e) => handleChangeYear(e.target.value)}
              label='Year'
              disabled={!site}
              select
              sx={{
                minWidth: 80,
                margin: '10px',
              }}
            >
              {selected.siteYears.map((year) => (
                <MenuItem key={uuidv4()} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              size='small'
              variant='outlined'
              value={selected.date}
              onChange={(e) => handleChangeDate(e.target.value)}
              label='Day'
              disabled={!site || selected.year === ''}
              select
              sx={{
                minWidth: 165,
                margin: '10px',
              }}
            >
              {selected.year === ''
                ? []
                : months.reduce((acc, monthArr) => {
                    if (
                      Object.keys(selected.siteData).includes(monthArr[0]) &&
                      Object.keys(selected.siteData[monthArr[0]]).length > 0
                    ) {
                      acc.push(
                        <ListSubheader key={uuidv4()}>
                          {monthArr[1]}
                        </ListSubheader>
                      );
                      Object.keys(selected.siteData[monthArr[0]])
                        .sort((a, b) => b - a)
                        .forEach((day) =>
                          acc.push(
                            <MenuItem
                              key={uuidv4()}
                              value={`${monthArr[0]}.${day}`}
                            >
                              {monthArr[1]} {ordinal(day)}
                            </MenuItem>
                          )
                        );
                    }
                    return acc;
                  }, [])}
            </TextField>
          </div>
        </Section>
        {site !== null && <div className={classes.formSeparator}></div>}
        {site !== null &&
          (Object.keys(selected.data).length > 0 ? (
            <>
              <div className={classes.submitter}>
                <Typography variant='subtitle2'>
                  Submitted By: {selected.data.submitter}
                </Typography>
              </div>
              {selected.siteMetaData.form === 'ground' && (
                <GroundWaterForm
                  editData={selected.data}
                  prefill={{
                    company: companyMeta,
                    site: selected.siteMetaData,
                  }}
                  domain={domain}
                  setShowResults={setShowResults}
                  siteAlias={site && site[1]}
                  setSite={setSite}
                />
              )}
              {selected.siteMetaData.form === 'surface' && (
                <SurfaceWaterForm
                  editData={selected.data}
                  prefill={{
                    company: companyMeta,
                    site: {
                      ...selected.siteMetaData,
                      twfName: site ? site[0] : '',
                    },
                  }}
                  setShowResults={setShowResults}
                  domain={domain}
                  siteAlias={site && site[1]}
                  setSite={setSite}
                />
              )}
            </>
          ) : (
            <div className={classes.noData}>No Data</div>
          ))}
      </div>

      <Pictures
        width='20%'
        maxWidth={400}
        height={site ? 2800 : 700}
        rows={site ? 12 : 3}
      />
    </div>
  );
}

EditData.propTypes = {
  domain: PropTypes.string,
  sites: PropTypes.array,
  company: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyMeta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setShowResults: PropTypes.func,
};
