import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { hsls } from '../../Helpers';

const VertNumberCont = styled(Box)({
  display:'flex',
  justifyContent: 'space-between',
  width: 'fit-content',
  gap: '3px'
});
const HorizNumberCont = styled(VertNumberCont)({
  flexDirection: 'column'
});

const VertTick = styled(Box)({ height: '1px', width: '3px', backgroundColor: 'black' });
const HorizTick = styled(VertTick)({ height: '3px', width: '1px' });



export default function Legend({ max, sx, barHeight, barWidth }) {
  const isVertical = barHeight > barWidth;

  const textSX = isVertical ? {
    height: barHeight + 11,
    flexDirection: 'column',
    top: -6,
    left: 5
  } : {
    width: barWidth,
  };

  const tickSX = isVertical ? {
    height: barHeight + 2,
    width: 0,
    flexDirection: 'column'
  } : {
    height: '100%',
    width: barWidth + 2,
    flexDirection: 'row'
  };

  return (
    <Box sx={sx}>
      <Box sx={{
        height: barHeight,
        width: barWidth,
        position: 'relative',
        border: '1px solid black',
        backgroundImage: `linear-gradient(${isVertical ? 0 : 90}deg,
          hsl(${hsls[0][0]},${hsls[0][1]}%,${hsls[0][2]}%) 0%,
          hsl(${hsls[1][0]},${hsls[1][1]}%,${hsls[1][2]}%) 33%,
          hsl(${hsls[2][0]},${hsls[2][1]}%,${hsls[2][2]}%) 50%,
          hsl(${hsls[3][0]},${hsls[3][1]}%,${hsls[3][2]}%) 67%,
          hsl(${hsls[4][0]},${hsls[4][1]}%,${hsls[4][2]}%) 100%)`
      }} />

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        ...tickSX
      }}>
        {[0,1,2].map((val) => {
          let alignment;
          if (val === 0) {
            alignment = 'flex-start';
          } else if (val === 1) {
            alignment = 'center';
          } else {
            alignment = 'flex-end';
          }
          
          if (isVertical) {
            return (
              <VertNumberCont key={val}>
                <VertTick />
              </VertNumberCont>
            );
          } else {
            return (
              <HorizNumberCont key={val} sx={{ alignSelf: alignment }}>
                <HorizTick />
              </HorizNumberCont>
            );
          }
        })}
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        ...textSX
      }}>
        {[max,0,-max].map((value, i) => {
          value = i === 1 ? '0 in.' : String(isVertical ? value : -value);

          let pos = 0;
          if (i === 0) {
            pos = '-12px';
          } else if (i === 2) {
            pos = '12px';
          }
            
          if (isVertical) {
            return (
              <VertNumberCont key={i}>
                <Typography variant='legend'>{value}</Typography>
              </VertNumberCont>
            );
          } else {
            return (
              <HorizNumberCont key={i}>
                <Typography variant='legend' sx={{ position: 'relative', left: pos }}>{value}</Typography>
              </HorizNumberCont>
            );
          }
        })}
      </Box>
    </Box>
  );
}


Legend.propTypes = {
  sx: PropTypes.object,
  max: PropTypes.number,
  barHeight: PropTypes.number,
  barWidth: PropTypes.number
};