import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import LoginButton from './Buttons/LoginButton';
import LogoutButton from './Buttons/LogOutButton';



export default function AuthBtnGroup() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? 
    <LogoutButton />
    : 
    <LoginButton/>
  ;
}