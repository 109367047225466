const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

const generatePassword = () => {
  let length = 10,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    nums = '0123456789',
    special = '!@#$%&*?',
    upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lower = 'abcdefghijklmnopqrstuvwxyz',
    strVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    strVal += charset.charAt(Math.floor(Math.random() * n));
  }

  const numReg = /[0-9]/;
  const lowReg = /[a-z]/;
  const upReg = /[A-Z]/;
  const specialReg = /[!@#$%&*?]/;
  
  let retVal = strVal.split('');
  let usedIndices = [12];
  if (!numReg.test(strVal)) {
    let i = 12;
    while (usedIndices.includes(i)) {
      i = getRandomInt(8);
    }

    usedIndices.push(i);
    retVal[i] = nums[getRandomInt(10)];
  }

  if (!lowReg.test(strVal)) {
    let i = 12;
    while (usedIndices.includes(i)) {
      i = getRandomInt(8);
    }
    
    usedIndices.push(i);
    retVal[i] = lower[getRandomInt(26)];
  }

  if (!upReg.test(strVal)) {
    let i = 12;
    while (usedIndices.includes(i)) {
      i = getRandomInt(8);
    }
    
    usedIndices.push(i);
    retVal[i] = upper[getRandomInt(26)];
  }

  if (!specialReg.test(strVal)) {
    let i = 12;
    while (usedIndices.includes(i)) {
      i = getRandomInt(8);
    }
    
    usedIndices.push(i);
    retVal[i] = special[getRandomInt(8)];
  }

  return retVal.join('');
};

export default generatePassword;