import React from 'react';
import { Box } from '@mui/material';

import Section from './Section';



// Drought Map URLs
const neMap = 'https://droughtmonitor.unl.edu/data/png/current/current_rdews_northeast_text.png';
const ctMap = 'https://droughtmonitor.unl.edu/data/png/current/current_ct_text.png';

// Drought Map Links
const neLink = 'https://droughtmonitor.unl.edu/CurrentMap/StateDroughtMonitor.aspx?Northeast';
const ctLink = 'https://droughtmonitor.unl.edu/CurrentMap/StateDroughtMonitor.aspx?CT';

// Drought Map Alt text
const neText = 'Drought status map of the Northeastern United States from U.S. Drought Monitor.';
const ctText = 'Drought status map of Connecticut from U.S. Drought Monitor.';



export default function DroughtStatus() {
  const renderImage = (source, link, text) => {
    return (
      <Box sx={{
        width: '45%'
      }}>
        <a href={link} target='_blank' rel='noreferrer'>
          <img
            style={{
              width: '100%',
              border: '1px solid black'
            }}
            src={source}
            alt={text}
          />
        </a>
      </Box>
    );
  };
  
  return (
    <Section titleText='Drought Status (updated weekly)' width='96%' marginTop='0px' marginBottom='0px'>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '100%',
        margin: '0 auto'
      }}>
        {renderImage(neMap, neLink, neText)}
        {renderImage(ctMap, ctLink, ctText)}
      </Box>
    </Section>
  );
}