import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function Popup({ children, sx, setOpen }) {
  const handleClick = () => {
    setOpen(false);
  };

  const stopClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        top: '-100%',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 5
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          padding: '23px',
          borderRadius: '4px',
          border: '1px solid rgb(180,180,180)',
          boxShadow: '3px 3px 5px 2px rgba(0,0,0,0.4)',
          ...sx
        }}
        onClick={stopClick}
      >
        <IconButton
          size='small'
          onClick={handleClick}
          aria-label='close popup'
          sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
        {children}
      </Box>
    </Box>
  );
}

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  sx: PropTypes.object,
  setOpen: PropTypes.func
};