import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormGroup,
  TextField,
  Autocomplete,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { customFetch } from '../../../Helpers/index';

import SubmitBtn from '../../SubmitBtn';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginBottom: 20
  }
}));



// Form allowing admin accounts to create new companies
export default function NewCompany({ domain, setShowResults, siteList, setData }) {
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    name: '',
    pwsID: '',
    sites: []
  });
  const [errors, setErrors] = useState({
    name: false,
    pwsID: false,
  });
  
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();


  // API request function
  const requestCreateCompany = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/admin/create`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ...meta,
        type: 'company'
      })
    });
  };
  
  
  // Checks that form is complete, submits request, and shows results in popup
  const handleSubmit = async () => {
    setLoading(true);

    let newErrors = {
      name: false,
      pwsID: false,
    };
    
    if (meta.name === '') {
      newErrors.name = 'Name is required';
    }

    if (meta.pwsID === '') {
      newErrors.pwsID = 'PWS ID is required';
    }

    if (Object.keys(newErrors).every(k => !newErrors[k])) {
      let results = await requestCreateCompany();
  
      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestCreateCompany();
      }
  
      // On success, clear form
      if (results.code === 200) {
        setData(prev => {
          return {
            ...prev,
            companies: [...prev.companies, [meta.name, results.res.alias]].sort((a,b) => a[0] > b[0]),
            relations: {
              ...prev.relations,
              [results.res.alias]: meta.sites
            }
          };
        });
        setMeta({
          name: '',
          pwsID: '',
          sites: []
        });
        setErrors({
          name: false,
          pwsID: false,
        });
      }
  
      setShowResults(results);
    } else {
      setErrors(newErrors);
    }

    setLoading(false);
  };


  return (
    <>
      <FormGroup className={classes.container}>
        <Typography variant='sectionTitle' sx={{marginTop: 0}}>New Water System Basic Information</Typography>
        <FormGroup sx={{width: '95%', margin: '0 auto', gap: '30px'}}>
          <TextField
            size='small'
            variant='outlined'
            label='Name'
            value={meta.name}
            onChange={(e) => setMeta(prev => { return { ...prev, name: e.target.value }; })}
            error={errors.name && meta.name === ''}
            helperText={errors.name && meta.name === ''}
          />
          <TextField
            size='small'
            variant='outlined'
            label='PWS ID'
            value={meta.pwsID}
            onChange={(e) => setMeta(prev => { return { ...prev, pwsID: e.target.value }; })}
            error={errors.pwsID && meta.pwsID === ''}
            helperText={errors.pwsID && meta.pwsID === ''}
          />
        </FormGroup>

        <Typography variant='sectionTitle'>Accessible Wells and Treatment Plants</Typography>
        <Autocomplete
          multiple
          size='small'
          options={siteList}
          value={meta.sites}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Wells and Treatment Plants'
            />
          )}
          onChange={(e, value) => setMeta(prev => { return { ...prev, sites: value }; })}
          getOptionLabel={opt => opt[0]}
          isOptionEqualToValue={(option, value) => (option[0] === value[0] && option[1] === value[1])}
          sx={{width: '95%', margin: '0 auto'}}
          renderOption={(props, option) => {
            return <li {...props} key={option[1]} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>{option[0]}</div>
              <div style={{fontStyle: 'italic', color: 'rgb(150,150,150)'}}>{option[2]}</div>
            </li>;
          }}
        />
      </FormGroup>

      <SubmitBtn btnText={loading ? <CircularProgress size={20} /> : 'Create Company'} submitFunc={handleSubmit} />
    </>
  );
}

NewCompany.propTypes = {
  domain: PropTypes.string,
  setShowResults: PropTypes.func,
  siteList: PropTypes.array,
  setData: PropTypes.func
};