/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl';
mapboxgl.accessToken =
  'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2txYjNjMHYxMGF4NTJ1cWhibHNub3BrdiJ9.1T_U5frbnHaHonvFpHenxQ';

import { cityLngLats, customFetch, pwsInCity } from '../../Helpers';
import Loading from '../LoadingComp/Loading';

import Legend from './Legend';
import Popup from './Popup';
import LabelledChart from './LabelledChart';

const InfoBarLine = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
});

const requestPublicData = async (domain) => {
  return await customFetch(`//${domain}/openView`, { method: 'GET' });
};

export default function Map({ domain, setLoading }) {
  const [thisLoading, setThisLoading] = useState(true);
  const [chartsData, setChartsData] = useState(false);
  const [colors, setColors] = useState(false);
  const [diffs, setDiffs] = useState(false);
  const [barInfo, setBarInfo] = useState({ city: '', pwss: [] });
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    // Get and save precip and historical chart data
    (async () => {
      const resObj = await requestPublicData(domain);
      setColors(resObj.res.colors);
      setDiffs(resObj.res.diffs);
      setChartsData(resObj.res.chartsData);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (map.current) return; // initialize map only once
      if (mapContainer.current && colors && diffs && chartsData) {
        // Create map
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/precipadmin/cle8wscc9001s01p2j41yejix',
          center: [-72.67, 41.51],
          zoom: 7.6,
          boxZoom: false,
          dragRotate: false,
          dragPan: false,
          keyboard: false,
          scrollZoom: false,
          touchPitch: false,
          touchZoomRotate: false,
          doubleClickZoom: false,
          attributionControl: false,
        })
          .addControl(
            new mapboxgl.AttributionControl({
              compact: true,
            }),
            'bottom-right'
          )
          .on('load', () => {
            // Zoom to CT
            map.current.fitBounds(
              [
                [-73.731995, 40.94049],
                [-71.779175, 42.05745],
              ],
              { padding: { top: 30 } }
            );

            // If layers have not been added, add them
            if (
              !map.current
                .getStyle()
                .layers.find((layer) => layer.id === 'city-shape')
            ) {
              map.current.addSource('cities', {
                type: 'vector',
                url: 'mapbox://precipadmin.3lgblhgn',
              });
              map.current.addLayer(
                {
                  id: 'city-shape',
                  type: 'fill',
                  source: 'cities',
                  'source-layer': 'Connecticut_and_Vicinity_Town-5xiwnb',
                  paint: {
                    'fill-color': colors,
                    'fill-outline-color': 'rgb(150,150,150)',
                  },
                },
                'water-point-label'
              );
              map.current.addLayer(
                {
                  id: 'city-line',
                  type: 'line',
                  source: 'cities',
                  'source-layer': 'Connecticut_and_Vicinity_Town-5xiwnb',
                  paint: {
                    'line-width': 2,
                    'line-color': 'rgb(90,90,90)',
                  },
                  filter: [
                    'in',
                    ['get', 'TOWN_NAME'],
                    ['literal', Object.keys(pwsInCity)],
                  ],
                },
                'water-point-label'
              );
            }
          });

        // Display historical chart on click
        map.current.on('click', 'city-shape', async (e) => {
          let city = e.features[0].properties.TOWN_NAME;
          let pwss = pwsInCity[city];

          console.log(city, pwss);
          console.log(chartsData);
          
          if (pwss) {
            let data = pwss
            .map((pwsName) => {
              if (pwsName.includes('Bethel')) {
                pwsName = 'Bethel Water Department';
              } else if (pwsName.includes('Co of CT')) {
                pwsName = pwsName.slice(0, 18) + 'O' + pwsName.slice(19);
              }
              console.log(pwsName, pwsName in chartsData);
              return [pwsName, chartsData[pwsName]];
            }).filter((x) => x[1]);

            setPopupContent(
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent:
                    data.length === 1 ? 'center' : 'space-between',
                  gap: 2,
                }}
              >
                {data.length > 0 ? (
                  data.map((cData) => (
                    <LabelledChart key={cData[0]} data={cData} />
                  ))
                ) : (
                  <Typography>
                    There is no data for any water systems in this area yet.
                  </Typography>
                )}
              </Box>
            );
            setShowPopup(true);
          }
        });

        // Cleanup when pointer leaves map.
        map.current.on('mouseleave', 'city-shape', () => {
          setBarInfo({ city: '', pwss: [] });
        });

        // Update info bar while mousing around map
        map.current.on('mousemove', 'city-shape', (e) => {
          if (e.features.length > 0) {
            const city = e.features[0].properties.TOWN_NAME;

            setBarInfo({
              city,
              pwss: pwsInCity[city] || ['None'],
            });

            map.current.getCanvas().style.cursor =
              city in pwsInCity ? 'pointer' : '';
          }
        });

        // Turn off loading screen after initial load
        if (thisLoading) {
          setLoading(false);
          setThisLoading(false);
        }
      }
    })();
  });

  return (
    <>
      <Box
        sx={{
          width: 'calc(100vw - 60px)',
          maxWidth: 'calc(2*(100vh - 300px))',
          minWidth: 650,
          height: 'calc(100vh - 300px)',
          minHeight: 450,
          backgroundColor: 'rgb(79,88,93)',
          borderRadius: '4px',
          boxShadow: '3px 3px 5px 2px rgb(150,150,150)',
          padding: '10px',
          margin: '0 auto',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          ref={mapContainer}
          sx={{
            height: '100%',
            width: '100%',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            bottom: -24,
            left: 0,
            right: 0,
            zIndex: 5,
          }}
        >
          {barInfo.city !== '' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '4px',
                width: 'fit-content',
                margin: '0 auto',
                padding: '6px 12px',
                borderRadius: '4px',
                fontFamily: 'monospace',
                color: '#fff',
                backgroundColor: 'rgba(35, 55, 75, 0.9)',
                zIndex: 5,
              }}
            >
              <InfoBarLine>
                <span>City:</span>
                <span>{barInfo.city}</span>
              </InfoBarLine>
              <InfoBarLine>
                <span>
                  Precipitation Difference from Historical Average (1985-2015):
                </span>
                <span>{diffs[barInfo.city]} in.</span>
              </InfoBarLine>
              <InfoBarLine>
                <span>Public Water Systems:</span>
                <span>{barInfo.pwss.join(', ')}</span>
              </InfoBarLine>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: -30,
            left: 0,
            right: 0,
            zIndex: 5,
          }}
        >
          <Box
            sx={{
              margin: '12px auto 0px auto',
              width: 'fit-content',
              backgroundColor: 'rgb(249,249,249)',
              border: '1px solid rgb(180,180,180)',
              borderRadius: '4px',
              padding: '6px',
            }}
          >
            <Typography
              align='center'
              sx={{
                marginBottom: '3px',
                fontSize: '14px',
                fontWeight: 'bold',
                lineHeight: '14px',
              }}
            >
              Difference between Current and Historic (1985-2015) 30 Day
              Precipitation Totals
            </Typography>
            <Legend
              max={diffs.max}
              sx={{ width: 'fit-content', margin: '0 auto' }}
              barHeight={10}
              barWidth={300}
            />
          </Box>
        </Box>

        {showPopup && (
          <Popup
            setOpen={setShowPopup}
            sx={{
              height: 'fit-content',
              width: 'fit-content',
            }}
          >
            {popupContent}
          </Popup>
        )}
      </Box>

      {thisLoading && <Loading />}
    </>
  );
}

Map.propTypes = {
  domain: PropTypes.string,
  setLoading: PropTypes.func,
};
