import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  TextField,
  MenuItem,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import IconPopper from '../../IconPopper';

const useStyles = makeStyles(() => ({
  weekSelect: {
    maxWidth: 250
  },
  sect: {
    marginTop: 30,
    justifyContent: 'center'
  },
}));

const formatDate = (dateStr) => {
  let pieces = dateStr.split('-');
  return `${pieces[1]}/${pieces[2]}/${pieces[0]}`;
};



export default function WeekSelector({ data, readOnly, handleChange, weekMonday, submitAttempted }) {
  const classes = useStyles();

  return (
    <FormGroup row={true} className={classes.sect}>
      {readOnly ? 
        <TextField
          className={classes.weekSelect}
          size='small'
          variant='outlined'
          label='Week Of'
          helperText='Monday of the week of observation'
          value={formatDate(weekMonday)}
          disabled
        />
        :
        <TextField
          className={classes.weekSelect}
          size='small'
          variant='outlined'
          label='Week Of'
          helperText='Monday of the week of observation'
          select
          value={weekMonday}
          onChange={(e) => handleChange(e.target.value, 'weekMonday')}
          error={submitAttempted && weekMonday === ''}
        >
          {
            data.site.missingMondays.map((date, i) => <MenuItem key={i} value={date}>{formatDate(date)}</MenuItem>)
          }
        </TextField>
      }

      <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='12px'>
        <Typography variant='popupSubheading'>Week Of</Typography>
        <Typography  variant='popupBody'>Refers to the week covered by the report.</Typography>
        <Typography variant='popupBody'>The week starts on a Monday and ends on the following Sunday.</Typography>
      </IconPopper>
    </FormGroup>
  );
}

WeekSelector.propTypes = {
  data: PropTypes.object,
  weekMonday: PropTypes.string,
  handleChange: PropTypes.func, 
  readOnly: PropTypes.bool,
  submitAttempted: PropTypes.bool
};