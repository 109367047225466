import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4} from 'uuid';

import {
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  FormHelperText,
  Typography
} from '@mui/material';

export default function WellForm({ classes, data, handleChangeAll, submitAttempted }) {
  const renderWLMRefPoints = useMemo(() => {
    const cols = ['', 'Top Of Casing', 'Measuring Tube'];
    const rows = ['Above Land Surface', 'Below Land Surface', 'Land Surface'];
    
    return cols.map((colName, i) => {
      let children;
      if (i === 0) {
        children = rows.map(rowName => <FormLabel sx={{color: (submitAttempted && (data.refPointLevel === ''))? 'red !important' : 'black !important', marginTop: '20px'}} key={uuidv4()}>{rowName}</FormLabel>);
      } else {
        children = rows.map(rowName => <Checkbox key={uuidv4()} checked={rowName === data.refPointLevel && colName === data.refPointType} onChange={() => handleChangeAll({ method: data.method, refPointLevel: rowName, refPointType: colName}, 'measurement')} sx={(submitAttempted && data.refPointLevel === '') ? {color: 'red'} : {}} />);
      }

      return (
        <FormGroup className={i === 0 ? classes.labelCol : classes.checkboxCol} key={uuidv4()}>
          <Typography variant='subtitle2' align='center'>{colName}</Typography>
          {children}
        </FormGroup>
      );
    });
  }, [data, submitAttempted]);

  return (
    <FormGroup className={classes.checkboxes}>
      <FormControl error={submitAttempted && data.refPointLevel === ''}>
        <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Water Level Measurement Reference Point</Typography>
        <FormGroup row={true} sx={{marginLeft: '20px'}}>
          {renderWLMRefPoints}
        </FormGroup>
      </FormControl>
      { submitAttempted && data.refPointLevel === '' && <FormHelperText error sx={{marginLeft: 10}}>*Required</FormHelperText> }
    </FormGroup>
  );
}

WellForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  handleChangeAll: PropTypes.func,
  submitAttempted: PropTypes.bool
};