import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  TextField
} from '@mui/material';


const useStyles = makeStyles(() => ({
  group: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 740
  }
}));



export default function DroughtStatus({ data, handleChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <FormGroup row={true} className={classes.group}>
        <FormControl>
          <Typography variant='sectionTitle' sx={{marginBottom: 0}}>Drought Status</Typography>
          <RadioGroup
            value={data.level}
            onChange={(e) => handleChange(e.target.value, 'droughtStatus.level')}
            sx={{
              marginLeft: 4
            }}
          >
            <FormControlLabel value='None' control={<Radio />} label='None' />
            <FormControlLabel value='Approaching Trigger Level' control={<Radio />} label='Approaching Trigger Level' />
            <FormControlLabel value='Drought Stage' control={<Radio />} label='Drought Stage' />
          </RadioGroup>
        </FormControl>
        
        {
          data.level === 'Drought Stage' &&
          <TextField
            multiline
            rows={4}
            value={data.description}
            placeholder='Please describe the Drought Stage'
            onChange={(e) => handleChange(e.target.value, 'droughtStatus.description')}
            sx={{
              flexGrow: 1,
              marginTop: '31px'
            }}
          />
        }
      </FormGroup>
    </Box>
  );
}

DroughtStatus.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func
};