import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import {
  TextField,
  FormGroup,
  Typography
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { roundXDigits } from '../../../Helpers';
import IconPopper from '../../IconPopper';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: 740,
    margin: '20px auto 0px auto'
  },
  textfield: {
    width: '48%'
  },
  icon: {
    color: 'rgb(220,40,40)',
  },
  iconBtn: {
    maxHeight: 40,
    position: 'relative',
    top: 130,
    left: -10,
    '&:hover': {
      backgroundColor: 'rgba(210,118,25,0.04) !important'
    }
  },
  group: {
    width: '95%',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  titleCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
    margin: '0 auto'
  },
  datetime: {
    width: '100%',
    justifyContent: 'center',
    gap: 20
  },
  labelLine: {
    display: 'flex',
    alignItems: 'center'
  }
}));


export default function SWFData({ index, data, handleChange }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleCont}>
        <Typography variant='h6'>{data.swfName}</Typography>
        <Typography style={{fontSize: 14, fontStyle: 'italic'}}>SWSF ID: {data.swsfID}</Typography>
      </div>

      <FormGroup>
        <FormGroup row={true} className={classes.labelLine}>
          <Typography variant='sectionTitle'>Usable Storage</Typography>
          
          <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='7px'>
            <Typography variant='popupSubheading'>Total Usable Storage</Typography>
            <Typography variant='popupBody'>
              The difference between the total storage volume of a water supply
              reservoir and the remaining volume below the minimum operational
              level (intake pipe elevation) or the water elevation above which water
              can be treated to meet water quality standards, whichever is least; in
              Million Gallons (MG).
            </Typography>

            <Typography variant='popupSubheading'>Instantaneous Usable Storage</Typography>
            <Typography variant='popupBody'>The currently available usable storage in Million Gallons (MG)</Typography>
          </IconPopper>
        </FormGroup>
          
        <FormGroup row={true} className={classes.group}>
          <TextField
            className={classes.textfield}
            size='small'
            label='Total Usable Storage (MG)'
            value={data.tus}
            onChange={(e) => handleChange(roundXDigits(e.target.value, 3, true), `swfs.${index}.tus`)}
            type='number'
            inputProps={{
              step: '0.01',
              min: '0'
            }}
          />
          <TextField
            className={classes.textfield}
            size='small'
            label='Instantaneous Usable Storage (MG)'
            value={data.ius}
            onChange={(e) => handleChange(roundXDigits(e.target.value, 3, true), `swfs.${index}.ius`)}
            type='number'
            inputProps={{
              step: '0.01',
              min: '0'
            }}
          />
        </FormGroup>
      </FormGroup>

      <FormGroup>
        <FormGroup row={true} className={classes.labelLine}>
          <Typography variant='sectionTitle'>Water Level</Typography>
          
          <IconPopper icon={<QuestionMarkIcon className={classes.icon} sx={{color: 'rgb(27,136,199)', fontSize: '14px !important'}} />} vertAdjust='7px'>
            <Typography variant='popupSubheading'>Water Elevation</Typography>
            <Typography variant='popupBody'>Reservoir water elevation in feet (ft).</Typography>

            <Typography variant='popupSubheading'>Percent Full</Typography>
            <Typography variant='popupBody'>(Instantaneous Usable Storage/ Total Usable Storage) X 100 (%)</Typography>
          </IconPopper>
        </FormGroup>
          
        <FormGroup row={true} className={classes.group}>
          <TextField
            className={classes.textfield}
            size='small'
            label='Water Elevation (ft.)'
            value={data.waterElev}
            onChange={(e) => handleChange(roundXDigits(e.target.value, 1, true), `swfs.${index}.waterElev`)}
            type='number'
            inputProps={{step: '0.1'}}
          />

          <TextField
            className={classes.textfield}
            size='small'
            label='Percent Full'
            value={data.percentFull}
            disabled
            type='number'
            inputProps={{
              step: '0.01',
              min: '0'
            }}
          />
        </FormGroup>
      </FormGroup>

      <FormGroup>
        <Typography variant='sectionTitle'>Comments</Typography>
        
        <TextField
          sx={{width: '95%', margin: '0 auto'}}
          size='small'
          variant='outlined'
          label='Comment'
          value={data.comment}
          onChange={(e) => handleChange(e.target.value, `swfs.${index}.comment`)}
          multiline
          rows={4}
        />
      </FormGroup>
    </div>
  );
}

SWFData.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  handleChange: PropTypes.func
};