import React from 'react';
import PropTypes from 'prop-types';

import WellForm from './WellForm';
import MeasureMethodForm from './MeasureMethodForm';
import WLMRefPointForm from './WLMRefPointForm';



export default function WellInformation({ classes, well, measurement, handleChangeAll, submitAttempted, wellID }) {
  return (
    <>
      <WellForm submitAttempted={submitAttempted} classes={classes} data={well} handleChangeAll={handleChangeAll} wellID={wellID} />

      <MeasureMethodForm submitAttempted={submitAttempted} classes={classes} data={measurement} handleChangeAll={handleChangeAll} />

      <WLMRefPointForm submitAttempted={submitAttempted} classes={classes} data={measurement} handleChangeAll={handleChangeAll} />
    </>
  );
}

WellInformation.propTypes = {
  classes: PropTypes.object,
  well: PropTypes.object,
  measurement: PropTypes.object,
  handleChangeAll: PropTypes.func,
  submitAttempted: PropTypes.bool,
  wellID: PropTypes.string
};