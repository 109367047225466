import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import {
  Typography
} from '@mui/material';


const useStyles = makeStyles(() => ({
  box: {
    padding: 15,
    border: '1px solid rgba(180,180,180,0.7)',
    borderRadius: 5,
    backgroundColor: 'rgb(157,224,255)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 40,
    maxWidth: 600,
    margin: '0 auto',
    boxSizing: 'border-box'
  },
  cont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 340
  },
  value: {
    marginLeft: '10px !important',
    fontWeight: 'bold !important'
  },
  hr: {
    height: 2,
    width: 380,
    backgroundColor: 'white',
  }
}));



export default function AggregateData({ data }) {
  const classes = useStyles();
  
  return (
    <div className={classes.box}>
      <div className={classes.cont}>
        <div className={classes.group}>
          <Typography>Sum of Total Usable Storage (MG):</Typography>
          <Typography className={classes.value}>{data.totalTUS}</Typography>
        </div>

        <div className={classes.hr}></div>

        <div className={classes.group}>
          <Typography>Sum of Instantaneous Usable Storage (MG):</Typography>
          <Typography className={classes.value}>{data.totalIUS}</Typography>
        </div>

        <div className={classes.hr}></div>

        <div className={classes.group}>
          <Typography>Average Percent Full:</Typography>
          <Typography className={classes.value}>{data.avgPercentFull}</Typography>
        </div>
      </div>
    </div>
  );
}

AggregateData.propTypes = {
  data: PropTypes.object
};