import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatISO } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';

import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import {
  roundXDigits,
  updateObject,
  customFetch,
} from '../../../Helpers/index';

import BasicInfo from './BasicInfo';
import WeekSelector from './WeekSelector';
import DroughtStatus from './DroughtStatus';
import AggregateData from './AggregateData';
import SubmitBtn from '../../SubmitBtn';
import SWFData from './SWFData';
import SupplyData from './SupplyData';
import IconPopper from '../../IconPopper';
import Section from '../../Section';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 40,
    textAlign: 'center',
  },
  sectionTitle: {
    marginLeft: '40px !important',
  },
  hr: {
    backgroundColor: 'rgb(242,208,59)',
    height: 4,
    width: '65%',
    margin: '30px auto',
  },
  subHeadingLine: {
    display: 'flex',
    width: 'fit-content',
    margin: '0 auto',
  },
}));

const createSwfObj = (obj) => {
  return {
    swfName: obj.name,
    swsfID: obj.id,
    waterElev: 0.0,
    tus: isNaN(obj.defaultTUS) ? 0.0 : parseFloat(obj.defaultTUS),
    ius: 0.0,
    percentFull: 0.0,
    comment: '',
  };
};

const getInitFormData = (swfArr) => {
  return {
    submitDate: formatISO(new Date(), { representation: 'date' }),
    weekMonday: '',
    swfs: swfArr.map((swf) => createSwfObj(swf)),
    supplyData: {
      avgDP: 0.0,
      totalWithdrawn: 0.0,
      daysRemaining: null,
    },
    aggregate: {
      totalTUS: 0.0,
      totalIUS: 0.0,
      avgPercentFull: 0.0,
    },
    droughtStatus: {
      level: 'None',
      description: '',
    },
  };
};

export default function SurfaceWaterForm({
  domain,
  prefill,
  siteAlias,
  setShowResults,
  editData,
  setSiteMetaData,
  setSite,
}) {
  const [formData, setFormData] = useState(getInitFormData(prefill.site.swfs));
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const classes = useStyles();
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    setFormData(
      editData && Object.keys(editData).length > 0
        ? editData.data
        : getInitFormData(prefill.site.swfs)
    );
  }, [editData, siteAlias, prefill]);

  const handleChange = (value, path) => {
    let newObj = { ...formData };
    updateObject(newObj, path, value);

    let changed = false;
    if (path.includes('.tus') || path.includes('.ius')) {
      changed = true;
      let pathParts = path.split('.');
      let newVal = pathParts.pop();
      let index = pathParts.pop();

      let obj = newObj.swfs[index];
      let perc;
      try {
        if (newVal === 'tus') {
          perc = (obj.ius / value) * 100;
        } else {
          perc = (value / obj.tus) * 100;
        }
      } catch {
        perc = false;
      }

      if (typeof perc === 'number' && perc !== Infinity) {
        newObj.swfs[index].percentFull = roundXDigits(perc, 2, true);
      }
    } else if (path.includes('level') && value !== 'Drought Stage') {
      newObj.droughtStatus.description = '';
    }

    if (changed) {
      newObj.swfs.reduce(
        (acc, swf, i) => {
          acc.tusSum += swf.tus;
          acc.iusSum += swf.ius;
          acc.percSum += swf.percentFull;

          if (i === newObj.swfs.length - 1) {
            newObj.aggregate.totalTUS = roundXDigits(acc.tusSum, 3, true);
            newObj.aggregate.totalIUS = roundXDigits(acc.iusSum, 3, true);
            newObj.aggregate.avgPercentFull = roundXDigits(
              acc.percSum / (i + 1),
              2,
              true
            );
          }

          return acc;
        },
        { tusSum: 0, iusSum: 0, percSum: 0 }
      );
    }

    if (path.includes('.ius') || path.includes('.avgDP')) {
      newObj.supplyData.daysRemaining =
        newObj.supplyData.avgDP === 0
          ? null
          : roundXDigits(
              parseFloat(newObj.aggregate.totalIUS) / newObj.supplyData.avgDP,
              2,
              true
            );
    }

    setFormData(newObj);
  };

  const requestSubmit = async () => {
    const accessToken = await getAccessTokenSilently();

    return await customFetch(`//${domain}/saveData`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        data: formData,
        type: 'surface',
        siteAlias,
        user: {
          name: user.name,
          userAlias: user.sub,
        },
      }),
    });
  };

  const handleSubmit = async () => {
    // Activates error highlighting for inputs
    if (!submitAttempted) {
      setSubmitAttempted(true);
    }

    let missingData = false;
    if (
      formData.weekMonday === '' ||
      (formData.droughtStatus.level === 'Drought Stage' &&
        formData.droughtStatus.description === '')
    ) {
      missingData = true;
    }

    if (missingData) {
      setShowResults({
        code: 'Not Submitted',
        msg: 'It seems that there is some missing data!',
        type: 'data',
        action: 'saved',
      });
    } else {
      let results = await requestSubmit();

      // If token expired during call, try again, this should auto refresh the token
      if (results.code === 419) {
        results = await requestSubmit();
      }

      if (results.code === 200) {
        if (!(editData && Object.keys(editData).length > 0)) {
          setFormData(getInitFormData(prefill.site.swfs));
          setSubmitAttempted(false);
          setSiteMetaData((prev) => {
            return {
              ...prev,
              missingMondays: prev.missingMondays.filter(
                (date) => !formData.weekMonday.includes(date)
              ),
            };
          });

          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          setSite(null);
        }
      }

      setShowResults({
        code: results.code,
        msg: results.res,
        type: 'data',
        action: 'saved',
      });
    }
  };

  return (
    <Box>
      <div className={classes.title}>
        <Typography variant='h1'>
          Surface Water Capacity Reporting Form
        </Typography>
        <div className={classes.subHeadingLine}>
          <Typography variant='subtitle1'>
            Pursuant to RCSA Section 19-13-B102(n)
          </Typography>

          <IconPopper
            icon={
              <QuestionMarkIcon
                className={classes.icon}
                sx={{ color: 'rgb(27,136,199)', fontSize: '14px !important' }}
              />
            }
          >
            <Typography variant='popupSubheading'>
              What are the required measurement and reporting frequencies?
            </Typography>
            <Typography variant='popupBody'>
              The Department of Public Health Drinking Water Section (DWS) is
              requesting public water systems to report surface water supply and
              treatment information on a weekly basis.
            </Typography>
            <Typography variant='popupBody'>
              The Surface Water Capacity Reporting form must be completed for
              each surface water treatment water system facility and its
              associated storage or distribution reservoir or reservoirs.
            </Typography>
          </IconPopper>
        </div>
      </div>

      <Section titleText='General Information'>
        <BasicInfo data={prefill} />
      </Section>

      <Section titleText='Measurements and Readings'>
        <>
          <WeekSelector
            data={prefill}
            weekMonday={formData.weekMonday}
            handleChange={handleChange}
            readOnly={editData && Object.keys(editData).length > 0}
            submitAttempted={submitAttempted}
          />

          {formData.swfs.map((obj, i) => {
            return (
              <Fragment key={i}>
                <div className={classes.hr}></div>

                <SWFData index={i} data={obj} handleChange={handleChange} />
              </Fragment>
            );
          })}

          {formData.weekMonday !== '' && (
            <AggregateData data={formData.aggregate} />
          )}
        </>
      </Section>

      <Section titleText='Supply Data'>
        <SupplyData
          data={formData.supplyData}
          handleChange={handleChange}
          submitAttempted={submitAttempted}
        />
      </Section>

      <Section titleText='Drought Plan Status'>
        <DroughtStatus
          data={formData.droughtStatus}
          handleChange={handleChange}
          submitAttempted={submitAttempted}
        />
      </Section>

      <SubmitBtn
        btnText='Submit Surface Water Data'
        submitFunc={handleSubmit}
      />
    </Box>
  );
}

SurfaceWaterForm.propTypes = {
  domain: PropTypes.string,
  prefill: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  editData: PropTypes.object,
  siteAlias: PropTypes.string,
  setShowResults: PropTypes.func,
  setSiteMetaData: PropTypes.func,
  setSite: PropTypes.func,
};
