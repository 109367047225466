import React from 'react';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import Loading from '../LoadingComp/Loading';



// Redirects user to login if attempting to access a component wrapped in this
function ProtectedComponent({ children, adminOnly, userList }) {
  if (adminOnly) {
    if (!userList || !(userList.length > 0)) {
      return <Navigate to='/' />;
    }
  }
  
  return children;
}

ProtectedComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  adminOnly: PropTypes.bool,
  userList: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ])
};

export default withAuthenticationRequired(ProtectedComponent, {
  onRedirecting: () => <Loading />
});