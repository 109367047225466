import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Typography,
  TextField,
} from '@mui/material';

export default function PWSForm({ classes, data }) {
  return (
    <FormGroup className={classes.sectionCont}>
      <Typography variant='sectionTitle' sx={{marginTop: 0}}>General Information</Typography>
      <FormGroup className={classes.inputsCont}>
        <FormGroup row={true} className={classes.group}>
          <TextField
            size='small'
            variant='outlined'
            label='PWS ID'
            value={data.company.meta.pwsID}
            className={classes.groupedField}
            disabled
          />
          <TextField
            size='small'
            variant='outlined'
            label='Name'
            value={data.company.meta.name}
            className={classes.groupedField}
            disabled
          />
        </FormGroup>

        <FormGroup row={true} className={classes.group} style={{ justifyContent: 'center' }}>
          {/* <TextField
            size='small'
            variant='outlined'
            label='Population Served by PWS'
            value={data.site.populationServed}
            className={classes.groupedField}
            disabled
          /> */}

          <TextField
            size='small'
            variant='outlined'
            label='Number of Supply Wells in PWS'
            value={data.company.sites.length}
            className={classes.groupedField}
            disabled
          />
        </FormGroup>

        <FormGroup>
          <Typography variant='sectionTitle' sx={{marginTop: 0}}>Location</Typography>
          <FormGroup row={true} sx={{ justifyContent: 'space-around' }}>
            <TextField
              className={classes.groupedField}
              size='small'
              variant='outlined'
              label='Latitude'
              value={data.site.lngLat[1]}
              disabled
            />

            <TextField
              className={classes.groupedField}
              size='small'
              variant='outlined'
              label='Longitude'
              value={data.site.lngLat[0]}
              disabled
            />
          </FormGroup>
        </FormGroup>
      </FormGroup>
    </FormGroup>
  );
}

PWSForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};