import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Typography,
  TextField,
} from '@mui/material';

import { roundXDigits } from '../../../Helpers';



export default function WellForm({ classes, data, handleChangeAll, wellID }) {
  const handleChange = (val, path) => {
    handleChangeAll({ ...data, [path]: roundXDigits(val, 1, true)}, 'well');
  };
  
  return (
    <>
      <FormGroup className={classes.sectionCont}>
        <Typography variant='sectionTitle'>General Information</Typography>
        <FormGroup className={classes.inputsCont}>
          <FormGroup row={true} className={classes.group}>
            <TextField
              className={classes.groupedField}
              size='small'
              variant='outlined'
              label='Well ID'
              value={wellID}
              disabled
            />
            <TextField
              className={classes.groupedField}
              size='small'
              variant='outlined'
              label='Top Of Casing Elevation (ft.)'
              value={data.tocElev}
              onChange={(e) => handleChange(e.target.value, 'tocElev')}
              type='number'
              inputProps={{ step: 0.1 }}
            />
          </FormGroup>
        </FormGroup>
      </FormGroup>

      <FormGroup className={classes.sectionCont}>
        <Typography variant='sectionTitle'>Original Reference Elevation (ft.)</Typography>
        <FormGroup row={true} className={classes.group}>
          <TextField
            className={classes.groupedField}
            size='small'
            variant='outlined'
            label='Static Water Level'
            value={data.swlElev}
            onChange={(e) => handleChange(e.target.value, 'swlElev')}
            type='number'
            inputProps={{ step: 0.1 }}
          />
          <TextField
            className={classes.groupedField}
            size='small'
            variant='outlined'
            label='Pumping Water Level'
            value={data.pwlElev}
            onChange={(e) => handleChange(e.target.value, 'pwlElev')}
            type='number'
            inputProps={{ step: 0.1 }}
          />
        </FormGroup>
      </FormGroup>
    </>
  );
}

WellForm.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  handleChangeAll: PropTypes.func,
  wellID: PropTypes.string
};